import React, {useRef, useState} from 'react'
import "./InviteEditComp.scss"
import Modal from "react-responsive-modal";
import CustomInput from "../../components/custom/CustomInput";
import {Button, Checkbox, DatePicker, Icon, SelectPicker, Toggle} from "rsuite";
import {BackAndConfirmButtons, BackButton, ConfirmButton, reorderLocations} from "../../components/Helper";
import isBefore from "date-fns/isBefore";
import GooglePlacesAutocomplete, {geocodeByAddress} from "react-google-places-autocomplete";
import {toast} from "react-toastify";
import moment from "moment-timezone";
import axios from "axios";
import AvatarEditor from 'react-avatar-editor'
import {isAfter} from "date-fns";
import {
    checkAndGetLocationLabel,
    convertDateTimeToBrowserTimezone,
    convertDateTimeToDefaultTimezone,
    getMaxYearsPrivateEvent, isCorporateWeddingEvent
} from "../../../functions";
import TextStyleComp from "../../../components/inviteTemplates/components/TextStyleComp/TextStyleComp";
import DateContainerStyleComp
    from "../../../components/inviteTemplates/components/DateContainerStyleComp/DateContainerStyleComp";
import LocationsContainerStyleComp
    from "../../../components/inviteTemplates/components/LocationsContainerStyleComp/LocationsContainerStyleComp";
import {
    getDefaultTemplateValues,
    resetTemplateStyles
} from "../../../components/InviteTemplatesFunctions";
import InviteTemplateRenderLazy from "../../../components/inviteTemplates/InviteTemplateRenderLazy";
import {corporateWeddingEvents} from "../../../const";


export default function InviteEditComp(
    {
        backgroundImageLink = '',
        eventType,
        weddingData,
        saveWeddingData,
        templateDetails,
        setTemplateDetails,
        changeTypeToCustom,
        audioLink,
        image1Link,
        image2Link,
        image3Link,
        coverImageLink,
        newImage1,
        newImage2,
        newImage3,
        newCoverImage,
        setNewImage1,
        setNewImage2,
        setNewImage3,
        setNewCoverImage,
        updateLocations,
        demo = false,
        setShowDemoModal = () => {}
    }) {

    let locatiiNunta = [{label: "Locație petrecere", value: "Locatie petrecere"}, {
        label: "Locație biserică",
        value: "Locatie biserica"
    }, {label: "Locație cununie", value: "Locatie cununie"}, {label: "Alta locație", value: "Alta locatie"}]
    let locatiiBotez = [{label: "Locație petrecere", value: "Locatie petrecere"}, {
        label: "Locație biserică",
        value: "Locatie biserica"
    }, {label: "Alta locație", value: "Alta locatie"}]
    let locatiiPetrecere = [{label: "Locație petrecere", value: "Locatie petrecere"}, {
        label: "Alta locație",
        value: "Alta locatie"
    }]
    let locatiiCorporate = [{label: "Locație eveniment", value: "Locatie eveniment"}, {
        label: "Alta locație",
        value: "Alta locatie"
    }]

    const columnStyle = {width: "30%"}
    const locationsTypes =
        ['Nunta', 'Cununie'].includes(weddingData.eventType) ? locatiiNunta
            :
            (weddingData.eventType === "Botez" || weddingData.eventType === "Mot" || weddingData.eventType === "Turta") ?
                locatiiBotez
                : weddingData.eventType === 'Petrecere' ?
                    locatiiPetrecere
                    : corporateWeddingEvents.includes(weddingData.eventType) ?
                        locatiiCorporate : locatiiCorporate

    const cropper1 = useRef(null);
    const cropper2 = useRef(null);
    const cropper3 = useRef(null);
    const cropperCover = useRef(null);

    const [showModalSection1, setShowModalSection1] = useState(false)
    const [showModalSection2, setShowModalSection2] = useState(false)
    const [showModalSection3, setShowModalSection3] = useState(false)
    const [showLocationsModal, setShowLocationsModal] = useState(false)

    //Locations and dates
    const [locations, setLocations] = useState(weddingData && weddingData.locatii ? weddingData.locatii : [])
    const [locationAddress, setLocationAddress] = useState('')
    const [locationLabel, setLocationLabel] = useState('')
    const [locationData, setLocationData] = useState(new Date())
    const [locationDay, setLocationDay] = useState(new Date(weddingData.dataEvent))
    const [locationName, setLocationName] = useState('')
    const [locationType, setLocationType] = useState('')
    const [editLocationIndex, setEditLocationIndex] = useState(-1)
    // eslint-disable-next-line

    const [image1Scale, setImage1Scale] = useState(1)
    const [image2Scale, setImage2Scale] = useState(1)
    const [image3Scale, setImage3Scale] = useState(1)
    const [coverImageScale, setCoverImageScale] = useState(1)

    const [newImage1Temp, setNewImage1Temp] = useState(false)
    const [newImage2Temp, setNewImage2Temp] = useState(false)
    const [newImage3Temp, setNewImage3Temp] = useState(false)
    const [newCoverImageTemp, setNewCoverImageTemp] = useState(false)

    const [mapsRestrictions, setMapsRestrictions] = useState(true)
    const [showResetModal, setShowResetModal] = useState(false)

    const toggleModal = (section) => {

        switch (section) {
            case 'section1':
                setShowModalSection1(!showModalSection1)
                break
            case 'section2':
                setShowModalSection2(!showModalSection2)
                break
            case 'section3':
                setShowModalSection3(!showModalSection3)
                break
            default:
                break
        }

    }

    const changeTemplateSectionValue = (section, index, value) => {
        let newTemplateDetails = templateDetails ? {...templateDetails} : {}
        newTemplateDetails[section][index] = value
        setTemplateDetails(newTemplateDetails)
    }

    const addLocation = () => {

        let locationNameTemp = locationName

        if (locationType === "") {
            toast.error("Adaugati tipul sau numele locatiei")
            return
        }

        if (locationType === "Alta locatie" && locationName === "") {
            toast.error("Adaugati numele locatiei ")
            return
        }

        if (locationNameTemp === "") {
            locationNameTemp = locationType
        }

        if (locationData === "") {
            toast.error("Adaugati ora evenimentului ")
            return
        }

        if (locationDay === "") {
            toast.error("Adaugati ziua evenimentului ")
            return
        }

        if (locationAddress === "" && locationLabel === "") {
            toast.error("Adaugati adresa evenimentului")
            return
        }

        let current = [...locations]

        let currentLocation = null

        if (editLocationIndex !== -1 && current[editLocationIndex]) {
            currentLocation = current[editLocationIndex]
        }

        if(currentLocation && currentLocation.address === locationAddress && currentLocation.pinx) {

            console.log('Maps location not changed');

            let location = {
                label: locationLabel && locationLabel.length > 0 ? locationLabel : '',
                address: locationAddress,
                pinx: currentLocation.pinx,
                piny: currentLocation.piny,
                time: locationData,
                day: locationDay,
                name: locationNameTemp
            }

            if (weddingData && weddingData.invite && weddingData.invite.language &&
                (weddingData.invite.language !== 'ro' && weddingData.invite.language !== '')) {
                location = {
                    label: editLocationIndex !== -1 ? locations[editLocationIndex].label : '',
                    address: locationAddress,
                    pinx: currentLocation.pinx,
                    piny: currentLocation.piny,
                    time: locationData,
                    day: locationDay,
                    name: editLocationIndex !== -1 ? locations[editLocationIndex].name : '',
                }
                location['label_' + weddingData.invite.language] = (locationLabel && locationLabel.length > 0 ? locationLabel : '')
                location['name_' + weddingData.invite.language] = locationName
            }

            if (editLocationIndex !== -1) {
                current[editLocationIndex] = location
            } else {
                current.push(location)
            }

            current = reorderLocations(current)

            setLocations(current)
            updateLocations(current)
            setLocationName('')
            setLocationAddress('')
            setLocationLabel('')
            setLocationType('')
            setLocationData(new Date())
            setLocationDay(new Date(weddingData.dataEvent))
            setEditLocationIndex(-1)
            setShowLocationsModal(false)
        } else if (locationAddress && locationAddress.length > 0) {
            geocodeByAddress(locationAddress)
                .then(results => {

                    let pinx = parseFloat(results[0].geometry.location.lat())
                    let piny = parseFloat(results[0].geometry.location.lng())

                    let location = {
                        label: locationLabel && locationLabel.length > 0 ? locationLabel : '',
                        address: locationAddress,
                        pinx: pinx,
                        piny: piny,
                        time: locationData,
                        day: locationDay,
                        name: locationNameTemp
                    }

                    if (weddingData && weddingData.invite && weddingData.invite.language &&
                        (weddingData.invite.language !== 'ro' && weddingData.invite.language !== '')) {
                        location = {
                            label: editLocationIndex !== -1 ? locations[editLocationIndex].label : '',
                            address: locationAddress,
                            pinx: pinx,
                            piny: piny,
                            time: locationData,
                            day: locationDay,
                            name: editLocationIndex !== -1 ? locations[editLocationIndex].name : '',
                        }
                        location['label_' + weddingData.invite.language] = (locationLabel && locationLabel.length > 0 ? locationLabel : '')
                        location['name_' + weddingData.invite.language] = locationName
                    }

                    if (editLocationIndex !== -1) {
                        current[editLocationIndex] = location
                    } else {
                        current.push(location)
                    }

                    current = reorderLocations(current)

                    setLocations(current)
                    updateLocations(current)
                    setLocationName('')
                    setLocationAddress('')
                    setLocationLabel('')
                    setLocationType('')
                    setLocationData(new Date())
                    setLocationDay(new Date(weddingData.dataEvent))
                    setEditLocationIndex(-1)
                    setShowLocationsModal(false)

                })
        } else {

            let location = {
                label: locationLabel && locationLabel.length > 0 ? locationLabel : '',
                address: '',
                pinx: null,
                piny: null,
                time: locationData,
                day: locationDay,
                name: locationNameTemp
            }

            if (weddingData && weddingData.invite && weddingData.invite.language &&
                (weddingData.invite.language !== 'ro' && weddingData.invite.language !== '')) {
                location = {
                    label: editLocationIndex !== -1 ? locations[editLocationIndex].label : '',
                    address: locationAddress,
                    pinx: null,
                    piny: null,
                    time: locationData,
                    day: locationDay,
                    name: editLocationIndex !== -1 ? locations[editLocationIndex].name : '',
                }
                location['label_' + weddingData.invite.language] = (locationLabel && locationLabel.length > 0 ? locationLabel : '')
                location['name_' + weddingData.invite.language] = locationName
            }

            if (editLocationIndex !== -1) {
                current[editLocationIndex] = location
            } else {
                current.push(location)
            }

            current = reorderLocations(current)

            setLocations(current)
            updateLocations(current)
            setLocationName('')
            setLocationAddress('')
            setLocationLabel('')
            setLocationType('')
            setLocationData(new Date())
            setLocationDay(new Date(weddingData.dataEvent))
            setEditLocationIndex(-1)
            setShowLocationsModal(false)
        }
    }

    const editLocation = (index) => {

        if (demo) {
            setShowDemoModal(true)
            toast.info('Această funcționalitate va fi disponibilă după ce apăsați pe butonul „Creează invitația” și vă autentificați în aplicația Ality.')
            return
        }

        let location = locations[index]
        let list = locationsTypes.filter(elem => elem.value === location.name)

        setEditLocationIndex(index)
        setLocationAddress(location.address ? location.address : '')

        if (weddingData && weddingData.invite && weddingData.invite.language &&
            (weddingData.invite.language !== 'ro' && weddingData.invite.language !== '')) {
            setLocationLabel(location['label_' + weddingData.invite.language] ?
                location['label_' + weddingData.invite.language] : '-')
            setLocationName(location['name_' + weddingData.invite.language] ?
                location['name_' + weddingData.invite.language] : '-')
        } else {
            setLocationLabel(location.label ? location.label : '')
            setLocationName(list.length === 0 ? location.name : "")

        }
        setLocationType(list.length === 0 ? "Alta locatie" : location.name)
        setLocationData(new Date(location.time))
        setLocationDay(location.day === undefined ? new Date(location.time) : new Date(location.day))
        setShowLocationsModal(true)
    }

    /*
     if(weddingData && weddingData.invite && weddingData.invite.language &&
                        (weddingData.invite.language !== 'ro' && weddingData.invite.language !== '')) {
                        location = {
                            label: locationLabel && locationLabel.length > 0 ? locationLabel : '',
                            address: locationAddress,
                            pinx: pinx,
                            piny: piny,
                            time: locationData,
                            day: locationDay,
                            name: editLocationIndex !== -1 ? locations[editLocationIndex].name : '',
                        }
                        location['name_' + weddingData.invite.language] = locationNameTemp
                    }
     */

    const removeLocation = () => {
        let index = editLocationIndex
        let newLocations = [...locations]

        newLocations.splice(index, 1)

        setLocations(newLocations)
        setLocationName('')
        setLocationAddress('')
        setLocationLabel('')
        setLocationType('')
        setLocationData(new Date())
        setLocationDay(new Date(weddingData.dataEvent))
        setEditLocationIndex(-1)
        setShowLocationsModal(false)
    }

    const persistLocations = async () => {
        let obj = {
            id_eveniment: weddingData.id_eveniment,
            locations: locations,
            prenumeMireasa: weddingData.prenumeMireasa,
            prenumeMire: weddingData.prenumeMire,
            dataEvent: templateDetails.section2.date ? templateDetails.section2.date : weddingData.dataEvent,
            eventDescription: weddingData.eventDescription,
            isPublished: weddingData.isPublished
        }

        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        if (demo) {
            auth = {}
        }

        axios.put(process.env.REACT_APP_DOMAIN + 'weddings/edit/' + weddingData.id, obj, auth)//formData, auth)
            .then(response => {
            })
            .catch(err => {
            })
    }


    return (<div className={"EditInviteComp"}>

        {
            templateDetails && templateDetails.id ?
              <>
                            <InviteTemplateRenderLazy
                                templateDetails={templateDetails}
                                weddingData={weddingData}
                                audioLink={audioLink}
                                image1Link={newImage1 ? window.URL.createObjectURL(newImage1) : image1Link}
                                image2Link={newImage2 ? window.URL.createObjectURL(newImage2) : image2Link}
                                image3Link={newImage3 ? window.URL.createObjectURL(newImage3) : image3Link}
                                coverImageLink={newCoverImage ? window.URL.createObjectURL(newCoverImage) : coverImageLink}
                                locations={locations}
                                mode={2}
                                toggleModal={toggleModal}
                            />

                            <div className="Section">
                                <div className="Title">Resetare template la valorile inițiale
                                </div>

                                <div className="Options-Row">
                                    <div
                                        className={"active-option"}
                                        style={{width: 'unset'}}
                                        onClick={_ => setShowResetModal(true)}><Icon icon={"cog"}/> Resetare
                                    </div>

                                </div>
                            </div>


                            <Modal classNames={{
                                overlay: 'modal-overlay',
                                modal: 'modal-no-top',
                                closeButton: 'modal-close-button',
                            }} focusTrapped={false} open={showModalSection1} onClose={() => toggleModal('section1')}>

                                <div className={"invite-section-edit-modal"}>


                                    {templateDetails.coverImage ?
                                        <>
                                            <div className={"input-title"}>
                                                Imagine de copertă
                                            </div>

                                            <Toggle className={"green"}
                                                    style={{width: "150px", alignSelf: "center", marginBottom: "8px"}}
                                                    checked={templateDetails.section1.coverImage}
                                                    onChange={status => {
                                                        changeTemplateSectionValue('section1', 'coverImage', status)
                                                    }} checkedChildren="Activată"
                                                    unCheckedChildren="Dezactivată"/>

                                            {
                                                templateDetails.section1.coverImage ?
                                                    newCoverImageTemp ?
                                                        <div className={"avatar-cont"}>
                                                            <AvatarEditor
                                                                ref={cropperCover}
                                                                image={newCoverImageTemp}
                                                                width={530}
                                                                height={530}
                                                                border={20}
                                                                color={[0, 0, 0, 0.6]} // RGBA
                                                                scale={parseFloat(coverImageScale + '')}
                                                                rotate={0}
                                                                borderRadius={150}
                                                                //onImage
                                                                onLoadFailure={(e) => {
                                                                    toast.error('Vă rugăm să încărcați doar fișiere .png sau .jpg!')
                                                                    setNewCoverImage(null)
                                                                }}
                                                                style={{width: '230px', height: '230px'}}
                                                            />
                                                            <div className={"actions"}>
                                                                <div className={"line"}>
                                                                    <div className={"col-left"}>
                                                                        Zoom
                                                                    </div>
                                                                    <div className={"col-right"}>
                                                                        <input type="range" className="custom-range"
                                                                               min="1"
                                                                               max="7"
                                                                               step="0.1" value={coverImageScale}
                                                                               onChange={(event) => setCoverImageScale(event.target.value)}/>
                                                                    </div>
                                                                </div>

                                                                <div className={"line"}>
                                                                    <ConfirmButton text={"Salvează"}
                                                                                   function={async () => {

                                                                                       const dataUrl = cropperCover.current.getImageScaledToCanvas().toDataURL()
                                                                                       const result = await fetch(dataUrl)
                                                                                       const blob = await result.blob()

                                                                                       setNewCoverImage(blob)
                                                                                       setNewCoverImageTemp(false)
                                                                                   }}/>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        :
                                                        <div className={"avatar-cont"}>
                                                            {
                                                                newCoverImage ?
                                                                    <div className={"image-preview"}>
                                                                        <img  alt={""}
                                                                            src={window.URL.createObjectURL(newCoverImage)}/>
                                                                    </div> :
                                                                    coverImageLink ?
                                                                        <div className={"image-preview"}>
                                                                            <img  alt={""} src={coverImageLink}/>
                                                                        </div>
                                                                        : null
                                                            }

                                                            <input
                                                                type="file"
                                                                name="myImage"
                                                                onChange={(event) => {

                                                                    setNewCoverImageTemp(event.target.files[0]);
                                                                }}
                                                            />
                                                        </div>
                                                    : null
                                            }
                                            <br/>
                                        </>
                                        : null
                                    }


                                    <div className={"input-title"}>
                                        {
                                            (eventType === 'Nunta' || eventType === 'Cununie') ?
                                                'Prima imagine (mire/mireasă)'
                                                :
                                                (eventType === 'Botez' || eventType === 'Mot' || eventType === "Turta") ?
                                                    'Imagine copil'
                                                    :
                                                    eventType === 'Petrecere' || corporateWeddingEvents.includes(eventType) ?
                                                        'Imagine'
                                                        : null
                                        }
                                    </div>

                                    <Toggle className={"green"}
                                            style={{width: "150px", alignSelf: "center", marginBottom: "8px"}}
                                            checked={templateDetails.section1.image1}
                                            onChange={status => {
                                                changeTemplateSectionValue('section1', 'image1', status)
                                            }} checkedChildren="Activată"
                                            unCheckedChildren="Dezactivată"/>
                                    {
                                        templateDetails.section1.image1 ?
                                            newImage1Temp ?
                                                <div className={"avatar-cont"}>
                                                    {corporateWeddingEvents.includes(eventType) ?
                                                        <AvatarEditor
                                                            ref={cropper1}
                                                            image={newImage1Temp}
                                                            width={330}
                                                            height={160}
                                                            border={0}
                                                            color={[0, 0, 0, 0.6]} // RGBA
                                                            scale={parseFloat(image1Scale + '')}
                                                            rotate={0}
                                                            borderRadius={0}
                                                            //onImage
                                                            onLoadFailure={(e) => {
                                                                toast.error('Vă rugăm să încărcați doar fișiere .png sau .jpg!')
                                                                setNewImage1Temp(null)
                                                            }}
                                                            style={{width: '330px', height: '160px', background: '#ccc'}}
                                                            disableBoundaryChecks={true}
                                                        />
                                                        :
                                                    <AvatarEditor
                                                        ref={cropper1}
                                                        image={newImage1Temp}
                                                        width={230}
                                                        height={230}
                                                        border={20}
                                                        color={[0, 0, 0, 0.6]} // RGBA
                                                        scale={parseFloat(image1Scale + '')}
                                                        rotate={0}
                                                        borderRadius={150}
                                                        //onImage
                                                        onLoadFailure={(e) => {
                                                            toast.error('Vă rugăm să încărcați doar fișiere .png sau .jpg!')
                                                            setNewImage1Temp(null)
                                                        }}
                                                        style={{width: '230px', height: '230px'}}
                                                    />}
                                                    <div className={"actions"}>
                                                        <div className={"line"}>
                                                            <div className={"col-left"}>
                                                                Zoom
                                                            </div>
                                                            <div className={"col-right"}>
                                                                <input type="range" className="custom-range" min="1"
                                                                       max="7"
                                                                       step="0.1" value={image1Scale}
                                                                       onChange={(event) => setImage1Scale(event.target.value)}/>
                                                            </div>
                                                        </div>

                                                        <div className={"line"}>
                                                            <ConfirmButton text={"Salvează"} function={async () => {

                                                                const dataUrl = cropper1.current.getImageScaledToCanvas().toDataURL()
                                                                const result = await fetch(dataUrl)
                                                                const blob = await result.blob()

                                                                setNewImage1(blob)
                                                                setNewImage1Temp(false)
                                                            }}/>
                                                        </div>
                                                    </div>

                                                </div>
                                                :
                                                <div className={"avatar-cont"}>
                                                    {
                                                        newImage1 ?
                                                            <div className={"image-preview " + (corporateWeddingEvents.includes(eventType) ? 'corporate' : '')}>
                                                                <img  alt={""} src={window.URL.createObjectURL(newImage1)}/>
                                                            </div> :
                                                            image1Link ?
                                                                <div className={"image-preview " + (corporateWeddingEvents.includes(eventType) ? 'corporate' : '')}>
                                                                    <img  alt={""} src={image1Link}/>
                                                                </div>
                                                                : null
                                                    }




                                                    <input
                                                        type="file"
                                                        name="myImage"
                                                        onChange={(event) => {

                                                            setNewImage1Temp(event.target.files[0]);
                                                        }}
                                                    />
                                                </div>
                                            : null
                                    }

                                    { isCorporateWeddingEvent(eventType, true) ?
                                        <p>Dimensiune optimă 330x260 px</p>
                                        :null}

                                    <br/>

                                    {
                                        (eventType === 'Nunta' || eventType === 'Cununie') ?
                                            <>
                                                <div className={"input-title"}>A doua imagine (mire/mireasă)</div>
                                                <Toggle className={"green"}
                                                        style={{
                                                            width: "150px",
                                                            alignSelf: "center",
                                                            marginBottom: "8px"
                                                        }}
                                                        checked={templateDetails.section1.image2}
                                                        onChange={status => {
                                                            changeTemplateSectionValue('section1', 'image2', status)
                                                        }} checkedChildren="Activată"
                                                        unCheckedChildren="Dezactivată"/>
                                                {
                                                    templateDetails.section1.image2 ?
                                                        newImage2Temp ?
                                                            <div className={"avatar-cont"}>
                                                                <AvatarEditor
                                                                    ref={cropper2}
                                                                    image={newImage2Temp}
                                                                    width={230}
                                                                    height={230}
                                                                    border={20}
                                                                    color={[0, 0, 0, 0.6]} // RGBA
                                                                    scale={parseFloat(image2Scale + '')}
                                                                    rotate={0}
                                                                    borderRadius={150}
                                                                    onLoadFailure={(e) => {
                                                                        toast.error('Vă rugăm să încărcați doar fișiere .png sau .jpg!')
                                                                        setNewImage2Temp(null)
                                                                    }}
                                                                    //onImage
                                                                />
                                                                <div className={"actions"}>
                                                                    <div className={"line"}>
                                                                        <div className={"col-left"}>
                                                                            Zoom
                                                                        </div>
                                                                        <div className={"col-right"}>
                                                                            <input type="range" className="custom-range"
                                                                                   min="1"
                                                                                   max="7"
                                                                                   step="0.1" value={image2Scale}
                                                                                   onChange={(event) => setImage2Scale(event.target.value)}/>
                                                                        </div>
                                                                    </div>

                                                                    <div className={"line"}>
                                                                        <ConfirmButton text={"Salvează"}
                                                                                       function={async () => {

                                                                                           const dataUrl = cropper2.current.getImageScaledToCanvas().toDataURL()
                                                                                           const result = await fetch(dataUrl)
                                                                                           const blob = await result.blob()

                                                                                           setNewImage2(blob)
                                                                                           setNewImage2Temp(false)
                                                                                       }}/>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            :
                                                            <div className={"avatar-cont"}>
                                                                {
                                                                    newImage2 ?
                                                                        <div className={"image-preview"}>
                                                                            <img  alt={""}
                                                                                src={window.URL.createObjectURL(newImage2)}/>
                                                                        </div> :
                                                                        image2Link ?
                                                                            <div className={"image-preview"}>
                                                                                <img  alt={""} src={image2Link}/>
                                                                            </div>
                                                                            : null
                                                                }

                                                                <input
                                                                    type="file"
                                                                    name="myImage"
                                                                    onChange={(event) => {

                                                                        setNewImage2Temp(event.target.files[0]);
                                                                    }}
                                                                />
                                                            </div>
                                                        : null
                                                }
                                                <br/>

                                            </>
                                            : null
                                    }

                                    {
                                        (eventType === 'Nunta' || eventType === 'Cununie') ?
                                            <>
                                                <CustomInput
                                                    value={templateDetails.section1.text1}
                                                    onChange={(value) => changeTemplateSectionValue('section1', 'text1', value)}
                                                    placeholder={"Titlu"}
                                                    componentClass={"textarea"}
                                                    className={'text-style-input'}
                                                />

                                                <TextStyleComp
                                                    styles={templateDetails.section1.text1Styles ? templateDetails.section1.text1Styles : {}}
                                                    setStyles={(value) => changeTemplateSectionValue('section1', 'text1Styles', value)}
                                                    borderBottomRadius={true}
                                                />

                                                <CustomInput
                                                    value={templateDetails.section1.groomName}
                                                    onChange={(value) => changeTemplateSectionValue('section1', 'groomName', value)}
                                                    placeholder={"Primul nume (mire/mireasă)"}
                                                    className={'text-style-input'}
                                                />

                                                <TextStyleComp
                                                    styles={templateDetails.section1.groomNameStyles ? templateDetails.section1.groomNameStyles : {}}
                                                    setStyles={(value) => changeTemplateSectionValue('section1', 'groomNameStyles', value)}
                                                    borderBottomRadius={true}
                                                />

                                                <div style={{marginTop: '5px', marginLeft: '8px'}}>
                                                    <label>Separator</label><br/>
                                                    <label>
                                                        <input type={"checkbox"}
                                                               checked={!templateDetails.section1.separator || templateDetails.section1.separator === 0}
                                                               onChange={() => changeTemplateSectionValue('section1', 'separator', 0)}/> &
                                                    </label>
                                                    <label style={{marginLeft: '20px'}}>
                                                        <input type={"checkbox"}
                                                               checked={templateDetails.section1.separator === 1}
                                                               onChange={() => changeTemplateSectionValue('section1', 'separator', 1)}/> și
                                                    </label>
                                                </div>
                                                <TextStyleComp
                                                    styles={templateDetails.section1.separatorStyles ? templateDetails.section1.separatorStyles : {}}
                                                    setStyles={(value) => changeTemplateSectionValue('section1', 'separatorStyles', value)}
                                                />

                                                <CustomInput
                                                    value={templateDetails.section1.brideName}
                                                    onChange={(value) => changeTemplateSectionValue('section1', 'brideName', value)}
                                                    placeholder={"Al doilea nume (mire/mireasă)"}
                                                    className={'text-style-input'}
                                                />

                                                <TextStyleComp
                                                    styles={templateDetails.section1.brideNameStyles ? templateDetails.section1.brideNameStyles : {}}
                                                    setStyles={(value) => changeTemplateSectionValue('section1', 'brideNameStyles', value)}
                                                    borderBottomRadius={true}
                                                />


                                                <CustomInput
                                                    value={templateDetails.section1.text2}
                                                    onChange={(value) => changeTemplateSectionValue('section1', 'text2', value)}
                                                    placeholder={"Descriere invitație"}
                                                    componentClass={"textarea"}
                                                    className={'text-style-input'}
                                                />

                                                <TextStyleComp
                                                    styles={templateDetails.section1.text2Styles ? templateDetails.section1.text2Styles : {}}
                                                    setStyles={(value) => changeTemplateSectionValue('section1', 'text2Styles', value)}
                                                    borderBottomRadius={true}
                                                />


                                                <div className={"input-title"} style={{marginTop: '10px'}}>Organizați și
                                                    botez
                                                    ?
                                                </div>
                                                <Toggle className={"green"}
                                                        style={{
                                                            width: "150px",
                                                            alignSelf: "center",
                                                            marginBottom: "8px"
                                                        }}
                                                        checked={templateDetails.section1.nuntaSiBotez}
                                                        onChange={status => {
                                                            changeTemplateSectionValue('section1', 'nuntaSiBotez', status)
                                                        }} checkedChildren="Activată"
                                                        unCheckedChildren="Dezactivată"/>

                                                {
                                                    templateDetails.section1.nuntaSiBotez ?
                                                        <>
                                                            <CustomInput
                                                                value={templateDetails.section1.childrenName}
                                                                onChange={(value) => changeTemplateSectionValue('section1', 'childrenName', value)}
                                                                placeholder={"Nume copil"}
                                                                className={'text-style-input'}
                                                            />

                                                            <TextStyleComp
                                                                styles={templateDetails.section1.childrenNameStyles ? templateDetails.section1.childrenNameStyles : {}}
                                                                setStyles={(value) => changeTemplateSectionValue('section1', 'childrenNameStyles', value)}
                                                                borderBottomRadius={true}
                                                            />

                                                            <div className={"input-title"}
                                                                 style={{marginTop: '5px'}}>Imagine
                                                                copil
                                                            </div>
                                                            <Toggle className={"green"}
                                                                    style={{
                                                                        width: "150px",
                                                                        alignSelf: "center",
                                                                        marginBottom: "8px"
                                                                    }}
                                                                    checked={templateDetails.section1.image3}
                                                                    onChange={status => {
                                                                        changeTemplateSectionValue('section1', 'image3', status)
                                                                    }} checkedChildren="Activată"
                                                                    unCheckedChildren="Dezactivată"/>
                                                            {
                                                                templateDetails.section1.image3 ?
                                                                    newImage3Temp ?
                                                                        <div className={"avatar-cont"}>
                                                                            <AvatarEditor
                                                                                ref={cropper3}
                                                                                image={newImage3Temp}
                                                                                width={230}
                                                                                height={230}
                                                                                border={20}
                                                                                color={[0, 0, 0, 0.6]} // RGBA
                                                                                scale={parseFloat(image3Scale + '')}
                                                                                rotate={0}
                                                                                borderRadius={150}
                                                                                onLoadFailure={(e) => {
                                                                                    toast.error('Vă rugăm să încărcați doar fișiere .png sau .jpg!')
                                                                                    setNewImage3Temp(null)
                                                                                }}
                                                                                //onImage
                                                                            />
                                                                            <div className={"actions"}>
                                                                                <div className={"line"}>
                                                                                    <div className={"col-left"}>
                                                                                        Zoom
                                                                                    </div>
                                                                                    <div className={"col-right"}>
                                                                                        <input type="range"
                                                                                               className="custom-range"
                                                                                               min="1"
                                                                                               max="7"
                                                                                               step="0.1"
                                                                                               value={image3Scale}
                                                                                               onChange={(event) => setImage3Scale(event.target.value)}/>
                                                                                    </div>
                                                                                </div>

                                                                                <div className={"line"}>
                                                                                    <ConfirmButton text={"Salvează"}
                                                                                                   function={async () => {

                                                                                                       const dataUrl = cropper3.current.getImageScaledToCanvas().toDataURL()
                                                                                                       const result = await fetch(dataUrl)
                                                                                                       const blob = await result.blob()

                                                                                                       setNewImage3(blob)
                                                                                                       setNewImage3Temp(false)
                                                                                                   }}/>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                        :
                                                                        <div className={"avatar-cont"}>
                                                                            {
                                                                                newImage3 ?
                                                                                    <div className={"image-preview"}>
                                                                                        <img  alt={""}
                                                                                            src={window.URL.createObjectURL(newImage3)}/>
                                                                                    </div> :
                                                                                    image3Link ?
                                                                                        <div
                                                                                            className={"image-preview"}>
                                                                                            <img  alt={""} src={image3Link}/>
                                                                                        </div>
                                                                                        : null
                                                                            }

                                                                            <input
                                                                                type="file"
                                                                                name="myImage"
                                                                                onChange={(event) => {

                                                                                    setNewImage3Temp(event.target.files[0]);
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    : null
                                                            }
                                                        </>
                                                        : null
                                                }


                                            </>
                                            : null
                                    }

                                    {
                                        (eventType === 'Botez' || eventType === "Mot" || eventType === "Turta") ?
                                            <>
                                                <CustomInput
                                                    value={templateDetails.section1.text1}
                                                    onChange={(value) => changeTemplateSectionValue('section1', 'text1', value)}
                                                    placeholder={"Titlu"}
                                                    componentClass={"textarea"}
                                                    className={'text-style-input'}
                                                />

                                                <TextStyleComp
                                                    styles={templateDetails.section1.text1Styles ? templateDetails.section1.text1Styles : {}}
                                                    setStyles={(value) => changeTemplateSectionValue('section1', 'text1Styles', value)}
                                                    borderBottomRadius={true}
                                                />

                                                <CustomInput
                                                    value={templateDetails.section1.groomName}
                                                    onChange={(value) => {
                                                        changeTemplateSectionValue('section1', 'groomName', value)
                                                    }}
                                                    placeholder={"Numele copilului"}
                                                    className={'text-style-input'}
                                                />

                                                <TextStyleComp
                                                    styles={templateDetails.section1.groomNameStyles ? templateDetails.section1.groomNameStyles : {}}
                                                    setStyles={(value) => changeTemplateSectionValue('section1', 'groomNameStyles', value)}
                                                    borderBottomRadius={true}
                                                />

                                                <CustomInput
                                                    value={templateDetails.section1.text2}
                                                    onChange={(value) => changeTemplateSectionValue('section1', 'text2', value)}
                                                    placeholder={"Împreună cu părinții"}
                                                    className={'text-style-input'}
                                                />

                                                <TextStyleComp
                                                    styles={templateDetails.section1.text2Styles ? templateDetails.section1.text2Styles : {}}
                                                    setStyles={(value) => changeTemplateSectionValue('section1', 'text2Styles', value)}
                                                    borderBottomRadius={true}
                                                />

                                                <CustomInput
                                                    value={templateDetails.section1.parents}
                                                    onChange={(value) => changeTemplateSectionValue('section1', 'parents', value)}
                                                    placeholder={"Numele părinților"}
                                                    className={'text-style-input'}
                                                    componentClass={"textarea"}
                                                />

                                                <TextStyleComp
                                                    styles={templateDetails.section1.parentsStyles ? templateDetails.section1.parentsStyles : {}}
                                                    setStyles={(value) => changeTemplateSectionValue('section1', 'parentsStyles', value)}
                                                    borderBottomRadius={true}
                                                />

                                                <CustomInput
                                                    value={templateDetails.section1.text3}
                                                    onChange={(value) => changeTemplateSectionValue('section1', 'text3', value)}
                                                    placeholder={"Având alături nașii"}
                                                    className={'text-style-input'}
                                                />

                                                <TextStyleComp
                                                    styles={templateDetails.section1.text3Styles ? templateDetails.section1.text3Styles : {}}
                                                    setStyles={(value) => changeTemplateSectionValue('section1', 'text3Styles', value)}
                                                    borderBottomRadius={true}
                                                />

                                                <CustomInput
                                                    value={templateDetails.section1.text4}
                                                    onChange={(value) => changeTemplateSectionValue('section1', 'text4', value)}
                                                    placeholder={"Numele nașilor"}
                                                    className={'text-style-input'}
                                                    componentClass={"textarea"}
                                                />

                                                <TextStyleComp
                                                    styles={templateDetails.section1.text4Styles ? templateDetails.section1.text4Styles : {}}
                                                    setStyles={(value) => changeTemplateSectionValue('section1', 'text4Styles', value)}
                                                    borderBottomRadius={true}
                                                />

                                                <CustomInput
                                                    value={templateDetails.section1.text5}
                                                    onChange={(value) => changeTemplateSectionValue('section1', 'text5', value)}
                                                    placeholder={"Descriere invitație"}
                                                    componentClass={"textarea"}
                                                    className={'text-style-input'}
                                                />

                                                <TextStyleComp
                                                    styles={templateDetails.section1.text5Styles ? templateDetails.section1.text5Styles : {}}
                                                    setStyles={(value) => changeTemplateSectionValue('section1', 'text5Styles', value)}
                                                    borderBottomRadius={true}
                                                />
                                            </>
                                            : null
                                    }

                                    {eventType && eventType === 'Petrecere' ?
                                            <>
                                                <CustomInput
                                                    value={templateDetails.section1.groomName}
                                                    onChange={(value) => changeTemplateSectionValue('section1', 'groomName', value)}
                                                    placeholder={"Titlul evenimentului"}
                                                    componentClass={"textarea"}
                                                    className={'text-style-input'}
                                                />

                                                <TextStyleComp
                                                    styles={templateDetails.section1.groomNameStyles ? templateDetails.section1.groomNameStyles : {}}
                                                    setStyles={(value) => changeTemplateSectionValue('section1', 'groomNameStyles', value)}
                                                    borderBottomRadius={true}
                                                />

                                                <CustomInput
                                                    value={templateDetails.section1.text2}
                                                    onChange={(value) => changeTemplateSectionValue('section1', 'text2', value)}
                                                    placeholder={"Descriere invitație"}
                                                    componentClass={"textarea"}
                                                    className={'text-style-input'}
                                                />

                                                <TextStyleComp
                                                    styles={templateDetails.section1.text2Styles ? templateDetails.section1.text2Styles : {}}
                                                    setStyles={(value) => changeTemplateSectionValue('section1', 'text2Styles', value)}
                                                    borderBottomRadius={true}
                                                />

                                            </>
                                            : null
                                    }

                                    { isCorporateWeddingEvent(eventType, true) ?
                                        <>
                                            <CustomInput
                                                value={templateDetails.section1.text1}
                                                onChange={(value) => changeTemplateSectionValue('section1', 'text1', value)}
                                                placeholder={"Introducere"}
                                                componentClass={"textarea"}
                                                className={'text-style-input'}
                                            />
                                            <TextStyleComp
                                                styles={templateDetails.section1.text1 ? templateDetails.section1.text1Styles : {}}
                                                setStyles={(value) => changeTemplateSectionValue('section1', 'text1Styles', value)}
                                                borderBottomRadius={true}
                                            />

                                            <CustomInput
                                                value={templateDetails.section1.groomName}
                                                onChange={(value) => changeTemplateSectionValue('section1', 'groomName', value)}
                                                placeholder={"Titlul evenimentului"}
                                                componentClass={"textarea"}
                                                className={'text-style-input'}
                                            />

                                            <TextStyleComp
                                                styles={templateDetails.section1.groomNameStyles ? templateDetails.section1.groomNameStyles : {}}
                                                setStyles={(value) => changeTemplateSectionValue('section1', 'groomNameStyles', value)}
                                                borderBottomRadius={true}
                                            />

                                            <CustomInput
                                                value={templateDetails.section1.text2}
                                                onChange={(value) => changeTemplateSectionValue('section1', 'text2', value)}
                                                placeholder={"Descriere invitație"}
                                                componentClass={"textarea"}
                                                className={'text-style-input'}
                                            />

                                            <TextStyleComp
                                                styles={templateDetails.section1.text2Styles ? templateDetails.section1.text2Styles : {}}
                                                setStyles={(value) => changeTemplateSectionValue('section1', 'text2Styles', value)}
                                                borderBottomRadius={true}
                                            />

                                        </>
                                        : null
                                    }

                                    <Button
                                        onClick={() => {
                                            setTemplateDetails(templateDetails)

                                            let newWeddingData = {...weddingData}
                                            if (eventType === 'Nunta' || eventType === 'Cununie') {
                                                newWeddingData.prenumeMire = templateDetails.section1.groomName
                                                newWeddingData.prenumeMireasa = templateDetails.section1.brideName
                                                saveWeddingData(newWeddingData, locations)
                                            }
                                            if (eventType === 'Botez' || eventType === 'Mot' || eventType === 'Turta') {
                                                newWeddingData.prenumeMire = templateDetails.section1.groomName
                                                saveWeddingData(newWeddingData, locations)
                                            }
                                            if (eventType === 'Petrecere' || isCorporateWeddingEvent(eventType,true)) {
                                                newWeddingData.prenumeMire = templateDetails.section1.groomName
                                                saveWeddingData(newWeddingData, locations)
                                            }


                                            toggleModal('section1')
                                        }}
                                        color="green"
                                        style={{
                                            backgroundColor: "#F26157",
                                            borderRadius: "20px",
                                            width: "100%",
                                            marginTop: "20px",
                                            marginBottom: "10px"
                                        }}>Previzualizează</Button>
                                </div>
                            </Modal>

                            <Modal classNames={{
                                overlay: 'modal-overlay',
                                modal: 'modal-no-top',
                                closeButton: 'modal-close-button',
                            }} focusTrapped={false} open={showModalSection2} onClose={() => {
                                toggleModal('section2')
                            }}>

                                <div className={"invite-section-edit-modal"} id={"inv-sec-ed-mod"}>

                                    <div className={"input-title"}>Data evenimentului</div>
                                    <DatePicker ranges={[]}
                                                container={() => document.getElementById("inv-sec-ed-mod")}
                                                format="DD-MM-YYYY"
                                                value={new Date(templateDetails.section2.date)}
                                                style={{
                                                    width: "100%",
                                                    borderRadius: "5px",
                                                    backgroundColor: "#E5F7FE",
                                                    marginBottom: '10px'
                                                }}
                                                placement="bottomStart"
                                                disabledDate={date => isBefore(date, new Date(new Date().setDate(new Date().getDate() - 1))) || isAfter(date, moment().add(getMaxYearsPrivateEvent(eventType), 'years').toDate())}
                                                defaultOpen={false} oneTap
                                                onSelect={(date) => {
                                                    changeTemplateSectionValue('section2', 'date', date)
                                                }}
                                                appearance="subtle" placeholder="Data evenimentului"
                                                locale={{
                                                    sunday: 'Sa',
                                                    monday: 'Lu',
                                                    tuesday: 'Ma',
                                                    wednesday: 'Mi',
                                                    thursday: 'Jo',
                                                    friday: 'Vi',
                                                    saturday: 'Sa',
                                                    ok: 'OK',
                                                    today: 'Azi',
                                                    yesterday: 'Ieri',
                                                    hours: 'Ore',
                                                    minutes: 'Minute',
                                                    seconds: 'Secunde'
                                                }}
                                                cleanable={false}
                                    />

                                    <div className={"input-title"}>Ora evenimentului (pentru countdown)</div>
                                    <DatePicker ranges={[]}
                                                format="HH:mm"
                                                style={{
                                                    width: "100%",
                                                    borderRadius: "5px",
                                                    backgroundColor: "#E5F7FE",
                                                    marginBottom: '10px'
                                                }}
                                                placement="bottomStart"
                                                disabledDate={date => isBefore(date, new Date(new Date().setDate(new Date().getDate() - 1)))}
                                                defaultOpen={false} block
                                                appearance="subtle" placeholder="Ora evenimentului"
                                                hideMinutes={minute => minute % 5 !== 0}
                                                locale={{
                                                    sunday: 'Sa',
                                                    monday: 'Lu',
                                                    tuesday: 'Ma',
                                                    wednesday: 'Mi',
                                                    thursday: 'Jo',
                                                    friday: 'Vi',
                                                    saturday: 'Sa',
                                                    ok: 'OK',
                                                    today: 'Azi',
                                                    yesterday: 'Ieri',
                                                    hours: 'Ore',
                                                    minutes: 'Minute',
                                                    seconds: 'Secunde'
                                                }}
                                                className={'text-style-input'}
                                                cleanable={false}
                                                onSelect={(date) => {
                                                    //setLocationData(moment(date).tz('Europe/Bucharest', true).toDate())
                                                    //date = moment(date.getHours() + ':' + date.getMinutes(), "HH:mm").toDate()
                                                    changeTemplateSectionValue('section2', 'date', convertDateTimeToDefaultTimezone(date))
                                                }}
                                                value={convertDateTimeToBrowserTimezone(new Date(templateDetails.section2.date))}
                                                renderValue={(date) => moment(date).format('HH:mm')}
                                    />


                                    <DateContainerStyleComp
                                        styles={templateDetails.section2.dateStyles ? templateDetails.section2.dateStyles : {}}
                                        setStyles={(value) => changeTemplateSectionValue('section2', 'dateStyles', value)}
                                        borderBottomRadius={true}
                                    />

                                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                        <Checkbox onChange={() => {
                                            let val = templateDetails.section2 && (templateDetails.section2.show_countdown !== false)
                                            let newTemplateDetails = {...templateDetails}
                                            newTemplateDetails.section2.show_countdown = !val
                                            setTemplateDetails(newTemplateDetails)
                                        }}
                                                  checked={templateDetails.section2 && (templateDetails.section2.show_countdown !== false)}/>
                                        <div style={{cursor: "pointer"}} onClick={() => {
                                            let val = templateDetails.section2 && (templateDetails.section2.show_countdown !== false)
                                            let newTemplateDetails = {...templateDetails}
                                            newTemplateDetails.section2.show_countdown = !val
                                            setTemplateDetails(newTemplateDetails)
                                        }}>Afișează timp rămas
                                        </div>
                                    </div>

                                    {
                                        eventType === 'Botez' || eventType === 'Mot' || eventType === 'Turta' || eventType === 'Petrecere' || eventType && isCorporateWeddingEvent(eventType, true) ?
                                            <>
                                                <div className="location">
                                                    <div className="header">
                                                        <div style={columnStyle}>Locatie</div>

                                                        <div style={columnStyle}>Adresa</div>

                                                        <div style={columnStyle}>Data</div>

                                                        <div style={{width: "10%"}}>Edit</div>
                                                    </div>

                                                    {locations.map((element, index) => {
                                                        let addr = element.address.split(',')[0]
                                                        addr = checkAndGetLocationLabel(element, weddingData)

                                                        return <div
                                                            className={index % 2 === 0 ? "even-line" : "uneven-line"}
                                                            key={index}
                                                            onClick={() => editLocation(index)}>
                                                            <div style={columnStyle}> {element.name}</div>
                                                            <div style={columnStyle}> {addr.toString()} </div>
                                                            <div
                                                                style={columnStyle}>
                                                                {moment(element.day).format('DD-MM-YYYY')} {moment(element.time).format('HH:mm')} </div>
                                                            <div style={{width: "10%"}}><Icon icon={"edit"}/></div>
                                                        </div>
                                                    })}
                                                </div>

                                                <LocationsContainerStyleComp
                                                    styles={templateDetails.section2.locationsStyles ? templateDetails.section2.locationsStyles : {}}
                                                    setStyles={(value) => changeTemplateSectionValue('section2', 'locationsStyles', value)}

                                                />

                                                {(weddingData && weddingData.invite && weddingData.invite.language &&
                                                    (weddingData.invite.language !== 'ro' && weddingData.invite.language !== '')) ?
                                                    null
                                                    :
                                                    <div className='add-small-button' onClick={() => {
                                                        if (demo) {
                                                            setShowDemoModal(true)
                                                            toast.info('Această funcționalitate va fi disponibilă după ce apăsați pe butonul „Creează invitația” și vă autentificați în aplicația Ality.')
                                                            return
                                                        }

                                                        setLocationName('')
                                                        setLocationAddress('')
                                                        setLocationLabel('')
                                                        setLocationType('')
                                                        setLocationData(new Date())
                                                        setLocationDay(new Date(weddingData.dataEvent))
                                                        setEditLocationIndex(-1)
                                                        setShowLocationsModal(true)
                                                    }} style={{marginTop: '15px', marginBottom: '10px', width: '100%'}}>

                                                        <Button color="green" style={{
                                                            backgroundColor: "#05AFF2",
                                                            borderRadius: "20px",
                                                            width: "100%"
                                                        }}><Icon icon="plus-circle" size="lg"
                                                                 style={{color: "#FFFFFF", marginRight: "10px"}}/>Adaugă
                                                            dată și locație</Button>
                                                    </div>}
                                            </>
                                            : null
                                    }

                                    {
                                        (eventType === 'Nunta' || eventType === 'Cununie') ?
                                            <>
                                                <CustomInput
                                                    value={templateDetails.section2.text1}
                                                    onChange={(value) => changeTemplateSectionValue('section2', 'text1', value)}
                                                    placeholder={"Text alaturi de"}
                                                    componentClass={"textarea"}
                                                    className={'text-style-input'}
                                                />

                                                <TextStyleComp
                                                    styles={templateDetails.section2.text1Styles ? templateDetails.section2.text1Styles : {}}
                                                    setStyles={(value) => changeTemplateSectionValue('section2', 'text1Styles', value)}
                                                    borderBottomRadius={true}
                                                />

                                                <CustomInput
                                                    value={templateDetails.section2.groomParents}
                                                    onChange={(value) => changeTemplateSectionValue('section2', 'groomParents', value)}
                                                    placeholder={"Părinții mirelui"}
                                                    className={'text-style-input'}
                                                    componentClass={"textarea"}
                                                />

                                                <TextStyleComp
                                                    styles={templateDetails.section2.groomParentsStyles ? templateDetails.section2.groomParentsStyles : {}}
                                                    setStyles={(value) => changeTemplateSectionValue('section2', 'groomParentsStyles', value)}
                                                    borderBottomRadius={true}
                                                />

                                                <CustomInput
                                                    value={templateDetails.section2.brideParents}
                                                    onChange={(value) => changeTemplateSectionValue('section2', 'brideParents', value)}
                                                    placeholder={"Părinții miresei"}
                                                    className={'text-style-input'}
                                                    componentClass={"textarea"}
                                                />

                                                <TextStyleComp
                                                    styles={templateDetails.section2.brideParentsStyles ? templateDetails.section2.brideParentsStyles : {}}
                                                    setStyles={(value) => changeTemplateSectionValue('section2', 'brideParentsStyles', value)}
                                                    borderBottomRadius={true}
                                                />


                                                <CustomInput
                                                    value={templateDetails.section2.text2}
                                                    onChange={(value) => changeTemplateSectionValue('section2', 'text2', value)}
                                                    placeholder={"Text alături de"}
                                                    componentClass={"textarea"}
                                                    className={'text-style-input'}
                                                />

                                                <TextStyleComp
                                                    styles={templateDetails.section2.text2Styles ? templateDetails.section2.text2Styles : {}}
                                                    setStyles={(value) => changeTemplateSectionValue('section2', 'text2Styles', value)}
                                                    borderBottomRadius={true}
                                                />
                                                <CustomInput
                                                    value={templateDetails.section2.text3}
                                                    onChange={(value) => changeTemplateSectionValue('section2', 'text3', value)}
                                                    placeholder={"Numele nașilor"}
                                                    componentClass={"textarea"}
                                                    className={'text-style-input'}
                                                />

                                                <TextStyleComp
                                                    styles={templateDetails.section2.text3Styles ? templateDetails.section2.text3Styles : {}}
                                                    setStyles={(value) => changeTemplateSectionValue('section2', 'text3Styles', value)}
                                                    borderBottomRadius={true}
                                                />
                                            </>
                                            :
                                            null
                                    }

                                    <Button
                                        onClick={() => {
                                            setTemplateDetails(templateDetails)
                                            if (showModalSection2) {
                                                persistLocations()
                                            }
                                            toggleModal('section2')
                                        }}
                                        color="green"
                                        style={{
                                            backgroundColor: "#F26157",
                                            borderRadius: "20px",
                                            width: "100%",
                                            marginTop: "20px",
                                            marginBottom: "10px"
                                        }}>Previzualizează</Button>
                                </div>
                            </Modal>


                            <Modal classNames={{
                                overlay: 'modal-overlay',
                                modal: 'modal-no-top modal-add-location',
                                closeButton: 'modal-close-button'
                            }} open={showLocationsModal} onClose={() => setShowLocationsModal(false)}>
                                <div style={{margin: "10px 0px"}}>

                                    <SelectPicker
                                        size="lg"
                                        onSelect={(val) => setLocationType(val)}
                                        value={locationType !== "Alta locatie" ? locationType : "Alta locatie"}
                                        searchable={false}
                                        appearance="subtle"
                                        placement="bottom"
                                        id="job-select"
                                        data={locationsTypes}
                                        style={{
                                            width: "100%",
                                            marginTop: "20px",
                                            borderRadius: "5px",
                                            backgroundColor: "#E5F7FE"
                                        }}
                                        placeholder="Selecteaza locatie"
                                    >
                                    </SelectPicker>
                                </div>

                                <div style={{marginBottom: "10px"}}>
                                    {locationType === "Alta locatie"
                                        ? <CustomInput style={{
                                            height: "40px",
                                            width: "100%",
                                            borderRadius: "5px",
                                            padding: "5px",
                                            backgroundColor: "#E5F7FE"
                                        }}
                                                       value={locationName}
                                                       onChange={(val) => setLocationName(val)}
                                                       placeholder={"Nume Locatie"}/>
                                        : null
                                    }
                                </div>

                                <div style={{margin: "10px 0px"}}>
                                    <DatePicker
                                        appearance="subtle"
                                        style={{
                                            width: "100%",
                                            borderRadius: "5px",
                                            height: "40px",
                                            backgroundColor: "#E5F7FE"
                                        }}
                                        ranges={[]}
                                        block
                                        locale={{
                                            sunday: 'Sa',
                                            monday: 'Lu',
                                            tuesday: 'Ma',
                                            wednesday: 'Mi',
                                            thursday: 'Jo',
                                            friday: 'Vi',
                                            saturday: 'Sa',
                                            ok: 'OK',
                                            today: 'Azi',
                                            yesterday: 'Ieri',
                                            hours: 'Ore',
                                            minutes: 'Minute',
                                            seconds: 'Secunde'
                                        }}
                                        placeholder="Ora eveniment"
                                        size="lg"
                                        onSelect={(date) => setLocationDay(date)}
                                        value={new Date(locationDay)}
                                        placement="bottomEnd"
                                    />
                                </div>

                                <div style={{marginBottom: "10px"}}>
                                    <DatePicker
                                        appearance="subtle"
                                        style={{
                                            width: "100%",
                                            borderRadius: "5px",
                                            height: "40px",
                                            backgroundColor: "#E5F7FE"
                                        }}
                                        ranges={[]}
                                        block
                                        hideMinutes={minute => minute % 5 !== 0}
                                        locale={{
                                            sunday: 'Sa',
                                            monday: 'Lu',
                                            tuesday: 'Ma',
                                            wednesday: 'Mi',
                                            thursday: 'Jo',
                                            friday: 'Vi',
                                            saturday: 'Sa',
                                            ok: 'OK',
                                            today: 'Azi',
                                            yesterday: 'Ieri',
                                            hours: 'Ore',
                                            minutes: 'Minute',
                                            seconds: 'Secunde'
                                        }}
                                        placeholder="Ora eveniment"
                                        size="lg"
                                        onSelect={(date) => {
                                            //setLocationData(moment(date).tz('Europe/Bucharest', true).toDate())
                                            //date = moment(date.getHours() + ':' + date.getMinutes(), "HH:mm").toDate()
                                            setLocationData(convertDateTimeToDefaultTimezone(date))
                                        }}
                                        value={convertDateTimeToBrowserTimezone(locationData)}
                                        placement="bottomEnd"
                                        format="HH:mm"
                                        renderValue={(date) => moment(date).format('HH:mm')}

                                    />
                                </div>

                                <div>
                                    <label>
                                        <input type={"checkbox"} checked={mapsRestrictions === true}
                                               onChange={() => setMapsRestrictions(true)}/> Locații din România
                                    </label>
                                    <label style={{marginLeft: '10px'}}>
                                        <input type={"checkbox"} checked={mapsRestrictions === false}
                                               onChange={() => setMapsRestrictions(false)}/> Locații din toată lumea
                                    </label>
                                </div>

                                <div style={{width: "100%", marginTop: "5px"}} id='fromGoogleAutocomplete'>
                                    <GooglePlacesAutocomplete
                                        debounce={500}
                                        minLengthAutocomplete={3}
                                        currentLocation={false}
                                        placeholder={"Adresa"}
                                        onSelect={value => {
                                            setLocationAddress(value.description)
                                            setLocationLabel(value.description)
                                        }}
                                        autocompletionRequest={{
                                            componentRestrictions: mapsRestrictions ? {
                                                country: 'ro'
                                            } : {}
                                        }}
                                        initialValue={locationAddress}
                                        inputStyle={{
                                            width: "100%",
                                            padding: "10px",
                                            backgroundColor: "#E5F7FE",
                                            border: "0px",
                                            borderRadius: "7px 11px",
                                            height: "40px"
                                        }}

                                    />
                                </div>

                                <p style={{marginTop: "10px"}}>Căutarea adreselor se face prin intermediul Google Maps.
                                    În cazul în care adresa dvs. nu există, puteți completa doar câmpul ”Denumire” însă
                                    în acest
                                    caz
                                    integrarea cu Google Maps și Waze va fi indisponibilă.
                                    <br/>
                                    În cazul în care adresa dvs. există dar doriți să modificați textul sau să adăugați
                                    detalii
                                    suplimentare trebuie doar să modificați câmpul ”Denumire”.
                                </p>

                                <CustomInput
                                    style={{
                                        height: "40px",
                                        width: "100%",
                                        borderRadius: "5px",
                                        padding: "5px",
                                        backgroundColor: "#E5F7FE",
                                        marginTop: "15px"
                                    }}
                                    value={locationLabel}
                                    onChange={(val) => {
                                        setLocationLabel(val)
                                    }} placeholder={"Denumire"}/>


                                {editLocationIndex !== -1 ? <>
                                        <div style={{display: "flex", width: "100%", justifyContent: "space-between"}}>
                                            {(weddingData && weddingData.invite && weddingData.invite.language &&
                                                (weddingData.invite.language !== 'ro' && weddingData.invite.language !== '')) ?
                                                null
                                                :
                                                <Button onClick={() => removeLocation()} style={{
                                                    flex: 1,
                                                    marginTop: 20,
                                                    marginBottom: 20,
                                                    marginRight: '10px',
                                                    borderRadius: "80px"
                                                }} color="red">Șterge</Button>}
                                            <Button onClick={() => addLocation()} style={{
                                                flex: 1,
                                                marginTop: 20,
                                                marginBottom: 20,
                                                marginLeft: '10px',
                                                borderRadius: "80px",
                                                backgroundColor: "#00afcc",
                                                color: "white"
                                            }} color="green">Salvează</Button>
                                        </div>

                                        <div>
                                            <BackButton text={"Înapoi"} function={() => setShowLocationsModal(false)}/>
                                        </div>
                                    </>
                                    :
                                    <BackAndConfirmButtons textBack={"Înapoi"} textConfirm={"Salveaza"}
                                                           functionBack={() => setShowLocationsModal(false)}
                                                           functionConfirm={() => addLocation()}/>
                                }
                            </Modal>

                            <Modal classNames={{
                                overlay: 'modal-overlay',
                                modal: 'modal-no-top',
                                closeButton: 'modal-close-button',
                            }} focusTrapped={false} open={showModalSection3} onClose={() => {
                                if (showModalSection3) {
                                    persistLocations()
                                }
                                toggleModal('section3')
                            }}>

                                <div className={"invite-section-edit-modal"}>

                                    {
                                        (eventType === 'Nunta' || eventType === 'Cununie') ?
                                            <>
                                                <div className="location">
                                                    <div className="header">
                                                        <div style={columnStyle}>Locatie</div>

                                                        <div style={columnStyle}>Adresa</div>

                                                        <div style={columnStyle}>Data</div>

                                                        <div style={{width: "10%"}}>Edit</div>
                                                    </div>

                                                    {locations.map((element, index) => {
                                                        let addr = element.address.split(',')[0]
                                                        addr = checkAndGetLocationLabel(element, weddingData)
                                                        let time = element.time

                                                        if (typeof time === "string") {
                                                            time = new Date(element.time);
                                                        }

                                                        return <div
                                                            className={index % 2 === 0 ? "even-line" : "uneven-line"}
                                                            key={index}
                                                            onClick={() => editLocation(index)}>
                                                            <div style={columnStyle}> {element.name}</div>
                                                            <div style={columnStyle}> {addr.toString()} </div>
                                                            <div
                                                                style={columnStyle}>
                                                                {moment(element.day).format('DD-MM-YYYY')} {moment(element.time).format('HH:mm')} </div>
                                                            <div style={{width: "10%"}}><Icon icon={"edit"}/></div>
                                                        </div>
                                                    })}
                                                </div>
                                                <LocationsContainerStyleComp
                                                    styles={templateDetails.section3 && templateDetails.section3.locationsStyles ? templateDetails.section3.locationsStyles : {}}
                                                    setStyles={(value) => changeTemplateSectionValue('section3', 'locationsStyles', value)}

                                                />
                                                {(weddingData && weddingData.invite && weddingData.invite.language &&
                                                    (weddingData.invite.language !== 'ro' && weddingData.invite.language !== '')) ?
                                                    null
                                                    :
                                                    <div className='add-small-button' onClick={() => {
                                                        if (demo) {
                                                            setShowDemoModal(true)
                                                            toast.info('Această funcționalitate va fi disponibilă după ce apăsați pe butonul „Creează invitația” și vă autentificați în aplicația Ality.')
                                                            return
                                                        }

                                                        setLocationName('')
                                                        setLocationAddress('')
                                                        setLocationLabel('')
                                                        setLocationType('')
                                                        setLocationData(new Date())
                                                        setLocationDay(new Date(weddingData.dataEvent))
                                                        setEditLocationIndex(-1)
                                                        setShowLocationsModal(true)
                                                        setShowLocationsModal(true)
                                                    }} style={{marginTop: '15px', marginBottom: '10px', width: '100%'}}>

                                                        <Button color="green" style={{
                                                            backgroundColor: "#05AFF2",
                                                            borderRadius: "20px",
                                                            width: "100%"
                                                        }}><Icon icon="plus-circle" size="lg"
                                                                 style={{color: "#FFFFFF", marginRight: "10px"}}/>Adaugă
                                                            dată și locație</Button>
                                                    </div>}
                                            </>
                                            : null
                                    }

                                    <CustomInput
                                        value={templateDetails.section3.text4}
                                        onChange={(value) => changeTemplateSectionValue('section3', 'text4', value)}
                                        placeholder={"Frază de încheiere"}
                                        componentClass={"textarea"}
                                        className={'text-style-input'}
                                    />

                                    <TextStyleComp
                                        styles={templateDetails.section3.text4Styles ? templateDetails.section3.text4Styles : {}}
                                        setStyles={(value) => changeTemplateSectionValue('section3', 'text4Styles', value)}
                                        borderBottomRadius={true}
                                    />

                                    <Button
                                        onClick={() => {
                                            if (showModalSection3) {
                                                persistLocations()
                                            }
                                            setTemplateDetails(templateDetails)
                                            toggleModal('section3')
                                        }}
                                        color="green"
                                        style={{
                                            backgroundColor: "#F26157",
                                            borderRadius: "20px",
                                            width: "100%",
                                            marginTop: "20px",
                                            marginBottom: "10px"
                                        }}>Previzualizează</Button>
                                </div>
                            </Modal>

                            <Modal classNames={{
                                overlay: 'modal-overlay',
                                modal: 'modal-no-top',
                                closeButton: 'modal-close-button',
                            }} focusTrapped={false} open={showResetModal} onClose={() => {
                                setShowResetModal(false)
                            }}>

                                <div className={"invite-section-edit-modal"}>

                                    <p>Vă rugăm alegeți informațiile pe care doriți să le resetați la valorile
                                        inițiale.</p>

                                    <Button
                                        onClick={() => {
                                            setTemplateDetails(resetTemplateStyles(templateDetails))
                                            setShowResetModal(false)
                                        }}
                                        color="green"
                                        style={{
                                            borderRadius: "20px",
                                            width: "100%",
                                            marginTop: "20px",
                                        }}>Resetează stilurile</Button>

                                    <Button
                                        onClick={() => {
                                            setTemplateDetails({
                                                eventType: templateDetails.eventType,
                                                id: templateDetails.id,
                                                ...getDefaultTemplateValues({data: weddingData}, templateDetails.id, 'ro', ['invite'])
                                            })
                                            setShowResetModal(false)
                                        }}
                                        color="blue"
                                        style={{
                                            borderRadius: "20px",
                                            width: "100%",
                                            marginTop: "20px",
                                            marginBottom: "10px"
                                        }}>Resetează stilurile și textele</Button>
                                </div>
                            </Modal>

              </>
                : null
        }

    </div>)
}
