import React from 'react'
import './style.scss'

import SVG_Invite from '../../../../../assets/InviteNavBar/n-invite.svg'
import SVG_Invite_Active from '../../../../../assets/InviteNavBar/n-invite-colored.svg'

import SVG_Intro from '../../../../../assets/InviteNavBar/intro-screen.svg'
import SVG_Intro_Active from '../../../../../assets/InviteNavBar/intro-screen-colored.svg'

import SVG_Animation from '../../../../../assets/InviteNavBar/animation-screen.svg'
import SVG_Animation_Active from '../../../../../assets/InviteNavBar/animation-screen-colored.svg'


import SVG_Settings_Active from '../../../../../assets/NavBarSVGs/settings-active-icon.svg'
import SVG_Settings from '../../../../../assets/NavBarSVGs/settings-icon.svg'

import {FormattedMessage} from "react-intl";
import EditInviteWalkthrough from "../../walkthrough/EditInviteWalkthrough";
import SVG_Walkthrough_Arrow from "../../../../../../assets/icons/walkthrough_arrow.svg";
import step from "react-reveal/src/lib/Step";

class InviteNavBar extends React.Component {
    state = {}


    getMobileOperatingSystem = () => {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            return "Windows Phone";
        }

        if (/android/i.test(userAgent)) {
            return "Android";
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "iOS";
        }

        return "unknown";
    }

    os = this.getMobileOperatingSystem()

    componentDidMount = () => {

    }

    render() {
        let svgStyle = {height: "23px"}

        return (<>
                {this.props.showWalkthrough ?
                    <EditInviteWalkthrough
                        eventId={this.props.eventId}
                        slug={'editInviteWalkthrough'}
                        step={this.props.walkthroughStep}
                        setStep={(value) => this.props.setWalkthroughStep(value)}
                        callback={() => {
                            this.props.closeWalkthgrough()
                        }}
                        changeTab={this.props.changeTab}
                    >

                    </EditInviteWalkthrough>
                    : null}

                <div
                    className={"InviteNavBar-container " + (this.props.showWalkthrough && this.props.walkthroughStep > 1 ? 'absolute' : '')}>
                    <div className="InviteNavBar-contents"
                         style={this.os === 'Android' ? {fontSize: '8px'} : {}}>

                        <div onClick={() => {
                            //Disable in walkthrough
                            if (this.props.showWalkthrough && this.props.walkthroughStep !== 2) {
                                return
                            }
                            this.props.changeTab('invitation')
                        }}
                             className={this.props.active === "invitation" ? "active-element" : "menu-element"}>
                            <div className={"icon-row"}>
                                <img src={this.props.active === "invitation" ? SVG_Invite_Active : SVG_Invite} alt=""
                                     style={svgStyle}/>
                            </div>
                            <div className={"text-row"}>
                                <FormattedMessage id={"menu.edit_invite.invite"}/>
                            </div>
                        </div>

                        {this.props.showIntroScreen ?
                            <div onClick={() => {
                                //Disable in walkthrough
                                if (this.props.showWalkthrough) {
                                    if(this.props.walkthroughStep !== 3) {
                                        return
                                    } else {
                                        this.props.setWalkthroughStep(4)
                                    }
                                }
                                this.props.changeTab('introScreen')
                            }}
                                 className={this.props.active === "introScreen" ? "active-element" : "menu-element"}>

                                {this.props.walkthroughStep === 3 ?
                                <div className={"walkthrough-arrow-cont"}>
                                    <div className={"walkthrough-arrow"}>
                                        <img alt={""} src={SVG_Walkthrough_Arrow}/>
                                    </div>
                                </div>:null}

                                <div className={"icon-row"}>
                                    <img src={this.props.active === "introScreen" ? SVG_Intro_Active : SVG_Intro} alt=""
                                         style={svgStyle}/>
                                </div>
                                <div className={"text-row"}>
                                    <FormattedMessage id={"menu.edit_invite.intro"}/>
                                </div>
                            </div>
                            : null}

                        {this.props.showIntroAnimation ?
                            <div onClick={() => {
                                if (this.props.showWalkthrough) {
                                    if(this.props.walkthroughStep !== 5) {
                                        return
                                    } else {
                                        this.props.setWalkthroughStep(6)
                                    }
                                }
                                this.props.changeTab('introAnimation')
                            }}
                                 className={this.props.active === "introAnimation" ? "active-element" : "menu-element"}>

                                {this.props.walkthroughStep === 5 ?
                                    <div className={"walkthrough-arrow-cont"}>
                                        <div className={"walkthrough-arrow"}>
                                            <img alt={""} src={SVG_Walkthrough_Arrow}/>
                                        </div>
                                    </div>:null}

                                <div className={"icon-row"}>
                                    <img
                                        src={this.props.active === "introAnimation" ? SVG_Animation_Active : SVG_Animation}
                                        alt=""
                                        style={svgStyle}/>
                                </div>
                                <div className={"text-row"}>
                                    <FormattedMessage id={"menu.edit_invite.animation"}/>
                                </div>
                            </div>
                            : null}

                        <div onClick={() => {
                            //Disable in walkthrough
                            if (this.props.showWalkthrough) {
                                if(this.props.walkthroughStep !== 7) {
                                    return
                                } else {
                                    this.props.setWalkthroughStep(8)
                                }
                            }
                            this.props.changeTab('options')
                        }}
                             className={this.props.active === "options" ? "active-element" : "menu-element"}>

                            {this.props.walkthroughStep === 7 ?
                                <div className={"walkthrough-arrow-cont"}>
                                    <div className={"walkthrough-arrow"}>
                                        <img alt={""} src={SVG_Walkthrough_Arrow}/>
                                    </div>
                                </div>:null}

                            <div className={"icon-row"}>
                                <img
                                    src={this.props.active === "options" ? SVG_Settings_Active : SVG_Settings}
                                    alt=""
                                    style={svgStyle}/>
                            </div>
                            <div className={"text-row"}>
                                <FormattedMessage id={"menu.edit_invite.options"}/>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default InviteNavBar;
