import {css} from "@emotion/react";
import BeatLoader from "react-spinners/BeatLoader";
import React from "react";
import './Loader.scss'

export default function Loader() {

    return (
        <div className={"loader-cont"}>
        <BeatLoader color={"#06aff2"} loading={true} css={css} size={30} />
        </div>
    )
}