import React, {useEffect} from 'react'
import './style.scss'
import {Button, Icon} from 'rsuite'
import SVG_Walkthrough_Arrow from "../../../../../../assets/icons/walkthrough_arrow.svg";


function InviteHeader(
    {
        title = '',
        goBack = undefined,
        save = false,
        saveLoading = false,
        preview = false
    }) {

    useEffect(() => {

        // eslint-disable-next-line
    }, []);

    return (<div className="InviteHeader-container">
        <div className="InviteHeader-contents">
            <div className="with-back">

                <div className={"left-col"}>
                    {goBack !== undefined &&
                        <Icon onClick={goBack} icon="angle-left"
                              style={{marginLeft: "50px", cursor: "pointer", fontSize: "35px"}}/>}
                </div>



                {save ?
                    <>
                    <div className={"title-col"}>
                        <h4>{title}</h4>
                    </div>
                    <div className={"right-col"}>
                        {preview ?
                            <Button
                                onClick={() => {
                                    preview(true)
                                }}
                                color="green"
                                className={"btn-preview"}
                                disabled={saveLoading}
                                loading={saveLoading}
                            >Previzualizare</Button> : null}

                        <Button
                            onClick={() => {
                                save()
                            }}
                            color="green"
                            className={"btn-save"}
                            disabled={saveLoading}
                            loading={saveLoading}
                        >
                            <div className={"icon-cont"}>
                                <img src={"/images/icons/save.svg"} alt={""}/></div>
                            Salvează</Button>
                    </div>
                    </>
                    : null}
            </div>
        </div>
    </div>)

}

export default InviteHeader;
