import React from 'react'
import './WebsiteInviteModule.scss'
import {Uploader, Icon, Dropdown, Input, Button, Message} from 'rsuite'
import Modal from "react-responsive-modal"

import axios from 'axios'
import {toast} from 'react-toastify'
import ParintiComponent from './AddInvite/ParintiComponent'
import NasiComponent from './AddInvite/NasiComponent'
import LoadingModal from './LoadingModal/LoadingModal'

import {CirclePicker} from 'react-color'
import CustomInput from '../components/custom/CustomInput'
import {
    ConfirmButton,
    getUrlFromImage,
    getBase64FromImage,
    PlayVideo,
    textarea_max_length,
    EditPicture2,
    resizeBlob, BackAndConfirmButtons
} from '../components/Helper'
import CustomQuestions from './CustomQuestions/CustomQuestions'

import MicRecorder from 'mic-recorder-to-mp3';
import Loader from "../components/Loader";
import InvitesModal from "../secondaryPages/AdminEventPage/Modals/invitesModal";
import InviteEditComp from "./Invite/InviteEditComp";
import {inviteTemplates} from "../../components/inviteTemplates/inviteTemplates";
import moment from "moment-timezone";
import {
    getAudioLink,
    getDefaultWeddingSendInviteMessage,
    translateInviteTexts
} from "../../functions";
import {
    getDefaultTemplateValues,
    getTemplateConfigValues, resetTemplateIntroScreenStyles,
    resetTemplateStyles
} from "../../components/InviteTemplatesFunctions";
import BackgroundStyleComp
    from "../../components/inviteTemplates/components/CustomTemplated/BackgroundStyleComp/BackgroundStyleComp";
import {injectIntl} from 'react-intl';
import IntroAnimationConfigComp
    from "../../components/inviteTemplates/introAnimations/IntroAnimationConfigComp/IntroAnimationConfigComp";
import AddAudio from "./EditInvite/components/AddAudio";
import IntroScreenEditComp from "../../components/inviteTemplates/IntroScreen/IntroScreenEditComp";
import {corporateWeddingEvents} from "../../const";
import InviteNavBar from "./EditInvite/components/layout/InviteNavBar/InviteNavBar";
import InviteHeader from "./EditInvite/components/layout/InviteHeader/InviteHeader";

const Mp3Recorder = new MicRecorder({bitRate: 128});


class WebsiteInviteModule extends React.Component {

    constructor(props) {
        super(props)
        this.scrollContainer = React.createRef()
    }

    queryParams = new URLSearchParams(this.props.location.search);


    state = {
        inviteType: "",

        inviteId: -1,
        mamaMireasa: "",
        tataMireasa: "",
        numeParintiMireasa: "",
        numeParintiMire: "",
        mamaMire: "",
        tataMire: "",

        parinti: [],
        numeNas: "",
        numeNasa: "",
        numeFamilie: "",

        timerStarted: false,
        timer: 0,
        showSendModal: false,
        showChangeModalToCustom: false,
        showChangeModalToCustomNew: false,
        showChangeModalToPersonal: false,
        showChangeModalToVideo: false,
        showChangeForm: false,
        newTemplateId: 0,

        image1Url: false,
        image2Url: false,
        image3Url: false,
        coverImageUrl: false,

        newImage1: null,
        newImage2: null,
        newImage3: null,
        newCoverImage: null,

        nasi: [],

        city: "",
        description: "",
        fontSize: "20px",
        fontColor: "#000",
        fontFamily: "Open Sans",
        fontsOptions: ["Open Sans", "Cursive", "Monospace", "Revert", "Fantasy", "Poppins"],
        fotoID: "",
        linkVideo: "",
        poza_invite: null,
        poza_invite_new: null,

        isChangingColor: false,

        isUsingAudio: false,
        audioType: null,
        isRecording: false,
        blobURL: '',
        serverAudioUrl: null,
        deleteServerAudio: false,
        audioBlobData: null,
        isBlocked: false,

        loading: false,
        loadingData: true,
        savingData: false,

        backgroundImageLink: null,
        deleteBackgroundImageLink: false,
        initialWeddingData: {},
        eventType: "",
        eventId: "",
        editSendInviteMessage: "",
        sendInviteMessage: "",

        base64ImageLink: "",

        showModalChangeType: false,
        activeTab: 'invitation',

        questions: [],
        isUsingNotifications: false,
        isUsingCustomQuestions: false,
        questionTypes: [{name: "Raspuns liber", type: 0}, {name: "Grila", type: 1}],
        canModifyQuestions: true,
        isPublished: false,
        tutorialModalPhoto: false,
        tutorialModalVideo: false,
        tutorialModalCustom: false,
        tutorialModalTemplate: false,
        tutorialModalPreview: false,
        tutorialModalSendInvite: false,
        tutorialModalSendInviteNotice: false,
        hideDontKnowButton: false,
        hideChildrenQuestion: false,
        hideConfirmForm: false,
        hideEmailInput: false,
        showAllOptions: false,


        useNewInvite: true,
        templateDetails: {},

        sortTemplates: 'top',
        showDemoModal: false,

        defaultTemplateValues: {},
        templateSelected: false,
        finalLoading: false,
        language: 'ro',
        templateConfigValues: null,

        showWalkthrough: false,
        walkthroughStep: 1
    }


    handleSelectLocationType = (value) => {
        this.setState({
            locationType: value
        })
    }

    async componentDidMount() {

        [window, document].forEach(
            el => el.addEventListener('message', this.onAppMessage))
        //window.addEventListener("message", this.onAppMessage)

        document.body.classList.add('body-scroll')

        this.getInviteData()
    }

    componentWillUnmount() {
        document.body.classList.remove('body-scroll')
    }

    async getInviteData() {


        let weddingData = {
            data: {
                locatii: this.getLocatii(this.queryParams.get('eventType')),
                eventType: this.queryParams.get('eventType') ?
                    this.queryParams.get('eventType') === 'Nunta' ? 'Nunta'
                        : this.queryParams.get('eventType') === 'Cununie' ? 'Cununie'
                            : this.queryParams.get('eventType') === 'NuntaBotez' ? 'Nunta'
                                : this.queryParams.get('eventType') === 'Botez' ? 'Botez'
                                    : this.queryParams.get('eventType') === 'Mot' ? 'Mot'
                                        : this.queryParams.get('eventType') === 'Turta' ? 'Turta'
                                            : this.queryParams.get('eventType') === 'Petrecere' ? 'Petrecere'
                                                : corporateWeddingEvents.includes(this.queryParams.get('eventType')) ? this.queryParams.get('eventType') : 'Nunta' : 'Nunta',
                eventId: 0,
                questions: [],
                notifications: true,
                useCustomQuestions: false,
                dataEvent: moment().add('1', 'year').toISOString(),

            }
        }

        /// Create wedding event
        let customQuestions = [
            {
                type: 1,
                title: "Ce meniu doriti?",
                options: ["Normal", "Vegetarian", "Vegan"]
            },
            {
                type: 1,
                title: "Ce meniu doreste insotitorul?(Daca veniti insotit)",
                options: ["Normal", "Vegetarian", "Vegan"]
            },
            {
                type: 1,
                title: "Aveti nevoie de cazare?",
                options: ["Da", "Nu"]
            },
            {
                type: 0,
                title: "Doriti cu cineva anume la masa?",
                options: []
            },
            {
                type: 0,
                title: "Nu doriti cu cineva anume la masa?",
                options: []
            },
        ]
        let obj = {
            user: localStorage.getItem('id'),
            eventType: weddingData.data.eventType,
            prenumeMireasa: "Mireasă",
            prenumeMire: (weddingData.data.eventType === 'Turta' || weddingData.data.eventType === 'Botez' || weddingData.data.eventType === 'Mot') ? 'Copil' :

                (weddingData.data.eventType === 'Petrecere') ?
                    'Aniversarea mea'
                    :
                    corporateWeddingEvents.includes(weddingData.data.eventType) ? 'Denumire eveniment business'
                        :

                    'Mire',
            prenumeOrganizator: '',
            prenumeMama: 'Mama',
            prenumeTata: 'Tata',
            organizerType: '',
            dataEvent: moment().add('1', 'year').toISOString(),
            eventDescription: '',
            locations: this.getLocatii(weddingData.data.eventType),
            generalInfo: true,
            budget: 0,
            questions: customQuestions,
            isPublished: false,
            imageLink: ''
        }

        let guestWeddingId = localStorage.getItem('guestWeddingId')

        weddingData = await axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getThisWedding/' + guestWeddingId)

        let audioLink = await getAudioLink(weddingData.data.invite);

        if (audioLink && audioLink !== '') {
            this.setState({
                serverAudioUrl: audioLink,
                isUsingAudio: true
            })
        }

        if (guestWeddingId && weddingData.data && weddingData.data.id_eveniment && this.queryParams.get('eventType') === weddingData.data.eventType) {
            let current_invite = weddingData.data.invite

            if (current_invite !== null) {
                let {
                    nasi,
                    description,
                    parinti,
                    linkVideo
                } = current_invite


                //Get persisted active tab
                let persistedEditInviteWedding = localStorage.getItem('edit_invite')
                let persistedEditInviteActiveTab = localStorage.getItem('edit_invite_tab')
                let defaultActiveTab = 'invitation'

                if(persistedEditInviteWedding && persistedEditInviteActiveTab && persistedEditInviteWedding === weddingData.data.id_eveniment) {
                    defaultActiveTab = persistedEditInviteActiveTab
                }
                localStorage.removeItem('edit_invite')
                localStorage.removeItem('edit_invite_tab')

                this.setState({
                    activeTab: defaultActiveTab,
                    inviteType: current_invite.inviteType,
                    inviteId: current_invite.id,
                    nasi,
                    description,
                    mamaMireasa: parinti[2].mamaMireasa,
                    tataMireasa: parinti[3].tataMireasa,
                    mamaMire: parinti[0].mamaMire,
                    tataMire: parinti[1].tataMire,
                    numeParintiMire: parinti[4].numeParintiMire,
                    numeParintiMireasa: parinti[5].numeParintiMireasa,
                    linkVideo,
                    audioType: current_invite.audioType ? (current_invite.audioType === "useAudio" ? 'link' : current_invite.audioType) : 'song',
                    isUsingAudio: current_invite.audioType !== "none",
                    songSelected: current_invite.song,
                    image1Url: current_invite.image1 !== null && current_invite.image1 !== undefined ? current_invite.image1.url : null,
                    image2Url: current_invite.image2 !== null && current_invite.image2 !== undefined ? current_invite.image2.url : null,
                    image3Url: current_invite.image3 !== null && current_invite.image3 !== undefined ? current_invite.image3.url : null,
                    coverImageUrl: current_invite.cover_image !== null && current_invite.cover_image !== undefined ? current_invite.cover_image.url : null,
                    backgroundImageLink: current_invite.image !== null ? `https://${process.env.REACT_APP_IMAGE_DOMAIN}${getUrlFromImage(current_invite.image)}` : null,
                    fontColor: current_invite.fontColor,
                    fontSize: current_invite.fontSize,
                    fontFamily: current_invite.fontFamily,
                    isPublished: current_invite.isPublished ? true : false,
                    current_event: weddingData.data,
                    templateDetails: weddingData.data.invite && weddingData.data.invite.templateDetails ? weddingData.data.invite.templateDetails : getDefaultTemplateValues(weddingData),
                    useNewInvite: current_invite.templateDetails && current_invite.templateDetails.id ? true : false,
                    sendInviteMessage: current_invite.sendInviteMessage && current_invite.sendInviteMessage.length > 0 ? current_invite.sendInviteMessage : getDefaultWeddingSendInviteMessage(weddingData.data),
                    hideChildrenQuestion: current_invite.hideChildrenQuestion === true,
                    hideDontKnowButton: current_invite.hideDontKnowButton === true,
                    hideConfirmForm: current_invite.hideConfirmForm === true,
                    hideEmailInput: current_invite.hideEmailInput === true,
                    templateConfigValues: current_invite.templateDetails ? getTemplateConfigValues(current_invite.templateDetails.id) : {}
                })
            } else {
                this.setState({
                    sendInviteMessage: getDefaultWeddingSendInviteMessage(weddingData.data),
                    showWalkthrough: !this.skipEditInviteWalkthrough() && (!weddingData.data.tutorials || (weddingData.data.tutorials && !weddingData.data.tutorials.editInviteWalkthrough))
                })

            }

            if (!weddingData.data.invite) {
                weddingData.data.invite = {language: 'ro'}
            }

            this.setState({
                initialWeddingData: weddingData.data,
                locations: weddingData.data.locatii,
                current_event: weddingData.data,
                eventType: weddingData.data.eventType,
                eventId: weddingData.data.id,
                questions: weddingData.data.questions !== null ? weddingData.data.questions : [],
                isUsingNotifications: weddingData.data.notifications,
                isUsingCustomQuestions: weddingData.data.useCustomQuestions,
                defaultTemplateValues: getDefaultTemplateValues(weddingData)
            }, () => {
                let initTemplateId = parseInt(this.queryParams.get('templateId') + '')
                if (initTemplateId) {


                    if (initTemplateId >= 1000 && initTemplateId <= 1003) {
                        if (this.state.inviteId === -1 && this.state.templateSelected === false) {
                            this.changeTemplateValue('id', initTemplateId, true)

                            this.setState({
                                inviteType: 'CustomTemplated',
                                useNewInvite: false,
                                showChangeModalToCustomNew: false,
                                showChangeForm: false,
                                templateSelected: true
                            }, () => {
                                this.changeTemplateValue('id', initTemplateId)
                                //this.scrollContainerTop()
                            })
                        } else {

                            if (this.state.useNewInvite === false && this.state.inviteType === 'CustomTemplated') {
                                this.setState({
                                    inviteType: 'CustomTemplated',
                                    useNewInvite: false,
                                    showChangeModalToCustomNew: false,
                                    showChangeForm: false,
                                    templateSelected: true
                                }, () => {
                                    this.changeTemplateValue('id', initTemplateId, true)
                                    //this.scrollContainerTop()
                                })

                                this.setState({
                                    showChangeForm: false
                                }, () => {
                                    //this.scrollContainerTop()
                                })

                            } else {
                                this.setState({
                                    showChangeModalToCustomNew: true,
                                    newTemplateId: initTemplateId
                                })
                            }
                        }
                    } else {


                        if (this.state.inviteId === -1 && this.state.templateSelected === false) {

                            this.changeTemplateValue('id', initTemplateId)

                            this.setState({
                                inviteType: 'Custom',
                                useNewInvite: true,
                                showChangeModalToCustomNew: false,
                                showChangeForm: false,
                                templateSelected: true,
                                //defaultTemplateValues: getDefaultTemplateValues(weddingData)
                            }, () => {
                                //this.scrollContainerTop()
                                this.changeTemplateValue('id', initTemplateId, true)
                            })
                        } else {

                            if (this.state.useNewInvite === true && this.state.inviteType === 'Custom') {
                                this.changeTemplateValue('id', initTemplateId, true)
                                this.setState({
                                    showChangeForm: false
                                }, () => {
                                    //this.scrollContainerTop()
                                })

                            } else {
                                this.setState({
                                    inviteType: 'Custom',
                                    useNewInvite: true,
                                    showChangeModalToCustomNew: false,
                                    showChangeForm: false,
                                    templateSelected: true
                                }, () => {
                                    this.changeTemplateValue('id', initTemplateId, true)
                                    //this.scrollContainerTop()
                                })
                                this.changeTemplateValue('id', initTemplateId, true)
                                this.setState({
                                    showChangeModalToCustomNew: true,
                                    newTemplateId: initTemplateId
                                })
                            }
                        }
                    }
                }

                let initPersonalInvite = this.queryParams.get('personalInvite')
                if (initPersonalInvite) {
                    if (this.state.inviteId === -1) {

                        this.setState({
                            inviteType: 'Personal',
                            useNewInvite: false,
                            showChangeModalToPersonal: false,
                            showChangeForm: false,
                            templateDetails: null,
                            templateSelected: true
                        }, () => {
                            //this.scrollContainerTop()
                        })
                    } else {

                        if (this.state.inviteType === 'Personal') {
                            this.setState({
                                showChangeForm: false
                            }, () => {
                                //this.scrollContainerTop()
                            })

                        } else {
                            this.setState({
                                showChangeModalToPersonal: true
                            })
                        }
                    }
                }

                let initVideoInvite = this.queryParams.get('videoInvite')
                if (initVideoInvite) {
                    if (this.state.inviteId === -1) {
                        this.setState({
                            inviteType: 'Video',
                            useNewInvite: false,
                            showChangeModalToVideo: false,
                            showChangeForm: false,
                            templateDetails: null,
                            templateSelected: true
                        }, () => {
                            //this.scrollContainerTop()
                        })
                    } else {
                        if (this.state.inviteType === 'Video') {
                            this.setState({
                                showChangeForm: false
                            }, () => {
                                //this.scrollContainerTop()
                            })

                        } else {
                            this.setState({
                                showChangeModalToVideo: true
                            })
                        }
                    }
                }
            })

            this.setState({
                showChangeForm: this.queryParams.get('selectTemplate') ? true : false,
                loadingData: false
            })
        } else {
            await axios.post(process.env.REACT_APP_DOMAIN + 'weddings/guest/create', obj)//formData, auth)
                .then(async response => {

                    weddingData = response
                    localStorage.setItem('guestWeddingId', weddingData.data.id_eveniment)
                    localStorage.setItem('guestWeddingType', weddingData.data.eventType)
                    let initTemplateId = parseInt(this.queryParams.get('templateId') + '')

                    if (!weddingData.data.invite) {
                        weddingData.data.invite = {language: 'ro'}
                    }

                    let templateDetails = getDefaultTemplateValues(weddingData, initTemplateId)
                    if (this.queryParams.get('eventType') === 'NuntaBotez') {
                        templateDetails.section1.nuntaSiBotez = true
                        templateDetails.section1.childrenName = 'Copil'
                        templateDetails.section1.text2 = translateInviteTexts('Vă invităm să fiți alături de noi la celebrarea căsătoriei noastre și a botezului micuței', 'ro')
                    }

                    this.setState({
                        initialWeddingData: weddingData.data,
                        locations: weddingData.data.locatii,
                        current_event: weddingData.data,
                        eventType: weddingData.data.eventType,
                        eventId: weddingData.data.id,
                        questions: weddingData.data.questions !== null ? weddingData.data.questions : [],
                        isUsingNotifications: weddingData.data.notifications,
                        isUsingCustomQuestions: weddingData.data.useCustomQuestions,
                        defaultTemplateValues: templateDetails,
                        sendInviteMessage: getDefaultWeddingSendInviteMessage(weddingData.data),
                        showWalkthrough: !this.skipEditInviteWalkthrough() && (!weddingData.data.tutorials || (weddingData.data.tutorials && !weddingData.data.tutorials.editInviteWalkthrough))

                    }, () => {

                        if (initTemplateId) {
                            if (initTemplateId >= 1000 && initTemplateId <= 1002) {
                                if (this.state.inviteId === -1 && this.state.templateSelected === false) {
                                    this.changeTemplateValue('id', initTemplateId)

                                    this.setState({
                                        inviteType: 'CustomTemplated',
                                        useNewInvite: false,
                                        showChangeModalToCustomNew: false,
                                        showChangeForm: false,
                                        templateSelected: true
                                    }, () => {
                                        //this.scrollContainerTop()
                                    })
                                } else {

                                    if (this.state.useNewInvite === false && this.state.inviteType === 'CustomTemplated') {
                                        this.changeTemplateValue('id', initTemplateId)
                                        this.setState({
                                            showChangeForm: false
                                        }, () => {
                                            //this.scrollContainerTop()
                                        })

                                    } else {
                                        this.setState({
                                            showChangeModalToCustomNew: true,
                                            newTemplateId: initTemplateId
                                        })
                                    }
                                }
                            } else {
                                if (this.state.inviteId === -1 && this.state.templateSelected === false) {
                                    this.changeTemplateValue('id', initTemplateId)

                                    this.setState({
                                        inviteType: 'Custom',
                                        useNewInvite: true,
                                        showChangeModalToCustomNew: false,
                                        showChangeForm: false,
                                        templateSelected: true
                                    }, () => {
                                        //this.scrollContainerTop()
                                    })
                                } else {

                                    if (this.state.useNewInvite === true && this.state.inviteType === 'Custom') {
                                        this.changeTemplateValue('id', initTemplateId)
                                        this.setState({
                                            showChangeForm: false
                                        }, () => {
                                            //this.scrollContainerTop()
                                        })

                                    } else {
                                        this.setState({
                                            showChangeModalToCustomNew: true,
                                            newTemplateId: initTemplateId
                                        })
                                    }
                                }
                            }


                        }

                        let initCustomInvite = this.queryParams.get('customInvite')
                        if (initCustomInvite) {
                            if (this.state.inviteId === -1 && this.state.templateSelected === false) {
                                this.setState({
                                    inviteType: 'Custom',
                                    useNewInvite: false,
                                    showChangeModalToCustom: false,
                                    showChangeForm: false,
                                    templateDetails: null,
                                    templateSelected: true
                                }, () => {
                                    //this.scrollContainerTop()
                                })
                            } else {
                                if (this.state.useNewInvite === false && this.state.inviteType === 'Custom') {
                                    this.setState({
                                        showChangeForm: false
                                    }, () => {
                                        //this.scrollContainerTop()
                                    })

                                } else {
                                    this.setState({
                                        showChangeModalToCustom: true
                                    })
                                }
                            }
                        }

                        let initPersonalInvite = this.queryParams.get('personalInvite')
                        if (initPersonalInvite) {
                            if (this.state.inviteId === -1 && this.state.templateSelected === false) {
                                this.setState({
                                    inviteType: 'Personal',
                                    useNewInvite: false,
                                    showChangeModalToPersonal: false,
                                    showChangeForm: false,
                                    templateDetails: null,
                                    templateSelected: true
                                }, () => {
                                    //this.scrollContainerTop()
                                })
                            } else {

                                if (this.state.inviteType === 'Personal') {
                                    this.setState({
                                        showChangeForm: false
                                    }, () => {
                                        //this.scrollContainerTop()
                                    })

                                } else {
                                    this.setState({
                                        showChangeModalToPersonal: true
                                    })
                                }
                            }
                        }

                        let initVideoInvite = this.queryParams.get('videoInvite')
                        if (initVideoInvite) {
                            if (this.state.inviteId === -1 && this.state.templateSelected === false) {
                                this.setState({
                                    inviteType: 'Video',
                                    useNewInvite: false,
                                    showChangeModalToVideo: false,
                                    showChangeForm: false,
                                    templateDetails: null,
                                    templateSelected: true
                                }, () => {
                                    //this.scrollContainerTop()
                                })
                            } else {
                                if (this.state.inviteType === 'Video') {
                                    this.setState({
                                        showChangeForm: false
                                    }, () => {
                                        //this.scrollContainerTop()
                                    })

                                } else {
                                    this.setState({
                                        showChangeModalToVideo: true
                                    })
                                }
                            }
                        }

                    })


                    this.setState({
                        loadingData: false,
                        showChangeForm: this.queryParams.get('selectTemplate') ? true : false,
                    })
                })
        }


    }

    skipEditInviteWalkthrough() {
        let value = localStorage.getItem('edit_invite_walkthrough')

        if(value && value === '1') {
            return true
        } else {
            return false
        }

    }

    getLocatii = (eventType) => {
        if (eventType === 'Nunta' || eventType === 'NuntaBotez') {
            return [
                {
                    "day": moment().add('1', 'year').toISOString(),
                    "name": "Locatie petrecere",
                    "pinx": 44.451627,
                    "piny": 26.085265,
                    "time": moment().add('1', 'year').add('1', 'hour').toISOString(),
                    "address": "Creative Ality, Romania"
                }
            ]
        } else {
            return [
                {
                    "day": moment().add('1', 'year').toISOString(),
                    "name": "Locatie petrecere",
                    "pinx": 44.451627,
                    "piny": 26.085265,
                    "time": moment().add('1', 'year').add('1', 'hour').toISOString(),
                    "address": "Creative Ality, Romania"
                }
            ]
        }


    }


    start = () => {
        if (this.state.isBlocked) {
            toast.info("Microfonul este blocat, acordati permisiune pentru a-l folosii.")
        } else {
            Mp3Recorder
                .start()
                .then(() => {
                    this.setState({
                        blobURL: '',
                        isRecording: true,
                        audioBlobData: null,
                        timer: 0,
                        timerStarted: true
                    });
                }).catch((e) => {
                console.error(e);
                toast.warn("Microfonul nu poate fi detectat")
            });
        }
    };

    stop = () => {
        Mp3Recorder
            .stop()
            .getMp3()
            .then(([buffer, blob]) => {
                const blobURL = URL.createObjectURL(blob)
                this.setState({
                    blobURL,
                    isRecording: false,
                    audioBlobData: blob,
                    timer: 0,
                    timerStarted: false
                });
            }).catch((e) => console.log(e));
    };

    removeNasi = (id) => {
        let current = [...this.state.nasi];

        for (var i = 0; i < current.length; i++) {
            if (current[i].id === id) {
                current.splice(i, 1);
            }
        }

        this.setState({
            nasi: current
        })
    }

    makeid(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;

        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    addNasi = () => {
        let {numeNasa, numeNas, numeFamilie} = this.state;
        let current = this.state.nasi;

        current.push({
            id: this.makeid(3),
            numeNasa,
            numeNas,
            numeFamilie
        })

        this.setState({
            numeNas: "",
            numeNasa: "",
            numeFamilie: "",
            nasi: current
        })
    }

    handleChange = (value, key) => {
        this.setState({
            [key]: value
        })
    }

    changeUseQuestionsState = async (state) => {


        if (this.state.canModifyQuestions === false) {
            toast.error("Aveti raspunsuri, modificarile nu mai sunt permise")
            return
        }

        this.setState({
            isUsingCustomQuestions: state
        })
    }

    changeQuestions = (newQuestions) => {
        this.setState({
            questions: newQuestions
        })
    }

    changeInviteDescription = (value) => {
        if (value.length > textarea_max_length - 1) {
            this.setState({
                description: value.substring(0, textarea_max_length)
            })
            return
        }

        this.setState({
            description: value
        })
    }

    saveNotificationStatus = () => {
        let obj = {
            notifications: this.state.isUsingNotifications
        }

        let auth = {
            headers: {}
        }

        axios.put(process.env.REACT_APP_DOMAIN + 'weddings/' + this.state.eventId, obj, auth)
            .then(_ => {
            })
            .catch(err => {
                console.log(err);
            })
    }

    triggerCustomQuestionsAlert = () => {
        toast.error("S-au primit raspunsuri la invitatie, modificarile la intrebari nu vor fi salvate")
    }

    saveCustomQuestions = () => {
        if (!this.state.canModifyQuestions) {
            return
        }

        let obj = {
            questions: this.state.questions,
            useCustomQuestions: this.state.isUsingCustomQuestions
        }

        let auth = {
            headers: {}
        }

        axios.put(process.env.REACT_APP_DOMAIN + 'weddings/' + this.state.eventId, obj, auth)
            .then(_ => {
            })
            .catch(err => {
                console.log(err);
            })
    }

    openSendModal = async () => {
        this.setState({
            tutorialModalSendInvite: false,
            tutorialModalSendInviteNotice: false
        })
        if (window.flutter_inappwebview) {
            let msg = this.state.current_event.prenumeMireasa + " & " + this.state.current_event.prenumeMire + ", va invita la nunta lor!"
            if (this.state.current_event.eventType === "Botez") {
                if (this.state.current_event.id_eveniment === '43zDB')
                    msg = `Va invit la botezul nostru, ${this.state.current_event.prenumeMire}`
                else
                    msg = `Va invit la botezul meu, ${this.state.current_event.prenumeMire}`
            }
            if (this.state.current_event.eventType === "Petrecere") {
                msg = `Sunteți invitați la urmatoarea petrecere: ${this.state.current_event.prenumeMire}`
            }
            window.flutter_inappwebview.callHandler('shareEvent', `${msg} ${process.env.REACT_APP_PAGE}invitation/${this.state.current_event.id_eveniment}`)
            return
        }

        if (window.alityAppView) {
            let msg = this.state.current_event.prenumeMireasa + " & " + this.state.current_event.prenumeMire + ", va invita la nunta lor!"
            if (this.state.current_event.eventType === "Botez") {
                if (this.state.current_event.id_eveniment === '43zDB')
                    msg = `Va invit la botezul nostru, ${this.state.current_event.prenumeMire}`
                else
                    msg = `Va invit la botezul meu, ${this.state.current_event.prenumeMire}`
            }
            if (this.state.current_event.eventType === "Petrecere") {
                msg = `Sunteți invitați la urmatoarea petrecere: ${this.state.current_event.prenumeMire}`
            }
            window.ReactNativeWebView.postMessage(JSON.stringify({
                action: 'shareMessage',
                message: `${msg} ${process.env.REACT_APP_PAGE}invitation/${this.state.current_event.id_eveniment}`,
            }))
            return
        }

        this.setState({
            showSendModal: true
        })
    }

    showBottomNav = () => {
        return ((this.state.inviteType === "Custom" && this.state.useNewInvite) || this.state.inviteType === 'CustomTemplated')
    }

    saveInviteData = async (redirectToPreview = false, openSendInvite = false) => {

        let obj = {
            inviteType: this.state.inviteType,
            parinti: [{mamaMire: this.state.mamaMire}, {tataMire: this.state.tataMire}, {mamaMireasa: this.state.mamaMireasa}, {tataMireasa: this.state.tataMireasa}, {numeParintiMire: this.state.numeParintiMire}, {numeParintiMireasa: this.state.numeParintiMireasa}],
            nasi: this.state.nasi,
            description: this.state.description,
            locatii: this.state.locations,
            linkVideo: this.state.linkVideo,
            fontColor: this.state.fontColor,
            fontSize: this.state.fontSize,
            fontFamily: this.state.fontFamily,
            imageLink: this.state.base64ImageLink,
            //isPublished: false,
            templateDetails: this.state.templateDetails,
            sendInviteMessage: this.state.sendInviteMessage,
            hideChildrenQuestion: this.state.hideChildrenQuestion,
            hideDontKnowButton: this.state.hideDontKnowButton,
            hideConfirmForm: this.state.hideConfirmForm,
            hideEmailInput: this.state.hideEmailInput

        }

        if (this.state.newImage1 !== null) {
            let image1Link = await getBase64FromImage(this.state.newImage1)
            obj["image1Link"] = image1Link
        }

        if (this.state.newImage2 !== null) {
            let image2Link = await getBase64FromImage(this.state.newImage2)
            obj["image2Link"] = image2Link
        }

        if (this.state.newImage3 !== null) {
            let image3Link = await getBase64FromImage(this.state.newImage3)
            obj["image3Link"] = image3Link
        }

        if (this.state.newCoverImage !== null) {
            let coverImageLink = await getBase64FromImage(this.state.newCoverImage)
            obj["coverImageLink"] = coverImageLink
        }


        if (this.state.inviteId !== -1) {

            let formData = new FormData()
            if (this.state.isUsingAudio && this.state.audioType === 'song' && this.state.songSelected !== null) {
                obj['song'] = this.state.songSelected

                obj['audioType'] = this.state.audioType ? this.state.audioType : "none";
            }

            if (this.state.isUsingAudio && this.state.audioType === 'message' && this.state.audioBlobData !== null) {
                formData.append("files.audio", this.state.audioBlobData)
                obj['audioType'] = this.state.audioType ? this.state.audioType : "none";
            }
            if (this.state.isUsingAudio && this.state.audioType === 'link' && this.state.customAudioLink.length > 0) {
                obj['audioType'] = this.state.audioType ? this.state.audioType : "none";
            }
            if (!this.state.isUsingAudio){
                obj['audioType'] =  "none";
            }

            formData.append("data", JSON.stringify(obj))

            if (this.state.poza_invite_new !== null) {
                formData.append("files.image", this.state.poza_invite_new)
            } else {
                if (this.state.deleteBackgroundImageLink) {
                    obj["image"] = null
                }
            }

            if (this.state.newImage1 !== null) {
                formData.append("files.image1", this.state.newImage1)
            }

            if (this.state.newImage2 !== null) {
                formData.append("files.image2", this.state.newImage2)
            }

            if (this.state.newImage3 !== null) {
                formData.append("files.image3", this.state.newImage3)
            }

            if (this.state.newCoverImage !== null) {
                formData.append("files.cover_image", this.state.newCoverImage)
            }


            let auth = {
                headers: {
                    "Content-Type": `multipart/form-data;`
                }
            }

            await axios.put(process.env.REACT_APP_DOMAIN + 'invites/' + this.state.inviteId, formData, auth)//formData, auth)
                .then(_ => {

                    if (redirectToPreview) {
                        this.props.history.push('/organizer/websiteInviteModulePreview/' + this.state.initialWeddingData.id_eveniment)
                    } else {
                        this.props.history.push('/login')
                    }
                })
                .catch(err => {
                    toast.error("Eroare")
                    this.setState({
                        finalLoading: false
                    })
                })

        } else {

            obj.event = this.state.initialWeddingData.id
            obj.user = localStorage.getItem('id')

            let formData = new FormData()
            if (this.state.isUsingAudio && this.state.audioType === 'song' && this.state.songSelected !== null) {
                obj['song'] = this.state.songSelected

                obj['audioType'] =  this.state.audioType ? this.state.audioType : "none";
            }

            if (this.state.isUsingAudio && this.state.audioType === 'message' && this.state.audioBlobData !== null) {
                formData.append("files.audio", this.state.audioBlobData)
                obj['audioType'] =  this.state.audioType ? this.state.audioType : "none";
            }
            if (this.state.isUsingAudio && this.state.audioType === 'link' && this.state.customAudioLink.length > 0) {
                obj['audioType'] =  this.state.audioType ? this.state.audioType : "none";
            }
            if (!this.state.isUsingAudio){
                obj['audioType'] =  "none";
            }
            formData.append("data", JSON.stringify(obj))

            if (this.state.poza_invite_new !== null) {
                formData.append("files.image", this.state.poza_invite_new)
            }

            if (this.state.newImage1 !== null) {
                formData.append("files.image1", this.state.newImage1)
            }

            if (this.state.newImage2 !== null) {
                formData.append("files.image2", this.state.newImage2)
            }

            if (this.state.newImage3 !== null) {
                formData.append("files.image3", this.state.newImage3)
            }
            if (this.state.newCoverImage !== null) {
                formData.append("files.cover_image", this.state.newCoverImage)
            }


            let auth = {
                headers: {
                    "Content-Type": `multipart/form-data;`
                }
            }

            await axios.post(process.env.REACT_APP_DOMAIN + 'invites', formData, auth)//formData, auth)
                .then(async response => {

                    let obj2 = {
                        invitatieInfo: true
                    }

                    let auth2 = {
                        headers: {}
                    }

                    await axios.put(process.env.REACT_APP_DOMAIN + 'weddings/' + this.state.initialWeddingData.id, obj2, auth2)


                    if (redirectToPreview) {
                        this.props.history.push('/organizer/websiteInviteModulePreview/' + this.state.initialWeddingData.id_eveniment)
                    } else {
                        this.props.history.push('/login')
                    }

                })
                .catch(err => {
                    toast.error("Eroare")
                    this.setState({
                        finalLoading: false
                    })
                })

        }
    }

    createInvite = async (redirectToPreview = false, openSendInvite = false) => {

        if (this.state.isUsingCustomQuestions) {
            if (!this.state.canModifyQuestions) {
                //toast.info("S-au primit raspunsuri la invitatie, modificarile la intrebari nu vor fi salvate")
            } else {
                let questions = this.state.questions
                //check questions for validity
                for (let i = 0; i < questions.length; i++) {
                    if (questions[i].title === "") {
                        toast.error(`Va rog completati toate titlurile. Titlu intrebare ${i + 1}`)
                        return
                    }

                    if (questions[i].type === 1) {
                        if (questions[i].options.length === 0) {
                            toast.error(`Ati ales intrebare grila dar nu ati oferit optiuni. Intrebare ${i + 1}`)
                            return
                        }

                        for (let j = 0; j < questions[i].options.length; j++) {
                            if (questions[i].options[j] === "") {
                                toast.error(`Va rog completati toate optiunile unde ati ales grila. Intrebare ${i + 1}, grila ${j + 1}`)
                                return
                            }
                        }
                    }
                }

                this.saveCustomQuestions()
            }
        }

        if (this.state.finalLoading) {
            return
        }

        this.setState({
            savingData: true,
            finalLoading: true
        })

        this.saveNotificationStatus()

        await this.saveInviteData(redirectToPreview, openSendInvite)

        this.setState({
            savingData: false
        })
    }

    saveWeddingData = async (weddingData, locations) => {
        let obj = {
            id_eveniment: weddingData.id_eveniment,
            locations: locations,
            prenumeMireasa: weddingData.prenumeMireasa,
            prenumeMire: weddingData.prenumeMire,
            dataEvent: weddingData.dataEvent,
            eventDescription: weddingData.eventDescription,
            isPublished: false
        }

        let newWeddingData = {...this.state.initialWeddingData}
        newWeddingData.prenumeMire = weddingData.prenumeMire
        newWeddingData.prenumeMireasa = weddingData.prenumeMireasa
        newWeddingData.dataEvent = weddingData.dataEvent
        newWeddingData.locations = weddingData.locations

        this.setState({
            initialWeddingData: newWeddingData,
            current_event: newWeddingData
        })

        let auth = {
            headers: {}
        }

        axios.put(process.env.REACT_APP_DOMAIN + 'weddings/edit/' + weddingData.id, obj, auth)//formData, auth)
            .then(response => {
            })
            .catch(err => {
            })
    }

    handlePoza = async (fileList) => {
        if (fileList.length === 0) {
            this.setState({
                poza_invite: null
            })
            return
        }

        if (fileList[0].blobFile.size > 7000000) {
            toast.error("Fisier prea mare, maxim 7MB")
        }

        await resizeBlob(fileList[0].blobFile)

        this.setState({
            poza_invite: fileList[0].blobFile
        })
    }

    saveCroppedImage = async (croppedImg) => {

        let data = await getBase64FromImage(croppedImg)

        this.setState({
            croppedImage: croppedImg,
            base64ImageLink: data,
            poza_invite: null,
            poza_invite_new: croppedImg,
            backgroundImageLink: data
        })
    }

    changeInviteTypeToCustomTemplated = () => {
        this.changeTemplateValue('id',
            (this.state.initialWeddingData.eventType === 'Nunta' || this.state.initialWeddingData.eventType === 'Cununie') ?
                1000
                :
                (this.state.initialWeddingData.eventType === 'Botez' || this.state.initialWeddingData.eventType === 'Mot' || this.state.initialWeddingData.eventType === 'Turta') ?
                    1001
                    :
                    this.state.initialWeddingData.eventType === 'Petrecere' ?
                        1002
                        : corporateWeddingEvents.includes(this.state.initialWeddingData.eventType) ?
                            1003
                            :1001
        )
    }

    openModalChangeType = () => {
        this.setState({
            showModalChangeType: true
        })
    }

    closeModalChangeType = () => {
        this.setState({
            showModalChangeType: false
        })
    }

    updateTutorial = async (tutorialInviteType) => {

        this.setState({
            tutorialModalPhoto: false,
            tutorialModalVideo: false,
            tutorialModalCustom: false,
            tutorialModalTemplate: false
        })

        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        let obj = {}
        obj[tutorialInviteType] = true

        await axios.post(process.env.REACT_APP_DOMAIN + 'weddings/update/tutorials/' + this.state.initialWeddingData.id_eveniment,
            obj,
            auth)

    }

    changeType = () => {
        this.setState({
            inviteType: "",

            mamaMireasa: "",
            tataMireasa: "",
            numeParintiMireasa: "",
            numeParintiMire: "",
            mamaMire: "",
            tataMire: "",

            numeNas: "",
            numeNasa: "",
            numeFamilie: "",

            nasi: [],

            description: "",
            fontSize: "20px",
            fontColor: "#000",
            fontFamily: "Open Sans",
            fontsOptions: ["Open Sans", "Cursive", "Monospace", "Revert", "Fantasy", "Poppins"],
            linkVideo: "",
            poza_invite: null,

            blobURL: '',
            isUsingAudio: false,
            serverAudioUrl: null,
            audioBlobData: null,

            questions: this.state.initialWeddingData.questions,
            isUsingNotifications: this.state.initialWeddingData.notifications,
            isUsingCustomQuestions: this.state.initialWeddingData.useCustomQuestions,

            showModalChangeType: false
        })
    }


    changeTemplateValue = (index, value, doNotChangeActiveTab = false) => {

           let newTemplateDetails = this.state.templateDetails ? {...this.state.templateDetails} : {}
           newTemplateDetails[index] = value

           if (index === 'id') {
               newTemplateDetails = resetTemplateStyles(newTemplateDetails)
               newTemplateDetails = resetTemplateIntroScreenStyles(newTemplateDetails)

               newTemplateDetails.introAnimationId = 0
               newTemplateDetails.introAnimationConfiguration = []
               newTemplateDetails.introScreenId = null
               newTemplateDetails.introScreenConfiguration = []
               this.setState({
                   templateConfigValues: getTemplateConfigValues(value)
               })
           }

           if (!newTemplateDetails.section1) {
               newTemplateDetails = {...newTemplateDetails, ...this.state.defaultTemplateValues}
           }

           if(doNotChangeActiveTab) {
               this.setState({
                   templateDetails: newTemplateDetails
               })
           } else {
               this.setState({
                   activeTab: 'invitation',
                   templateDetails: newTemplateDetails
               })
           }


           window.scrollTo(0, 0)

    }

    scrollContainerTop = () => {
        this.scrollContainer.current.scroll({
            top: 0,
            //behavior: 'smooth'
        })
    }

    sortTemplates = (templates) => {
        if (this.state.sortTemplates === 'top') {
            templates.sort(function (a, b) {
                return (a.usage ? a.usage : 0) - (b.usage ? b.usage : 0)
            })
            return templates
        } else {
            return templates
        }
    }

    goBack = () => {
        this.props.history.push("/organizer/adminEventPage/" + this.state.initialWeddingData.id_eveniment)
    }

    render() {
        if (this.state.loadingData) {
            return <div className="WebsiteInviteModule-container">

                <Loader/>

            </div>
        }

        let customInputStyle = {
            margin: "10px 0px",
            backgroundColor: "#E5F7FE",
            borderRadius: "10px"
        }

        let body = <div></div>

        if (this.state.inviteType === "Personal") {
            body = <div>
                <div className="Upload-zone">
                    {this.state.backgroundImageLink !== null
                        ? <div style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            <p>Invitatie personala</p>
                            <img style={{
                                padding: "5px",
                                width: "300px",
                                height: "300px",
                                alignSelf: "center"
                            }}
                                 src={this.state.backgroundImageLink} alt=""/>
                        </div>
                        : <p>Nicio imagine adaugata</p>
                    }

                    <h3> Schimba invitatia personala </h3>
                    <Uploader onChange={this.handlePoza} accept=".jpg" style={{borderRadius: "5px"}}
                              autoUpload={false}
                              fileListVisible={false}
                              multiple={false} disabled={this.state.poza_invite !== null}>
                        <div style={{color: "#221d5d", width: "100%", height: "100%"}}
                             id="text">Click aici pentru a
                            uploada fotografie
                        </div>
                    </Uploader>
                </div>

                {this.state.poza_invite !== null &&
                    <EditPicture2 uploadedImage={this.state.poza_invite}
                                  width={291} //1164
                                  height={552} //2208
                                  border={0}
                                  borderRadius={0}
                                  saveCroppedImage={this.saveCroppedImage}/>}


                {this.state.activeTab === 'options' ?
                <>
                    <Message type="info" style={{marginBottom: '12px'}}
                             description={"Mai multe opțiuni vor fi disponibile după ce salvați modificările și vă autentificați în aplicație!"}/>


                    <div className="Section">
                    <div className="Title">Personalizare text trimitere invitație
                    </div>

                    {
                        this.state.editSendInviteMessage ?
                            <div className={""}>
                                    <textarea
                                        style={{
                                            width: '80%',
                                            height: '100px',
                                            margin: 'auto',
                                            borderColor: '#ccc',
                                            borderRadius: '5px',
                                            padding: '5px',
                                            fontSize: '15px',
                                            fontWeight: '500'
                                        }}
                                        maxLength={255}
                                        value={this.state.sendInviteMessage}
                                        onChange={(e) => this.setState({sendInviteMessage: e.target.value.substring(0,255)})}
                                    />
                                <div>{this.state.sendInviteMessage.length}/255</div>
                            </div>
                            : null
                    }


                    <div className="Options-Row">
                        {
                            this.state.editSendInviteMessage ?
                                <div
                                    className={"active-option"}
                                    style={{width: 'unset'}}
                                    onClick={_ => this.setState({editSendInviteMessage: false})}>Salvează
                                </div>
                                :
                                <div
                                    className={"active-option"}
                                    style={{width: 'unset'}}
                                    onClick={_ => this.setState({editSendInviteMessage: true})}>Editează
                                </div>
                        }
                    </div>
                </div>

                {!window.flutter_inappwebview ? <div className="Section">
                        <div className="Title">Doriți să adăugați o melodie sau să înregistrați un mesaj
                            audio pentru invitație?
                        </div>

                        <div className="Options-Row">
                            <div
                                className={this.state.isUsingAudio ? "active-option" : "inactive-option"}
                                onClick={_ => {
                                    this.setState({isUsingAudio: true})
                                }} style={{marginRight: "30px"}}>Da
                            </div>
                            <div
                                className={!this.state.isUsingAudio ? "active-option" : "inactive-option"}
                                onClick={_ => this.setState({isUsingAudio: false})}>Nu
                            </div>
                        </div>
                    </div>
                    : <div className="Section">
                        <div className="Title">Pentru a adauga un mesaj audio va rugam sa folositi
                            un laptop/pc
                        </div>
                    </div>}

                {this.state.isUsingAudio &&
                    <AddAudio
                        initialWeddingData={this.state.initialWeddingData}
                        audioType={this.state.audioType} serverAudioUrl={this.state.serverAudioUrl}
                        deleteServerAudio={this.state.deleteServerAudio}
                        allowAudioRequest={this.state.allowAudioRequest}
                        customAudioLink={this.state.customAudioLink}
                        customAudioSecond={this.state.customAudioSecond}
                        songSelected={this.state.songSelected}
                        setAudioType={(value) => this.setState({audioType: value})}
                        setAudioBlobData={(value) => this.setState({audioBlobData: value})}
                        setCustomAudioLink={(value) => this.setState({customAudioLink: value})}
                        setCustomAudioSecond={(value) => this.setState({customAudioSecond: value})}
                        setDeleteServerAudio={(value) => this.setState({deleteServerAudio: value})}
                        setSongSelected={(value) => this.setState({songSelected: value})}
                    />}
                    </>:null}
            </div>
        }

        if (this.state.inviteType === "Video") {
            let linkParts = this.state.linkVideo.split("watch?v=")
            let link = ""
            if (linkParts.length === 1) {
                link = linkParts[0]
            } else {
                link = linkParts[0] + "embed/" + linkParts[1]
            }

            let videoId = ""

            try {
                if (this.state.linkVideo !== "") {
                    videoId = this.state.linkVideo.split("watch?v=")[1].split("&")[0]
                }
            } catch (e) {
                
            }
            
            

            body = <div>
                {/* <iframe src={link.split("&")[0]}
					frameBorder='0'
					allow='autoplay; encrypted-media'
					allowFullScreen={true}
					title='video'
				/> */}
                <PlayVideo videoId={videoId} link={link.split("&")[0]}/>

                <div>Daca nu merge video-ul, va rog acordati permisiunile necesare din youtube.
                </div>

                <CustomInput value={this.state.linkVideo}
                             onChange={value => this.setState({linkVideo: value})}
                             placeholder={"Link video Invitatie"}
                             style={customInputStyle} icon={<Icon icon="text-height"/>}/>

                <a style={{display: "table-cell"}} href={this.state.linkVideo} target="_blank"
                   rel="noopener noreferrer">Deschide link</a>
            </div>
        }

        if (this.state.inviteType === "Custom") {

            if (this.state.useNewInvite) {
                body = <>


                    {this.state.activeTab === 'invitation' ?
                        <>
                            <InviteEditComp
                                eventType={this.state.eventType}
                                weddingData={this.state.initialWeddingData}
                                saveWeddingData={this.saveWeddingData}
                                templateDetails={this.state.templateDetails}
                                setTemplateDetails={(val) => {
                                    this.setState({templateDetails: val})
                                }}
                                audioLink={this.state.isUsingAudio && this.state.serverAudioUrl ? this.state.serverAudioUrl : ''}
                                image1Link={this.state.image1Url ? `https://${process.env.REACT_APP_IMAGE_DOMAIN}${this.state.image1Url}` : ''}
                                image2Link={this.state.image2Url ? `https://${process.env.REACT_APP_IMAGE_DOMAIN}${this.state.image2Url}` : ''}
                                image3Link={this.state.image3Url ? `https://${process.env.REACT_APP_IMAGE_DOMAIN}${this.state.image3Url}` : ''}
                                coverImageLink={this.state.coverImageUrl ? `https://${process.env.REACT_APP_IMAGE_DOMAIN}${this.state.coverImageUrl}` : ''}
                                newImage1={this.state.newImage1}
                                newImage2={this.state.newImage2}
                                newImage3={this.state.newImage3}
                                newCoverImage={this.state.newCoverImage}
                                setNewImage1={(val) => {
                                    this.setState({newImage1: val})
                                }}
                                setNewImage2={(val) => {
                                    this.setState({newImage2: val})
                                }}
                                setNewImage3={(val) => {
                                    this.setState({newImage3: val})
                                }}
                                setNewCoverImage={(val) => {
                                    this.setState({newCoverImage: val})
                                }}
                                changeTypeToCustom={() => {
                                    this.setState({
                                        activeTab: 'invitation',
                                        templateDetails: null,
                                        useNewInvite: false
                                    })
                                }}
                                updateLocations={(val) => this.setState({locations: val})}
                                demo={true}
                                setShowDemoModal={() => this.setState({showDemoModal: true})}
                            />

                            <div className="Section">
                                <div className="Title">Vrei mai multe opțiuni de personalizare?
                                </div>
                                <div className="Options-Row">
                                    <div
                                        className={"active-option"}
                                        style={{width: 'unset', cursor: 'pointer'}}
                                        onClick={_ => {
                                            this.setState({activeTab: 'options'})
                                            this.scrollContainerTop()
                                        }}>Da, vreau
                                    </div>
                                </div>
                            </div>
                        </>
                        : null}


                    {this.state.activeTab === 'options' ?
                        <>
                            <Message type="info"
                                     description={"Mai multe opțiuni vor fi disponibile după ce salvați modificările și vă autentificați în aplicație!"}/>


                            <div className="Section">
                                <div className="Title">Personalizare text trimitere invitație
                                </div>

                                {
                                    this.state.editSendInviteMessage ?
                                        <div className={""}>
                                    <textarea
                                        style={{
                                            width: '80%',
                                            height: '100px',
                                            margin: 'auto',
                                            borderColor: '#ccc',
                                            borderRadius: '5px',
                                            padding: '5px',
                                            fontSize: '15px',
                                            fontWeight: '500'
                                        }}
                                        maxLength={255}
                                        value={this.state.sendInviteMessage}
                                        onChange={(e) => this.setState({sendInviteMessage: e.target.value.substring(0, 255)})}
                                    />
                                            <div>{this.state.sendInviteMessage.length}/255</div>
                                        </div>
                                        : null
                                }


                                <div className="Options-Row">
                                    {
                                        this.state.editSendInviteMessage ?
                                            <div
                                                className={"active-option"}
                                                style={{width: 'unset'}}
                                                onClick={_ => this.setState({editSendInviteMessage: false})}>Salvează
                                            </div>
                                            :
                                            <div
                                                className={"active-option"}
                                                style={{width: 'unset'}}
                                                onClick={_ => this.setState({editSendInviteMessage: true})}>Editează
                                            </div>
                                    }
                                </div>
                            </div>

                            {!window.flutter_inappwebview ? <div className="Section">
                                    <div className="Title">Doriți să adăugați o melodie sau să
                                        înregistrați un mesaj audio pentru invitație?
                                    </div>

                                    <div className="Options-Row">
                                        <div
                                            className={this.state.isUsingAudio ? "active-option" : "inactive-option"}
                                            onClick={_ => {
                                                this.setState({isUsingAudio: true})
                                            }} style={{marginRight: "30px"}}>Da
                                        </div>
                                        <div
                                            className={!this.state.isUsingAudio ? "active-option" : "inactive-option"}
                                            onClick={_ => this.setState({isUsingAudio: false})}>Nu
                                        </div>
                                    </div>
                                </div>
                                : <div className="Section">
                                    <div className="Title">Pentru a adauga un mesaj audio va
                                        rugam sa folositi un laptop/pc
                                    </div>
                                </div>}

                            {this.state.isUsingAudio &&
                                <AddAudio
                                    initialWeddingData={this.state.initialWeddingData}
                                    audioType={this.state.audioType}
                                    serverAudioUrl={this.state.serverAudioUrl}
                                    deleteServerAudio={this.state.deleteServerAudio}
                                    allowAudioRequest={this.state.allowAudioRequest}
                                    customAudioLink={this.state.customAudioLink}
                                    customAudioSecond={this.state.customAudioSecond}
                                    songSelected={this.state.songSelected}
                                    setAudioType={(value) => this.setState({audioType: value})}
                                    setAudioBlobData={(value) => this.setState({audioBlobData: value})}
                                    setCustomAudioLink={(value) => this.setState({customAudioLink: value})}
                                    setCustomAudioSecond={(value) => this.setState({customAudioSecond: value})}
                                    setDeleteServerAudio={(value) => this.setState({deleteServerAudio: value})}
                                    setSongSelected={(value) => this.setState({songSelected: value})}
                                />}
                        </> : null}
                </>
            } else {
                body = <div>
                    {this.state.eventType !== "Petrecere" &&
                        <ParintiComponent eventType={this.state.eventType}
                                          handleChange={this.handleChange}
                                          tataMire={this.state.tataMire}
                                          mamaMire={this.state.mamaMire}
                                          numeParintiMire={this.state.numeParintiMire}
                                          tataMireasa={this.state.tataMireasa}
                                          mamaMireasa={this.state.mamaMireasa}
                                          numeParintiMireasa={this.state.numeParintiMireasa}
                        />}

                    {this.state.eventType !== "Petrecere" &&
                        <NasiComponent nasi={this.state.nasi} handleChange={this.handleChange}
                                       addNasi={this.addNasi}
                                       removeNasi={this.removeNasi}
                                       numeNasa={this.state.numeNasa} numeNas={this.state.numeNas}
                                       numeFamilie={this.state.numeFamilie}
                        />}

                    <CustomInput value={this.state.description}
                                 onChange={this.changeInviteDescription}
                                 componentClass={"textarea"} icon={<Icon icon="pencil"/>}
                                 placeholder={`Text Invitatie ${this.state.description.length}/${textarea_max_length}`}
                                 style={customInputStyle}/>

                    <div style={{fontSize: "18px"}}>Format text</div>
                    <div className="Invite-Design">
                        <div>
                            <p>Dimensiune</p>

                            <Input value={this.state.fontSize.split("p")[0]}
                                   onChange={value => this.setState({fontSize: value + "px"})}
                                   placeholder={"Dimensiune"}
                                   style={{
                                       backgroundColor: "#E5F7FE",
                                       borderRadius: "10px",
                                       width: "100px"
                                   }}
                                   icon={<Icon icon="text-height"/>} type={"number"}/>
                        </div>

                        <div className="color-container">
                            <p>Culoare</p>

                            <div className="color-choice">
                                <div style={{
                                    height: "35px",
                                    width: "35px",
                                    backgroundColor: this.state.fontColor,
                                    borderRadius: "50%",
                                    border: '1px solid #000'
                                }} onClick={() => this.setState({isChangingColor: true})}></div>

                                {this.state.isChangingColor &&
                                    <Modal classNames={{
                                        overlay: 'modal-overlay',
                                        modal: 'modal-colors',
                                        closeButton: 'modal-close-button',
                                    }} center open={this.state.isChangingColor}
                                           onClose={() => this.setState({isChangingColor: false})}>
                                        <div className="color-options">
                                            <CirclePicker color={this.state.fontColor}
                                                          onChange={newColor => this.setState({
                                                              fontColor: newColor.hex,
                                                              isChangingColor: false
                                                          })}/>

                                            <div className="basic-color">
                                                <div className="basic-color-white"
                                                     onClick={() => this.setState({
                                                         fontColor: "#fff",
                                                         isChangingColor: false
                                                     })}></div>
                                                <div className="basic-color-black"
                                                     onClick={() => this.setState({
                                                         fontColor: "#000",
                                                         isChangingColor: false
                                                     })}></div>
                                            </div>
                                        </div>
                                    </Modal>}
                            </div>
                        </div>

                        <div style={{textAlign: "center"}}>
                            <p>Font</p>

                            <Dropdown title={this.state.fontFamily}
                                      activeKey={this.state.fontFamily}
                                      className="dropdown_scroll"
                                      style={{backgroundColor: "#E5F7FE", borderRadius: "10px"}}>
                                {this.state.fontsOptions.map((option, index) => {
                                    return <Dropdown.Item key={index} eventKey={option}
                                                          onClick={() => this.setState({fontFamily: option})}>{option}</Dropdown.Item>
                                })}
                            </Dropdown>
                        </div>
                    </div>

                    <p style={{
                        fontSize: this.state.fontSize,
                        fontFamily: this.state.fontFamily,
                        color: "" + this.state.fontColor,
                        marginBottom: "20px"
                    }}>Text final</p>

                    {this.state.backgroundImageLink !== null
                        ? <div>
                            <p>Imagine fundal</p>
                            <img style={{padding: "5px", height: "300px", alignSelf: "center"}}
                                 src={this.state.backgroundImageLink} alt=""/>

                            {this.state.poza_invite === null && <div className="Section">
                                <div className="Title">Doriti sa stergeti imaginea de fundal
                                    curenta?
                                </div>

                                <div className="Options-Row">
                                    <div
                                        className={this.state.deleteBackgroundImageLink ? "active-option" : "inactive-option"}
                                        onClick={_ => this.setState({deleteBackgroundImageLink: true})}
                                        style={{marginRight: "30px"}}>Da
                                    </div>
                                    <div
                                        className={!this.state.deleteBackgroundImageLink ? "active-option" : "inactive-option"}
                                        onClick={_ => this.setState({deleteBackgroundImageLink: false})}>Nu
                                    </div>
                                </div>
                            </div>}
                        </div>
                        : <p>Nicio imagine adaugata</p>
                    }

                    <div className="Upload-zone">
                        <p> {this.state.backgroundImageLink !== null ? "Schimba Fotografia de Fundal (va sterge imaginea curenta)" : "Adauga fotografie de fundal"} </p>

                        <Uploader onChange={this.handlePoza} accept=".jpg"
                                  style={{borderRadius: "5px"}}
                                  autoUpload={false} multiple={false}
                                  disabled={this.state.poza_invite !== null}
                                  fileListVisible={false}
                        >
                            <div style={{color: "#221d5d", width: "100%", height: "100%"}}
                                 id="text">Click aici pentru a
                                incarca fotografia
                            </div>
                        </Uploader>
                    </div>

                    {this.state.poza_invite !== null &&
                        <EditPicture2 uploadedImage={this.state.poza_invite}
                                      width={291} //1164
                                      height={552} //2210
                                      border={0}
                                      borderRadius={0}
                                      saveCroppedImage={this.saveCroppedImage}/>}

                    {this.state.activeTab === 'options' ?
                        <>
                            <Message type="info"
                                     description={"Mai multe opțiuni vor fi disponibile după ce salvați modificările și vă autentificați în aplicație!"}/>
                            <div className="Section">
                        <div className="Title">Personalizare text trimitere invitație
                        </div>

                        {
                            this.state.editSendInviteMessage ?
                                <div className={""}>
                                    <textarea
                                        style={{
                                            width: '80%',
                                            height: '100px',
                                            margin: 'auto',
                                            borderColor: '#ccc',
                                            borderRadius: '5px',
                                            padding: '5px',
                                            fontSize: '15px',
                                            fontWeight: '500'
                                        }}
                                        maxLength={255}
                                        value={this.state.sendInviteMessage}
                                        onChange={(e) => this.setState({sendInviteMessage: e.target.value.substring(0,255)})}
                                    />
                                    <div>{this.state.sendInviteMessage.length}/255</div>
                                </div>
                                : null
                        }


                        <div className="Options-Row">
                            {
                                this.state.editSendInviteMessage ?
                                    <div
                                        className={"active-option"}
                                        style={{width: 'unset'}}
                                        onClick={_ => this.setState({editSendInviteMessage: false})}>Salvează
                                    </div>
                                    :
                                    <div
                                        className={"active-option"}
                                        style={{width: 'unset'}}
                                        onClick={_ => this.setState({editSendInviteMessage: true})}>Editează
                                    </div>
                            }
                        </div>
                    </div>

                    {!window.flutter_inappwebview ? <div className="Section">
                            <div className="Title">Doriți să adăugați o melodie sau să înregistrați un
                                mesaj audio pentru invitație?
                            </div>

                            <div className="Options-Row">
                                <div
                                    className={this.state.isUsingAudio ? "active-option" : "inactive-option"}
                                    onClick={_ => {
                                        this.setState({isUsingAudio: true})
                                    }} style={{marginRight: "30px"}}>Da
                                </div>
                                <div
                                    className={!this.state.isUsingAudio ? "active-option" : "inactive-option"}
                                    onClick={_ => this.setState({isUsingAudio: false})}>Nu
                                </div>
                            </div>
                        </div>
                        : <div className="Section">
                            <div className="Title">Pentru a adauga un mesaj audio va rugam sa
                                folositi un laptop/pc
                            </div>
                        </div>}

                    {this.state.isUsingAudio &&
                        <AddAudio
                            initialWeddingData={this.state.initialWeddingData}
                            audioType={this.state.audioType}
                            serverAudioUrl={this.state.serverAudioUrl}
                            deleteServerAudio={this.state.deleteServerAudio}
                            allowAudioRequest={this.state.allowAudioRequest}
                            customAudioLink={this.state.customAudioLink}
                            customAudioSecond={this.state.customAudioSecond}
                            songSelected={this.state.songSelected}
                            setAudioType={(value) => this.setState({audioType: value})}
                            setAudioBlobData={(value) => this.setState({audioBlobData: value})}
                            setCustomAudioLink={(value) => this.setState({customAudioLink: value})}
                            setCustomAudioSecond={(value) => this.setState({customAudioSecond: value})}
                            setDeleteServerAudio={(value) => this.setState({deleteServerAudio: value})}
                            setSongSelected={(value) => this.setState({songSelected: value})}
                        />}

                        </>:null}
                </div>
            }
        }

        if (this.state.inviteType === "CustomTemplated") {
            body =
                <div>
                    {this.state.activeTab === 'invitation' ?
                        <>
                            <BackgroundStyleComp
                                templateDetails={this.state.templateDetails}
                                setTemplateDetails={(val) => this.setState({templateDetails: val})}
                                backgroundImageLink={this.state.backgroundImageLink}
                                deleteBackgroundImageLink={this.state.deleteBackgroundImageLink}
                                setDeleteBackgroundImageLink={(val) => this.setState({deleteBackgroundImageLink: val})}
                                handlePoza={this.handlePoza}
                                poza_invite={this.state.poza_invite}
                                saveCroppedImage={this.saveCroppedImage}
                            />

                            <InviteEditComp
                                eventType={this.state.eventType}
                                weddingData={this.state.initialWeddingData}
                                saveWeddingData={this.saveWeddingData}
                                templateDetails={this.state.templateDetails}
                                setTemplateDetails={(val) => this.setState({templateDetails: val})}
                                audioLink={this.state.isUsingAudio && this.state.serverAudioUrl ? this.state.serverAudioUrl : ''}
                                image1Link={this.state.image1Url ? `https://${process.env.REACT_APP_IMAGE_DOMAIN}${this.state.image1Url}` : ''}
                                image2Link={this.state.image2Url ? `https://${process.env.REACT_APP_IMAGE_DOMAIN}${this.state.image2Url}` : ''}
                                image3Link={this.state.image3Url ? `https://${process.env.REACT_APP_IMAGE_DOMAIN}${this.state.image3Url}` : ''}
                                coverImageLink={this.state.coverImageUrl ? `https://${process.env.REACT_APP_IMAGE_DOMAIN}${this.state.coverImageUrl}` : ''}
                                newImage1={this.state.newImage1}
                                newImage2={this.state.newImage2}
                                newImage3={this.state.newImage3}
                                newCoverImage={this.state.newCoverImage}
                                setNewImage1={(val) => {
                                    this.setState({newImage1: val})
                                }}
                                setNewImage2={(val) => {
                                    this.setState({newImage2: val})
                                }}
                                setNewImage3={(val) => {
                                    this.setState({newImage3: val})
                                }}
                                setNewCoverImage={(val) => {
                                    this.setState({newCoverImage: val})
                                }}
                                changeTypeToCustom={() => {
                                    this.setState({
                                        templateDetails: null,
                                        useNewInvite: false
                                    })
                                }}
                                updateLocations={(val) => this.setState({locations: val})}
                            />

                            <div className="Section">
                                <div className="Title">Vrei mai multe opțiuni de personalizare?
                                </div>
                                <div className="Options-Row">
                                    <div
                                        className={"active-option"}
                                        style={{width: 'unset', cursor: 'pointer'}}
                                        onClick={_ => {
                                            this.setState({activeTab: 'options'})
                                            this.scrollContainerTop()
                                        }}>Da, vreau
                                    </div>
                                </div>
                            </div>
                        </> : null}


                    {this.state.activeTab === 'options' ?
                        <>
                            <Message type="info"
                                     description={"Mai multe opțiuni vor fi disponibile după ce salvați modificările și vă autentificați în aplicație!"}/>

                            <div className="Section">
                                <div className="Title">Personalizare text trimitere invitație
                                </div>
                                {
                                    this.state.editSendInviteMessage ?
                                        <div className={""}>
                                    <textarea
                                        style={{
                                            width: '80%',
                                            height: '100px',
                                            margin: 'auto',
                                            borderColor: '#ccc',
                                            borderRadius: '5px',
                                            padding: '5px',
                                            fontSize: '15px',
                                            fontWeight: '500'
                                        }}
                                        maxLength={255}
                                        value={this.state.sendInviteMessage}
                                        onChange={(e) => this.setState({sendInviteMessage: e.target.value.substring(0, 255)})}
                                    />
                                            <div>{this.state.sendInviteMessage.length}/255</div>
                                        </div>
                                        : null
                                }
                                <div className="Options-Row">
                                    {
                                        this.state.editSendInviteMessage ?
                                            <div
                                                className={"active-option"}
                                                style={{width: 'unset'}}
                                                onClick={_ => this.setState({editSendInviteMessage: false})}>Salvează
                                            </div>
                                            :
                                            <div
                                                className={"active-option"}
                                                style={{width: 'unset'}}
                                                onClick={_ => this.setState({editSendInviteMessage: true})}>Editează
                                            </div>
                                    }
                                </div>
                            </div>

                            {/*
                    <div className="Section">
                        <div className="Title">Adăugare imagine invitație</div>
                        <div>Această imagine va apărea în momentul în care trimiteți invitația</div>

                        <div className="Options-Row">
                            <div
                                className={this.state.isUsingOGImage ? "active-option" : "inactive-option"}
                                onClick={_ => this.setState({isUsingOGImage: true})}
                                style={{marginRight: "30px"}}>Da
                            </div>
                            <div
                                className={!this.state.isUsingOGImage ? "active-option" : "inactive-option"}
                                onClick={_ => this.setState({isUsingOGImage: false})}>Nu
                            </div>
                        </div>


                        {this.state.isUsingOGImage ?
                            <>
                                {this.state.eventImageLink !== null
                                    ? <img style={{
                                        padding: "5px",
                                        width: "290px",
                                        height: "148px",
                                        alignSelf: "center"
                                    }}
                                           src={this.state.eventImageLink} alt=""/>
                                    : <div>Nicio imagine adaugată</div>
                                }

                                <div style={customInputStyle}>
                                    <Uploader onChange={this.handleEventPoza} accept=".jpg"
                                              style={{borderRadius: "5px"}} fileListVisible={false}
                                              autoUpload={false} multiple={false}
                                              disabled={this.state.eventImage !== null || this.state.eventImage}>
                                        <div style={{color: "#221d5d", width: "100%", height: "100%"}}
                                             id="text">Click aici
                                            pentru a încărca imaginea
                                        </div>
                                    </Uploader>
                                </div>

                                {this.state.eventImage && this.state.eventImage !== null && this.state.eventImage[0] ?
                                    <EditPicture2 uploadedImage={this.state.eventImage[0]}
                                                  width={290} //1164
                                                  height={148} //2210
                                                  border={0}
                                                  borderRadius={0}
                                                  saveCroppedImage={this.saveCroppedEventImage}/>
                                    : null
                                }
                            </>
                            : null}
                    </div>
*/}
                            {!window.flutter_inappwebview ? <div className="Section">
                                    <div className="Title">Doriți să adăugați o melodie sau să înregistrați
                                        un mesaj audio pentru invitație?
                                    </div>

                                    <div className="Options-Row">
                                        <div
                                            className={this.state.isUsingAudio ? "active-option" : "inactive-option"}
                                            onClick={_ => {
                                                this.setState({isUsingAudio: true})
                                            }} style={{marginRight: "30px"}}>Da
                                        </div>
                                        <div
                                            className={!this.state.isUsingAudio ? "active-option" : "inactive-option"}
                                            onClick={_ => this.setState({isUsingAudio: false})}>Nu
                                        </div>
                                    </div>
                                </div>
                                : <div className="Section">
                                    <div className="Title">Pentru a adauga un mesaj audio va rugam sa
                                        folositi un laptop/pc
                                    </div>
                                </div>}

                            {this.state.isUsingAudio &&
                                <AddAudio
                                    initialWeddingData={this.state.initialWeddingData}
                                    audioType={this.state.audioType}
                                    serverAudioUrl={this.state.serverAudioUrl}
                                    deleteServerAudio={this.state.deleteServerAudio}
                                    allowAudioRequest={this.state.allowAudioRequest}
                                    customAudioLink={this.state.customAudioLink}
                                    customAudioSecond={this.state.customAudioSecond}
                                    songSelected={this.state.songSelected}
                                    setAudioType={(value) => this.setState({audioType: value})}
                                    setAudioBlobData={(value) => this.setState({audioBlobData: value})}
                                    setCustomAudioLink={(value) => this.setState({customAudioLink: value})}
                                    setCustomAudioSecond={(value) => this.setState({customAudioSecond: value})}
                                    setDeleteServerAudio={(value) => this.setState({deleteServerAudio: value})}
                                    setSongSelected={(value) => this.setState({songSelected: value})}
                                />}
                        </> : null}
                </div>
        }


        return (
            <div className="WebsiteInviteModule-container">

                <InviteHeader
                    title={'Creează invitația'}
                    save={() => this.createInvite(false)}
                    saveLoading={this.state.savingData}
                    preview={() => this.createInvite(true)}
                />

                <LoadingModal loading={this.state.loading}/>

                <Modal classNames={{
                    overlay: 'modal-overlay',
                    modal: 'modal',
                    closeButton: 'modal-close-button',
                }} open={this.state.showModalChangeType} onClose={this.closeModalChangeType}>
                    <div style={{marginTop: "30px"}}>
                        <div>Daca schimbati tipul de invitatie, pierdeti datele introduse in cadrul
                            acesteia
                        </div>

                        <ConfirmButton text={"Confirm"} function={this.changeType}/>
                    </div>
                </Modal>

                <div className="WebsiteInviteModule-contents">
                    <div
                        className={"input-zone " + (this.state.inviteType === '' || this.state.showChangeForm ? "large" : "")}
                        style={{flex:1}}
                        ref={this.scrollContainer}>
                        {/* {this.state.inviteType === "" && <div>
							<div className="Input_Type_Options">
								<div className="Input_Type_Option" onClick={_ => this.setInviteType("Personal")}>Invitatie personala</div>

								<div className="Input_Type_Option" onClick={_ => this.setInviteType("Video")}>Incarca link</div>

								<div className="Input_Type_Option" onClick={_ => this.setInviteType("Custom")}>Invitatie personalizata</div>
							</div>

							<BackButton text={"Înapoi"} function={this.goBack} />
						</div>}

						{this.state.saveTheDateType !== "" && <div>
							<div onClick={this.openModalChangeType} className="ChangeChoiceButton">
								Alege alt tip de invitatie
							</div>
						</div>} */}

                        {/*
						<SelectInviteType inviteType={this.state.inviteType}
										  setInviteType={this.setInviteType}
										  useNewInvite={this.state.useNewInvite}
							goBack={this.goBack} openModalChangeType={this.openModalChangeType} text={"invitatie"} />
							*/}

                        {
                            this.state.inviteType === '' || this.state.showChangeForm ?
                                <>
                                    <h5 className={"invite-type-title"}>Alegeți un template
                                        predefinit</h5>
                                    <div className={"chooseTemplate-filters"}>
                                        <div
                                            className={"item " + (this.state.sortTemplates === 'top' ? 'active' : '')}
                                            onClick={() => this.setState({sortTemplates: 'top'})}>
                                            Top favorite
                                        </div>
                                        <div
                                            className={"item " + (this.state.sortTemplates === 'newest' ? 'active' : '')}
                                            onClick={() => this.setState({sortTemplates: 'newest'})}>
                                            Cele mai noi
                                        </div>
                                    </div>
                                    <div className={"chooseTemplate"}>
                                        {
                                            this.sortTemplates([...inviteTemplates]).reverse()
                                                .filter(item => ((item.type === this.state.initialWeddingData.eventType) ||
                                                    (item.otherTypes && item.otherTypes.includes(this.state.initialWeddingData.eventType))

                                                ) && item.id < 1000).map((template, i) => (
                                                <div key={i}
                                                     className={"item " + (template.title ? 'with-title' : '')}
                                                     onClick={() => {

                                                         if (this.state.inviteId === -1 && this.state.templateSelected === false) {
                                                             this.changeTemplateValue('id', template.id)

                                                             this.setState({
                                                                 inviteType: 'Custom',
                                                                 useNewInvite: true,
                                                                 showChangeModalToCustomNew: false,
                                                                 showChangeForm: false,
                                                                 templateSelected: true
                                                             }, () => {
                                                                 this.scrollContainerTop()
                                                             })
                                                         } else {

                                                             if (this.state.useNewInvite === true && this.state.inviteType === 'Custom') {
                                                                 this.changeTemplateValue('id', template.id)
                                                                 this.setState({
                                                                     showChangeForm: false
                                                                 }, () => {
                                                                     this.scrollContainerTop()
                                                                 })

                                                             } else {
                                                                 this.setState({
                                                                     showChangeModalToCustomNew: true,
                                                                     newTemplateId: template.id
                                                                 })
                                                             }
                                                         }

                                                     }}>
                                                    <img alt={""} src={template.preview}
                                                         loading={"lazy"}/>
                                                    {
                                                        template.title ?
                                                            <div
                                                                className={"item-title"}>{template.title}</div>
                                                            : null
                                                    }

                                                    {
                                                        this.queryParams.get('iframe') ?
                                                            <div className={"item-overlay"}>
                                                                <div
                                                                    className={"item-overlay-buttons"}>
                                                                    <div
                                                                        className={'item-button-choose'}
                                                                        onClick={() => {

                                                                            if (this.state.inviteId === -1 && this.state.templateSelected === false) {
                                                                                this.changeTemplateValue('id', template.id)

                                                                                this.setState({
                                                                                    inviteType: 'Custom',
                                                                                    useNewInvite: true,
                                                                                    showChangeModalToCustomNew: false,
                                                                                    showChangeForm: false,
                                                                                    templateSelected: true
                                                                                }, () => {
                                                                                    this.scrollContainerTop()
                                                                                })
                                                                            } else {

                                                                                if (this.state.useNewInvite === true && this.state.inviteType === 'Custom') {
                                                                                    this.changeTemplateValue('id', template.id)
                                                                                    this.setState({
                                                                                        showChangeForm: false
                                                                                    }, () => {
                                                                                        this.scrollContainerTop()
                                                                                    })

                                                                                } else {
                                                                                    this.setState({
                                                                                        showChangeModalToCustomNew: true,
                                                                                        newTemplateId: template.id
                                                                                    })
                                                                                }
                                                                            }

                                                                        }}>Alege
                                                                    </div>

                                                                    <div
                                                                        className={'item-button-demo'}
                                                                        onClick={() => {
                                                                        }}>Demo
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : null
                                                    }
                                                </div>
                                            ))
                                        }
                                    </div>

                                    {/* For old events using the old custom invite */}
                                    {this.state.useNewInvite === false && this.state.inviteType === 'Custom' ?
                                        <>
                                            <h5 className={"invite-type-title"}>Creează invitația de
                                                la 0</h5>
                                            <div className={"chooseTemplate"}>
                                                <div key={'custom'} className={"item"}
                                                     onClick={() => {

                                                         if (this.state.inviteId === -1 && this.state.templateSelected === false) {
                                                             if (!this.state.initialWeddingData.tutorials || (this.state.initialWeddingData.tutorials && !this.state.initialWeddingData.tutorials.inviteCustom)) {
                                                                 this.setState({
                                                                     tutorialModalCustom: true
                                                                 })
                                                             }

                                                             this.setState({
                                                                 inviteType: 'Custom',
                                                                 useNewInvite: false,
                                                                 showChangeModalToCustom: false,
                                                                 showChangeForm: false,
                                                                 templateDetails: null,
                                                                 templateSelected: true
                                                             }, () => {
                                                                 this.scrollContainerTop()
                                                             })
                                                         } else {
                                                             if (this.state.useNewInvite === false && this.state.inviteType === 'Custom') {
                                                                 this.setState({
                                                                     showChangeForm: false
                                                                 }, () => {
                                                                     this.scrollContainerTop()
                                                                 })

                                                             } else {
                                                                 this.setState({
                                                                     showChangeModalToCustom: true
                                                                 })
                                                             }
                                                         }
                                                     }}>
                                                    <img alt={""}
                                                         src={'/images/custom-template.jpg'}/>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <h5 className={"invite-type-title"}>Creează invitația de
                                                la 0</h5>
                                            <div className={"chooseTemplate"}>
                                                <div key={'custom'} className={"item"}
                                                     onClick={() => {

                                                         if (this.state.inviteId === -1 && this.state.templateSelected === false) {
                                                             if (!this.state.initialWeddingData.tutorials || (this.state.initialWeddingData.tutorials && !this.state.initialWeddingData.tutorials.inviteCustomTemplated)) {
                                                                 this.setState({
                                                                     tutorialModalCustomTemplated: true
                                                                 })
                                                             }
                                                             this.changeInviteTypeToCustomTemplated();
                                                             this.setState({
                                                                 inviteType: 'CustomTemplated',
                                                                 useNewInvite: false,
                                                                 showChangeModalToCustomTemplated: false,
                                                                 showChangeForm: false,
                                                                 templateSelected: true
                                                             }, () => {
                                                                 this.scrollContainerTop()
                                                                 this.changeInviteTypeToCustomTemplated();
                                                             })
                                                         } else {
                                                             if (this.state.inviteType === 'CustomTemplated') {
                                                                 this.setState({
                                                                     showChangeForm: false
                                                                 }, () => {
                                                                     this.scrollContainerTop()
                                                                 })

                                                             } else {
                                                                 this.setState({
                                                                     showChangeModalToCustomTemplated: true
                                                                 })
                                                             }
                                                         }
                                                     }}>
                                                    <img alt={""}
                                                         src={'/images/custom-template.jpg'}/>
                                                </div>
                                            </div>
                                        </>}

                                    <h5 className={"invite-type-title"}>Încarcă invitație deja
                                        creată</h5>
                                    <div className={"chooseTemplate"}>
                                        <div key={'personal'} className={"item"} onClick={() => {

                                            if (this.state.inviteId === -1 && this.state.templateSelected === false) {

                                                this.setState({
                                                    inviteType: 'Personal',
                                                    useNewInvite: false,
                                                    showChangeModalToPersonal: false,
                                                    showChangeForm: false,
                                                    templateDetails: null,
                                                    templateSelected: true
                                                }, () => {
                                                    this.scrollContainerTop()
                                                })
                                            } else {

                                                if (this.state.inviteType === 'Personal') {
                                                    this.setState({
                                                        showChangeForm: false
                                                    }, () => {
                                                        this.scrollContainerTop()
                                                    })

                                                } else {
                                                    this.setState({
                                                        showChangeModalToPersonal: true
                                                    })
                                                }
                                            }
                                        }}>
                                            <img alt={""} src={'/images/foto-template.jpg'}/>
                                        </div>

                                        <div key={'video'} className={"item"} onClick={() => {
                                            if (this.state.inviteId === -1 && this.state.templateSelected === false) {

                                                this.setState({
                                                    inviteType: 'Video',
                                                    useNewInvite: false,
                                                    showChangeModalToVideo: false,
                                                    showChangeForm: false,
                                                    templateDetails: null,
                                                    templateSelected: true
                                                }, () => {
                                                    this.scrollContainerTop()
                                                })
                                            } else {
                                                if (this.state.inviteType === 'Video') {
                                                    this.setState({
                                                        showChangeForm: false
                                                    }, () => {
                                                        this.scrollContainerTop()
                                                    })

                                                } else {
                                                    this.setState({
                                                        showChangeModalToVideo: true
                                                    })
                                                }
                                            }
                                        }}>
                                            <img alt={""} src={'/images/video-template.jpg'}/>
                                        </div>
                                    </div>

                                    <Modal classNames={{
                                        overlay: 'modal-overlay',
                                        modal: 'modal',
                                        closeButton: 'modal-close-button',
                                    }} open={this.state.showChangeModalToCustom} onClose={() => {
                                        this.setState({
                                            showChangeModalToCustom: false
                                        })
                                    }}>
                                        <div style={{marginTop: "30px"}}>
                                            <div>Atenție! Schimbând tipul de invitație veți pierde
                                                toate configurările
                                            </div>

                                            <ConfirmButton text={"Confirm"} function={() => {
                                                if (!this.state.initialWeddingData.tutorials || (this.state.initialWeddingData.tutorials && !this.state.initialWeddingData.tutorials.inviteCustom)) {
                                                    this.setState({
                                                        tutorialModalCustom: true
                                                    })
                                                }
                                                this.setState({
                                                    inviteType: 'Custom',
                                                    useNewInvite: false,
                                                    showChangeModalToCustom: false,
                                                    showChangeForm: false,
                                                    templateDetails: null
                                                }, () => {
                                                    this.scrollContainerTop()
                                                })

                                            }}/>
                                        </div>
                                    </Modal>

                                    <Modal classNames={{
                                        overlay: 'modal-overlay',
                                        modal: 'modal',
                                        closeButton: 'modal-close-button',
                                    }} open={this.state.showChangeModalToCustomTemplated}
                                           onClose={() => {
                                               this.setState({
                                                   showChangeModalToCustomTemplated: false
                                               })
                                           }}>
                                        <div style={{marginTop: "30px"}}>
                                            <div>Atenție! Schimbând tipul de invitație veți pierde
                                                toate configurările
                                            </div>

                                            <ConfirmButton text={"Confirm"} function={() => {
                                                if (!this.state.initialWeddingData.tutorials || (this.state.initialWeddingData.tutorials && !this.state.initialWeddingData.tutorials.inviteCustomTemplated)) {
                                                    this.setState({
                                                        showChangeModalToCustomTemplated: true
                                                    })
                                                }
                                                this.changeInviteTypeToCustomTemplated();
                                                this.setState({
                                                    inviteType: 'CustomTemplated',
                                                    useNewInvite: false,
                                                    showChangeModalToCustomTemplated: false,
                                                    showChangeForm: false
                                                }, () => {
                                                    this.scrollContainerTop()
                                                    this.changeInviteTypeToCustomTemplated();
                                                })

                                            }}/>
                                        </div>
                                    </Modal>

                                    <Modal classNames={{
                                        overlay: 'modal-overlay',
                                        modal: 'modal',
                                        closeButton: 'modal-close-button',
                                    }} open={this.state.showChangeModalToCustomNew} onClose={() => {
                                        this.setState({
                                            showChangeModalToCustomNew: false
                                        })
                                    }}>
                                        <div style={{marginTop: "30px"}}>
                                            <div>Atenție! Schimbând tipul de invitație veți pierde
                                                toate configurările
                                            </div>

                                            <ConfirmButton text={"Confirm"} function={() => {
                                                if (!this.state.initialWeddingData.tutorials || (this.state.initialWeddingData.tutorials && !this.state.initialWeddingData.tutorials.inviteTemplate)) {
                                                    this.setState({
                                                        tutorialModalTemplate: true
                                                    })
                                                }
                                                this.changeTemplateValue('id', this.state.newTemplateId)
                                                this.setState({
                                                    inviteType: 'Custom',
                                                    useNewInvite: true,
                                                    showChangeModalToCustomNew: false,
                                                    showChangeForm: false
                                                }, () => {
                                                    this.scrollContainerTop()
                                                })

                                            }}/>
                                        </div>
                                    </Modal>

                                    <Modal classNames={{
                                        overlay: 'modal-overlay',
                                        modal: 'modal',
                                        closeButton: 'modal-close-button',
                                    }} open={this.state.showChangeModalToPersonal} onClose={() => {
                                        this.setState({
                                            showChangeModalToPersonal: false
                                        })
                                    }}>
                                        <div style={{marginTop: "30px"}}>
                                            <div>Atenție! Schimbând tipul de invitație veți pierde
                                                toate configurările
                                            </div>

                                            <ConfirmButton text={"Confirm"} function={() => {
                                                if (!this.state.initialWeddingData.tutorials || (this.state.initialWeddingData.tutorials && !this.state.initialWeddingData.tutorials.invitePhoto)) {
                                                    this.setState({
                                                        tutorialModalPhoto: true
                                                    })
                                                }
                                                this.setState({
                                                    inviteType: 'Personal',
                                                    useNewInvite: false,
                                                    showChangeModalToPersonal: false,
                                                    showChangeForm: false,
                                                    templateDetails: null
                                                }, () => {
                                                    this.scrollContainerTop()
                                                })

                                            }}/>
                                        </div>
                                    </Modal>

                                    <Modal classNames={{
                                        overlay: 'modal-overlay',
                                        modal: 'modal',
                                        closeButton: 'modal-close-button',
                                    }} open={this.state.showChangeModalToVideo} onClose={() => {
                                        this.setState({
                                            showChangeModalToVideo: false
                                        })
                                    }}>
                                        <div style={{marginTop: "30px"}}>
                                            <div>Atenție! Schimbând tipul de invitație veți pierde
                                                toate configurările
                                            </div>

                                            <ConfirmButton text={"Confirm"} function={() => {
                                                if (!this.state.initialWeddingData.tutorials || (this.state.initialWeddingData.tutorials && !this.state.initialWeddingData.tutorials.inviteVideo)) {
                                                    this.setState({
                                                        tutorialModalVideo: true
                                                    })
                                                }
                                                this.setState({
                                                    inviteType: 'Video',
                                                    useNewInvite: false,
                                                    showChangeModalToVideo: false,
                                                    showChangeForm: false,
                                                    templateDetails: null
                                                }, () => {
                                                    this.scrollContainerTop()
                                                })

                                            }}/>
                                        </div>
                                    </Modal>
                                </>
                                :
                                <>

                                {this.state.activeTab === 'invitation' ?
                                    <div className={"edit-invite-main-buttons-cont"}>
                                        <div onClick={() =>
                                            this.setState({
                                                showChangeForm: true
                                            }, () => {
                                                window.scrollTo(0, 0)
                                            })
                                        } className="eim-button yellow">
                                            Alege un alt template
                                        </div>
                                    </div>
                                    :
                                    null}


                                    {body}

                                    {this.state.activeTab === 'introAnimation' ?
                                        <IntroAnimationConfigComp
                                            templateConfigValues={this.state.templateConfigValues}
                                            isUsingAudio={this.state.isUsingAudio}
                                            initialWeddingData={this.state.initialWeddingData}
                                            templateDetails={this.state.templateDetails}
                                            setTemplateDetails={(value) => this.setState({templateDetails: value})}
                                            audioType={this.state.audioType}
                                            serverAudioUrl={this.state.serverAudioUrl}
                                            deleteServerAudio={this.state.deleteServerAudio}
                                            allowAudioRequest={this.state.allowAudioRequest}
                                            customAudioLink={this.state.customAudioLink}
                                            customAudioSecond={this.state.customAudioSecond}
                                            songSelected={this.state.songSelected}
                                            setAudioType={(value) => this.setState({audioType: value})}
                                            setAudioBlobData={(value) => this.setState({audioBlobData: value})}
                                            setCustomAudioLink={(value) => this.setState({customAudioLink: value})}
                                            setCustomAudioSecond={(value) => this.setState({customAudioSecond: value})}
                                            setDeleteServerAudio={(value) => this.setState({deleteServerAudio: value})}
                                            setSongSelected={(value) => this.setState({songSelected: value})}
                                            setIsUsingAudio={(value) => this.setState({isUsingAudio: value})}
                                        /> : null}

                                    {this.state.activeTab === 'introScreen' ?
                                        <IntroScreenEditComp
                                            eventType={this.state.eventType}
                                            weddingData={this.state.initialWeddingData}
                                            saveWeddingData={this.saveWeddingData}
                                            templateDetails={this.state.templateDetails}
                                            setTemplateDetails={(val) => this.setState({templateDetails: val})}
                                            audioLink={this.state.isUsingAudio && this.state.serverAudioUrl ? this.state.serverAudioUrl : ''}
                                            image1Link={this.state.image1Url ? `https://${process.env.REACT_APP_IMAGE_DOMAIN}${this.state.image1Url}` : ''}
                                            image2Link={this.state.image2Url ? `https://${process.env.REACT_APP_IMAGE_DOMAIN}${this.state.image2Url}` : ''}
                                            image3Link={this.state.image3Url ? `https://${process.env.REACT_APP_IMAGE_DOMAIN}${this.state.image3Url}` : ''}
                                            coverImageLink={this.state.coverImageUrl ? `https://${process.env.REACT_APP_IMAGE_DOMAIN}${this.state.coverImageUrl}` : ''}
                                            newImage1={this.state.newImage1}
                                            newImage2={this.state.newImage2}
                                            newImage3={this.state.newImage3}
                                            newCoverImage={this.state.newCoverImage}
                                            setNewImage1={(val) => {
                                                this.setState({newImage1: val})
                                            }}
                                            setNewImage2={(val) => {
                                                this.setState({newImage2: val})
                                            }}
                                            setNewImage3={(val) => {
                                                this.setState({newImage3: val})
                                            }}
                                            setNewCoverImage={(val) => {
                                                this.setState({newCoverImage: val})
                                            }}
                                            changeTypeToCustom={() => {
                                                this.setState({
                                                    activeTab: 'invitation',
                                                    templateDetails: null,
                                                    useNewInvite: false
                                                })
                                            }}
                                            updateLocations={(val) => this.setState({locations: val})}
                                        /> : null}

                                    {this.state.inviteType !== "" && this.state.activeTab === 'options' &&
                                        <div>
                                            <div className="Section">
                                                <div className="Title">Doriti sa primiti notificari
                                                    cand primiti raspuns?
                                                </div>

                                                <div className="Options-Row">
                                                    <div
                                                        className={this.state.isUsingNotifications ? "active-option" : "inactive-option"}
                                                        onClick={_ => this.setState({isUsingNotifications: true})}
                                                        style={{marginRight: "30px"}}>Da
                                                    </div>
                                                    <div
                                                        className={!this.state.isUsingNotifications ? "active-option" : "inactive-option"}
                                                        onClick={_ => this.setState({isUsingNotifications: false})}>Nu
                                                    </div>
                                                </div>
                                            </div>

                                            <CustomQuestions eventId={this.state.eventId}
                                                             questions={this.state.questions}
                                                             shouldUseQuestions={this.state.isUsingCustomQuestions}
                                                             canModifyQuestions={this.state.canModifyQuestions}
                                                             eventType={this.state.eventType}
                                                             changeUseQuestionsState={this.changeUseQuestionsState}
                                                             changeQuestions={this.changeQuestions}
                                                             language={this.state.language}
                                                             intl={this.props.intl}
                                                             hideDontKnowButton={this.state.hideDontKnowButton}
                                                             setHideDontKnowButton={(val) => this.setState({hideDontKnowButton: val})}
                                                             hideChildrenQuestion={this.state.hideChildrenQuestion}
                                                             setHideChildrenQuestion={(val) => this.setState({hideChildrenQuestion: val})}
                                                             hideConfirmForm={this.state.hideConfirmForm}
                                                             setHideConfirmForm={(val) => this.setState({hideConfirmForm: val})}
                                                             hideEmailInput={this.state.hideEmailInput}
                                                             setHideEmailInput={(val) => this.setState({hideEmailInput: val})}
                                            />

                                        </div>}

                                    {this.state.activeTab === 'options' || this.state.activeTab === 'introAnimation' || this.state.activeTab === 'introScreen' ?
                                    <div className={"edit-invite-main-buttons-cont"} style={{marginTop: '0.5rem', paddingTop: (this.state.activeTab === 'options' ? '1rem' : '0')}}>
                                        <div onClick={() =>
                                            this.setState({
                                                activeTab: 'invitation'
                                            }, () => {
                                                localStorage.setItem('edit_invite', this.state.initialWeddingData.id_eveniment)
                                                localStorage.setItem('edit_invite_tab', 'invitation')
                                                this.scrollContainerTop()
                                            })
                                        } className="eim-button yellow">
                                            Mergi la invitație
                                        </div>
                                    </div>
                                    :null}

                                    <div style={{paddingTop: '20px'}}></div>
                                </>
                        }


                    </div>
                </div>

                <InvitesModal
                    id={this.state.current_event.id_eveniment}
                    showModal={this.state.showSendModal}
                    toggleModal={() => {
                        this.setState({showSendModal: false})
                    }}
                    mire={this.state.current_event.prenumeMire}
                    mireasa={this.state.current_event.prenumeMireasa}
                    eventType={this.state.current_event.eventType}
                />


                <Modal classNames={{
                    overlay: 'modal-overlay',
                    modal: 'modal',
                    closeButton: 'modal-close-button',
                }} focusTrapped={false}
                       open={this.state.showDemoModal}
                       onClose={() => this.setState({showDemoModal: false})}>

                    <div>
                        <h3>Atenționare!</h3>
                        <br/>
                        Această funcție va fi disponibilă după ce vă creați un cont în aplicația
                        Ality.
                        <br/><br/>Modificările aduse invitației vor fi salvate și veți putea
                        continua de unde ați rămas.
                    </div>


                    <BackAndConfirmButtons textBack={"Înapoi"} textConfirm={"Creează cont"}
                                           functionBack={() => this.setState({showDemoModal: false})}
                                           functionConfirm={() => this.createInvite(false)}/>

                </Modal>

                {this.showBottomNav() ?
                    <InviteNavBar
                        active={this.state.activeTab}
                        changeTab={(tab) => {
                            this.setState({
                                activeTab: tab,
                                showChangeForm: false
                            })
                            localStorage.setItem('edit_invite', this.state.initialWeddingData.id_eveniment)
                            localStorage.setItem('edit_invite_tab', tab)
                            this.scrollContainerTop()
                        }}
                        showIntroAnimation={this.state.templateConfigValues.introAnimations}
                        showIntroScreen={this.state.templateConfigValues.introScreen}
                        showWalkthrough={this.state.showWalkthrough}
                        closeWalkthgrough={() => this.setState({
                            showWalkthrough: false
                        })}
                        walkthroughStep={this.state.walkthroughStep}
                        setWalkthroughStep={(step) => this.setState({walkthroughStep: step})}
                        eventId={this.state.initialWeddingData.id_eveniment}
                    />
                    :null}

            </div>
        )
    }

}

export default injectIntl(WebsiteInviteModule);
