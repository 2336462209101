import React, {lazy, Suspense, useEffect, useState} from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";

import {IntlProvider} from "react-intl";
import LanguageContext from "./contexts/LanguageContext";
import messages_ro from './translations/ro.json'
import messages_en from './translations/en.json'
import './App.css';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//Events
import Login from './Login/Login';
import Signup from './SignUp/Signup';
import EventPage from './secondaryPages/EventPage';
import EventPageWPreview from './organizer/secondaryPages/EventPage'
import InvitePage from './mainPages/InvitePage/InvitePage';

import Events from './mainPages/Events';
import MyEvents from './mainPages/MyEvents/MyEvents';
import Profile from './secondaryPages/ProfilePage/Profile';

import Resetpage from './mainPages/ResetPage';
import ConfirmMailPage from './mainPages/ConfirmMailPage';
import ConfirmEmailChangePage from './mainPages/ConfirmEmailChangePage';

import ResultsPage from './mainPages/FaceRecogResults/ResultsPage';

import PublicEvent from './mainPages/PublicEventPage/PublicEvent';

//Wedding
import EventPageW from './Wedding/secondaryPages/EventPage';
import MainInviteW from './Wedding/Invites/Template1/MainInvite';
import MainInvite2W from './Wedding/Invites/Template1/MainInvite2';

import SeatingChartW from './Wedding/secondaryPages/SeatingChart';
import HostessPageW from './Wedding/secondaryPages/hostessPage/HostessPage';
import IntermediatePageEvents from './secondaryPages/IntermediatePageEvents';
import axios from "axios";
import MobileModal from "./components/MobileModal";
import UpdateAppModal from "./components/UpdateAppModal";
import ViewEvents from "./organizer/mainPages/ViewEvents";
import CreateEvent from "./organizer/mainPages/CreateEvent/CreateEvent";
import AdminEventPage from "./organizer/secondaryPages/AdminEventPage/AdminEventPage";
import AdminSeatingChart from "./organizer/secondaryPages/AdminEventPage/SeatingChart/AdminSeatingChart";
import SeatingChart from "./organizer/secondaryPages/SeatingChart";
import Hostess from "./organizer/secondaryPages/AdminEventPage/Hostess";
import HostessPage from "./organizer/secondaryPages/hostessPage/HostessPage";
import CheckPresence from "./organizer/secondaryPages/checkPresence/CheckPresence";
import AddSaveTheDate from "./organizer/mainPages/AddSaveTheDate";
import EditSaveTheDate from "./organizer/mainPages/EditSaveTheDate";
import PreviewSaveTheDate from "./organizer/Invites/Template1/PreviewSaveTheDate";
import AddAlbum from "./organizer/mainPages/AddAlbum";
import EditAlbum from "./organizer/mainPages/EditAlbum";
import ViewAlbums from "./organizer/mainPages/ViewAlbums";
import AdminFeed from "./organizer/mainPages/AdminFeed";
import AddInvite from "./organizer/mainPages/AddInvite";
import EditInvite from "./organizer/mainPages/EditInvite/EditInvite";
import MainInvite2Preview from './organizer/Invites/Template1/MainInvite2';
import EditEvent from "./organizer/mainPages/EditEvent";
import PublishEvent from "./organizer/mainPages/PublishEvent";
import IntermediatePageOrganizer from "./organizer/secondaryPages/IntermediatePageOrganizer";
import GiftMoney from "./organizer/mainPages/GiftMoney/GiftMoney";
import EventInfoPage from "./organizer/mainPages/EventInfoPage/EventInfoPage";
import GuestInfoPage from "./organizer/mainPages/GuestInfoPage/GuestInfoPage";
//import ExtraServicesPage from "./organizer/mainPages/ExtraServicesPage/ExtraServicesPage";
import SetParticipantsTables
    from "./organizer/secondaryPages/AdminEventPage/SeatingChart/Components/SetParticipantsTables";
import SaveTheDateResponses from "./organizer/mainPages/Responses/SaveTheDateResponses";
import InviteResponses from "./organizer/mainPages/Responses/InviteResponses";
import AddResponse from "./organizer/mainPages/AddResponse/AddResponse";
import EditResponse from "./organizer/mainPages/EditResponse/EditResponse";
import AddMenu from "./organizer/mainPages/MenuPage/AddMenu";
import EditMenu from "./organizer/mainPages/MenuPage/EditMenu";
//import FaceRecog from "./organizer/secondaryPages/FaceRecog/FaceRecog";
import Exports from "./organizer/mainPages/ExportsPage/Exports";
import ContactUs from "./organizer/mainPages/ContactUs/ContactUs";
//import Testimonials from "./organizer/secondaryPages/Testimonials/Testimonials";
//import TestimonialsConfig from "./organizer/secondaryPages/Testimonials/TestimonialsConfig";
//import TestimonialsListItems from "./organizer/secondaryPages/Testimonials/TestimonialsListItems";
import Administrators from "./organizer/secondaryPages/AdminEventPage/Administrators";
import AdminInvite from "./organizer/mainPages/AdminInvite/AdminInvite";
import HostessInvite from "./organizer/mainPages/HostessInvite/HostessInvite";
import PaymentConfirm from "./organizer/mainPages/Payment/PaymentConfirm";
import CustomTransaction from "./organizer/mainPages/CustomTransaction/CustomTransaction";
import NoPremiumPackage from "./organizer/mainPages/NoPremiumPackage";
import WebsiteInviteModule from "./organizer/mainPages/WebsiteInviteModule";
import WebsiteInviteModulePreview from "./organizer/mainPages/WebsiteInviteModulePreview";
import WebsiteInviteModuleDemo from "./organizer/mainPages/WebsiteInviteModuleDemo";
import UploadExcelResponses from "./organizer/mainPages/UploadExcelResponses/UploadExcelResponses";
import FeedbackForm from "./mainPages/FeedbackForm/FeedbackForm";
import MyTickets from "./secondaryPages/ProfilePage/MyTickets/MyTickets";
import MyInvites from "./secondaryPages/ProfilePage/MyInvites/MyInvites";
import EditProfile from "./secondaryPages/ProfilePage/EditProfile";
import moment from "moment-timezone";
import DownloadTicket from "./mainPages/DownloadTicket";
import DownloadInvite from "./mainPages/DownloadInvite";
import QrCheckerInvite from "./secondaryPages/QrCheckerInvite/QrCheckerInvite";
import FaceRecogResults from "./secondaryPages/FaceRecogResults/FaceRecogResults";
import GuestUnsubscribe from "./mainPages/GuestUnsubscribe/GuestUnsubscribe";
import {GoogleOAuthProvider} from '@react-oauth/google';
import {GOOGLE_LOGIN_CLIENT_ID} from "./const";
import {isJsonString} from "./functions";
import CacheBusterComp from "./components/CacheBusterComp/CacheBusterComp";
import ExportInviteJpg from "./dev/ExportInviteJpg";
import Loader from "./components/Loader";
import NotificationsScreen from "./mainPages/NotificationsScreen/NotificationsScreen";
import Packages from "./organizer/mainPages/Packages/Packages";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import WeddingParticipantsPhotos
    from "./mainPages/ParticipantsPhotos/WeddingParticipantsPhotos/WeddingParticipantsPhotos";
import WeddingParticipantsPhotosUpload
    from "./mainPages/ParticipantsPhotos/WeddingParticipantsPhotos/WeddingParticipantsPhotosUpload";
import ThankYouRegisterPage from "./Login/secondaryPages/ThankYouRegisterPage/ThankYouRegisterPage";

const PlanRouter = lazy(() => import("./organizer/mainPages/plan/PlanRouter"))
//const MemoryBoothRouter = lazy(() => import("./organizer/mainPages/MemoryBoothRouter"))
const QrScanRouter = lazy(() => import("./secondaryPages/qrscan/QrScanRouter"))

moment.tz.setDefault('Europe/Bucharest')


function App() {

    const [currentLanguage, setCurrentLanguage] = useState(localStorage.getItem('language') ? localStorage.getItem('language') : 'ro')
    const [unreadNotifications, setUnreadNotifications] = useState(0)

    const messages = {
        'ro': messages_ro,
        'en': messages_en
    };

    [window, document].forEach(
        el => el.addEventListener('message', (message) => {

            let data = message.data && isJsonString(message.data) && JSON.parse(message.data) ? JSON.parse(message.data) : false

            if (data) {
                // eslint-disable-next-line
                switch (data.action) {
                    case 'addToCalendarSuccess':
                        toast.success('Evenimentul a fost adăugat în calendar!')
                        break
                    case 'addToCalendarError':
                        toast.error('Evenimentul nu a putut fi adăugat în calendar!')
                        break
                    case 'downloadMediaSuccess':
                        toast.success('Fișierul a fost descărcat!')
                        break
                    case 'downloadMediaError':
                        toast.success('Aplicația nu are permisiune pentru a descărca fișierul!')
                        break
                    case 'registerPushToken':
                        registerPushToken(data.token)
                        break
                    case 'unregisterPushToken':
                        unregisterPushToken()
                        break
                }
            }

        }))

    const registerPushToken = async (token) => {

        if (localStorage.getItem('jwt') !== null) {
            let data = {
                token: token
            }

            let auth = {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('jwt')
                }
            }

            axios.post(process.env.REACT_APP_DOMAIN + 'utils/storePushToken', data, auth)
        }
    }

    const unregisterPushToken = async () => {
        if (localStorage.getItem('jwt') !== null) {
            let auth = {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('jwt')
                }
            }

            axios.post(process.env.REACT_APP_DOMAIN + 'utils/unregisterPushToken', {}, auth)
        }
    }

    setTimeout(() => {
        if (window.alityAppView) {
            document.body.classList.add('body-webview')
        }
    }, 1000)

    setTimeout(() => {
        if (window.alityAppView) {
            document.body.classList.add('body-webview')
        }
    }, 2000)

    setTimeout(() => {
        if (window.alityAppView) {
            document.body.classList.add('body-webview')
        }
    }, 3000)

    const changeLanguage = (lang) => {
        setCurrentLanguage(lang)
        localStorage.setItem('language', lang)
    }

    const mainContext = {
        language: currentLanguage,
        setLanguage: changeLanguage,
        unreadNotifications: unreadNotifications,
        setUnreadNotifications: setUnreadNotifications
    };

    useEffect(() => {

        localStorage.setItem('first_launch', '1')
        localStorage.removeItem('notifications_check_date')

        const search = window.location.search;
        const params = new URLSearchParams(search);

        let requestedLang = params.get('applang')
        if (requestedLang && requestedLang === 'en') {
            setCurrentLanguage('en')
        }
        if (requestedLang && requestedLang === 'ro') {
            setCurrentLanguage('ro')
        }

    }, [])

    return (
        <CacheBusterComp>
            {({loading, isLatestVersion, refreshCacheAndReload}) => {
                if (loading) return null;
                if (!loading && !isLatestVersion) {
                    // You can decide how and when you want to force reload the app
                    refreshCacheAndReload();
                }
                return (<LanguageContext.Provider value={mainContext}>
                    <div className="App font-family-poppins-300">
                        <GoogleOAuthProvider clientId={GOOGLE_LOGIN_CLIENT_ID}>
                            <IntlProvider locale={currentLanguage} defaultLocale="ro"
                                          messages={messages[currentLanguage]}>
                                <ErrorBoundary>
                                    <Router>

                                        {/*<ErrorBoundary>*/}
                                        <ToastContainer/>

                                        <Switch>
                                            {/* Events */}
                                            <Route exact path="/" component={Events}/>
                                            {/* <Route exact path="/organizer" component={AppsOrganizer} /> */}


                                            {/* Organizer */}
                                            <Route exact path="/organizer/viewEvents" component={ViewEvents}/>
                                            <Route exact path="/organizer/addEvent" component={CreateEvent}/>
                                            <Route exact path="/organizer/adminEventPage/:id"
                                                   component={AdminEventPage}/>
                                            <Route exact path="/organizer/eventPage/:id" component={EventPageWPreview}/>


                                            <Route exact path="/organizer/adminSeatingChart/:id"
                                                   component={AdminSeatingChart}/>


                                            <Route exact path="/organizer/seatingChart/:id" component={SeatingChart}/>
                                            <Route exact path="/organizer/hostessInvites/:id" component={Hostess}/>
                                            <Route exact path="/organizer/hostessChoose/:id" component={HostessPage}/>
                                            <Route exact path="/organizer/checkPresence/:id" component={CheckPresence}/>
                                            <Route exact path="/organizer/addSaveTheDate/:id"
                                                   component={AddSaveTheDate}/>
                                            <Route exact path="/organizer/editSaveTheDate/:id"
                                                   component={EditSaveTheDate}/>
                                            <Route exact path="/organizer/previewSaveTheDate/:id"
                                                   component={PreviewSaveTheDate}/>
                                            <Route exact path="/organizer/addAlbum/:id" component={AddAlbum}/>
                                            <Route exact path="/organizer/editAlbum/:id/:idAlbum"
                                                   component={EditAlbum}/>
                                            <Route exact path="/organizer/viewAlbums/:id" component={ViewAlbums}/>


                                            <Route exact path="/organizer/adminFeed/:id" component={AdminFeed}/>

                                            <Route exact path="/organizer/addInvite/:id" component={AddInvite}/>
                                            <Route exact path="/organizer/editInvite/:id" component={EditInvite}/>
                                            <Route exact path="/organizer/editInvite/:id/:new" component={EditInvite}/>
                                            <Route exact path="/organizer/websiteInviteModule"
                                                   component={WebsiteInviteModule}/>
                                            <Route exact path="/organizer/websiteInviteModulePreview/:id"
                                                   component={WebsiteInviteModulePreview}/>
                                            <Route exact path="/organizer/websiteInviteModuleDemo"
                                                   component={WebsiteInviteModuleDemo}/>
                                            <Route exact path="/organizer/invitationPreview/:id"
                                                   component={MainInvite2Preview}/>
                                            <Route exact path="/organizer/invitationPreview/:id/:secondaryInviteId"
                                                   component={MainInvite2Preview}/>

                                            <Route exact path="/organizer/editEvent/:id" component={EditEvent}/>
                                            <Route exact path="/organizer/publishEvent/:id" component={PublishEvent}/>

                                            <Route exact path="/organizer/pageSelect"
                                                   component={IntermediatePageOrganizer}/>

                                            {/*
                                        <Route exact path="/organizer/plan/:id" component={Planning}/>
                                        <Route exact path="/organizer/plan/tasks/:id" component={TasksPage}/>
                                        <Route exact path="/organizer/plan/buget/:id" component={Budget}/>
                                        <Route exact path="/organizer/plan/gifts/:id" component={Gifts}/>
                                        <Route exact path="/organizer/plan/adminDaySchedule/:id"
                                               component={AdminDaySchedule}/>
                                               */}

                                            <Route path={"/organizer/plan"} component={PlanRouter}>
                                                <Suspense fallback={<Loader/>}>
                                                    <PlanRouter/>
                                                </Suspense>
                                            </Route>

                                            <Route exact path="/organizer/dar/:id" component={GiftMoney}/>

                                            <Route exact path="/organizer/info/:id" component={EventInfoPage}/>
                                            <Route exact path="/organizer/guests/:id" component={GuestInfoPage}/>

                                            {/*
                                               <Route exact path="/organizer/extraServices/:id"
                                                   component={ExtraServicesPage}/>
                                         <Route exact path="/organizer/faceRecog/:id" component={FaceRecog}/>
                                             <Route exact path="/organizer/testimonials/main/:id" component={Testimonials}/>
                                        <Route exact path="/organizer/testimonials/config/:id"
                                               component={TestimonialsConfig}/>
                                        <Route exact path="/organizer/testimonials/listItems/:id"
                                               component={TestimonialsListItems}/>

                                        <Route path={"/organizer/memorybooth"} component={MemoryBoothRouter}>
                                            <Suspense fallback={<Loader/>}>
                                                <MemoryBoothRouter/>
                                            </Suspense>
                                        </Route>
                                        */}

                                            <Route exact path="/organizer/seatParticipants/:id"
                                                   component={SetParticipantsTables}/>

                                            <Route exact path="/organizer/raspunsuriSaveTheDate/:id"
                                                   component={SaveTheDateResponses}/>

                                            <Route exact path="/organizer/raspunsuriInvitati/:id"
                                                   component={InviteResponses}/>
                                            <Route exact path="/organizer/adaugaRaspuns/:id" component={AddResponse}/>
                                            <Route exact path="/organizer/editeazaRaspuns/:id/:idResponse"
                                                   component={EditResponse}/>
                                            <Route exact path="/organizer/uploadExcelResponses/:id"
                                                   component={UploadExcelResponses}/>

                                            <Route exact path="/organizer/addMenu/:id" component={AddMenu}/>
                                            <Route exact path="/organizer/addMenu/:id" component={AddMenu}/>
                                            <Route exact path="/organizer/editMenu/:id" component={EditMenu}/>


                                            <Route exact path="/organizer/exports/:id" component={Exports}/>
                                            <Route exact path="/organizer/contact_us" component={ContactUs}/>
                                            <Route exact path="/organizer/contact_us/:id" component={ContactUs}/>
                                            <Route exact path="/organizer/package/:id" component={Packages}/>
                                            <Route exact path="/organizer/packages/:id" component={Packages}/>

                                            <Route exact path="/organizer/participants_photos/:id"
                                                   component={WeddingParticipantsPhotos}/>
                                            <Route exact path="/wedding_participants_photos_upload/:id"
                                                   component={WeddingParticipantsPhotosUpload}/>


                                            <Route exact path="/dev/export_invite/:id" component={ExportInviteJpg}/>
                                            <Route exact path="/dev/export_invite/:id/:additional_invite_id"
                                                   component={ExportInviteJpg}/>


                                            <Route exact path="/organizer/administrators/:id"
                                                   component={Administrators}/>
                                            <Route exact path="/organizer/adminInvite/:uid" component={AdminInvite}/>
                                            <Route exact path="/organizer/hostessInvite/:uid"
                                                   component={HostessInvite}/>

                                            <Route exact path="/organizer/afterPayment" component={PaymentConfirm}/>
                                            <Route exact path="/organizer/payCustomTransaction/:id"
                                                   component={CustomTransaction}/>

                                            {/* Tutorials */}
                                            {/*
				<Route exact path="/tutorials/saveTheDate" component={SaveTheDateTutorial}/>
				<Route exact path="/tutorials/invite" component={InviteTutorial}/>
				<Route exact path="/tutorials/albums" component={AlbumsTutorial}/>
				<Route exact path="/tutorials/menu" component={MenuTutorial}/>
				<Route exact path="/tutorials/feed" component={FeedTutorial}/>
				<Route exact path="/tutorials/tasks" component={TasksTutorial}/>
				<Route exact path="/tutorials/budget" component={BudgetTutorial}/>
				<Route exact path="/tutorials/gifts" component={GiftsTutorial}/>
				<Route exact path="/tutorials/seatingChart" component={SeatingChartTutorial}/>
				<Route exact path="/tutorials/saveTheDateResponses" component={SaveTheDateResponsesTutorial}/>
				<Route exact path="/tutorials/inviteResponses" component={InviteResponsesTutorial}/>
				<Route exact path="/tutorials/seatParticipants" component={SeatParticipantsTutorial}/>
				<Route exact path="/tutorials/hostess" component={HostessTutorial}/>
				<Route exact path="/tutorials/checkPresence" component={CheckPresenceTutorial}/>
				<Route exact path="/tutorials/giftMoney" component={GiftMoneyTutorial}/>
				<Route exact path="/tutorials/export" component={ExportTutorial}/>
				<Route exact path="/tutorials/contactUs" component={ContactUsTutorial}/>
				<Route exact path="/tutorials/administrators" component={AdministratorsTutorial}/>
				*/}
                                            <Route exact path="/organizer/noPremiumPackage/:id"
                                                   component={NoPremiumPackage}/>


                                            {/* Events */}
                                            {/*<Route exact path="/news" component={News}/> */}
                                            <Route exact path="/events/:searchQuery?/:category?" component={Events}/>
                                            <Route exact path="/organizer" component={Events}/>
                                            <Route exact path="/myEvents" component={MyEvents}/>
                                            <Route exact path="/profile" component={Profile}/>
                                            <Route exact path="/mytickets" component={MyTickets}/>
                                            <Route exact path="/myinvites" component={MyInvites}/>
                                            <Route exact path="/editProfile" component={EditProfile}/>

                                            <Route exact path="/selectPage" component={IntermediatePageEvents}/>

                                            {/* <Route exact path="/invitationEvent/:id" component={MainInvite2} /> */}

                                            <Route exact path="/eventPage/:id" component={EventPage}/>
                                            <Route exact path="/eventPage/:id/:tab" component={EventPage}/>

                                            <Route exact path="/publicEvent/:id" component={PublicEvent}/>
                                            <Route exact path="/publicEvent/:id/:tab" component={PublicEvent}/>

                                            <Route path={"/qrscan"} component={QrScanRouter}>
                                                <Suspense fallback={<Loader/>}>
                                                    <QrScanRouter/>
                                                </Suspense>
                                            </Route>
                                            <Route exact path="/invite/:idParticipant/:username/:identifier/:template"
                                                   component={InvitePage}/>

                                            <Route exact path="/login" component={Login}/>
                                            <Route exact path="/signup" component={Signup}/>
                                            <Route exact path="/signup-thank-you" component={ThankYouRegisterPage}/>

                                            <Route exact path="/resetPassword/:id" component={Resetpage}/>
                                            <Route exact path="/confirm-email/:id" component={ConfirmMailPage}/>
                                            <Route exact path="/confirm-email-change/:id"
                                                   component={ConfirmEmailChangePage}/>

                                            <Route exact path="/results/:eventID/:requestID" component={ResultsPage}/>

                                            {/* Wedding */}
                                            {/* <Route exact path="/addInviteCode" component={ScanQR} /> */}
                                            <Route exact path="/eventPageW/:id" component={EventPageW}/>

                                            <Route exact path="/saveTheDate/:id" component={MainInviteW}/>
                                            <Route exact path="/invitation/:id" component={MainInvite2W}/>
                                            <Route exact path="/invitation/:id/:secondaryInviteId"
                                                   component={MainInvite2W}/>

                                            <Route exact path="/hostessMap/:id" component={HostessPageW}/>
                                            <Route exact path="/seatingChart/:id" component={SeatingChartW}/>

                                            <Route exact path="/feedbackForm" component={FeedbackForm}/>
                                            <Route exact path="/facebookForm" component={FeedbackForm}/>
                                            <Route exact path="/organizer/facebookForm" component={FeedbackForm}/>
                                            <Route exact path="/guestUnsubscribe/:id" component={GuestUnsubscribe}/>

                                            <Route exact path="/downloadTicket/:code" component={DownloadTicket}/>
                                            <Route exact path="/downloadInvite/:code" component={DownloadInvite}/>

                                            <Route exact path="/notifications" component={NotificationsScreen}/>

                                            <Route exact path="/qrCheckerInvite/:code" component={QrCheckerInvite}/>
                                            <Route exact path="/faceRecogResults/:eventID/:requestID"
                                                   component={FaceRecogResults}/>
                                            <Route exact path="/public/:slug" component={PublicEvent}/>
                                            <Route exact path="/public/:slug/:tab" component={PublicEvent}/>
                                            <Route exact path="/:slug" component={EventPage}/>
                                            <Route exact path="/:slug/:tab" component={EventPage}/>

                                        </Switch>

                                        <MobileModal/>
                                        <UpdateAppModal/>
                                        {/*</ErrorBoundary>*/}
                                    </Router>
                                </ErrorBoundary>
                            </IntlProvider>
                        </GoogleOAuthProvider>
                    </div>
                </LanguageContext.Provider>)
            }}
        </CacheBusterComp>
    );
}

export default App;
