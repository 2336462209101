import React from 'react'
import './ViewEvents.scss'
import NavbarColor from '../../components/NavbarColor'
import EventCard from './ViewEvents/EventCard'
import axios from 'axios'
import { toast } from 'react-toastify'
import { ConfirmButton, ShareModal } from '../components/Helper'

import Loader from "../components/Loader";
import HeaderWhite from "../../components/HeaderWhite";
import {Button, Icon, Input} from "rsuite";
import {
	getLanguageFlag,
	getMaxInvitesNumber,
	getWeddingFirstDate,
	getWeddingTitle
} from "../../functions";
import SendInviteModal from "../../components/SendInviteModal/SendInviteModal";
import SendSaveTheDateModal from "../../components/SendSaveTheDateModal/SendSaveTheDateModal";
import Modal from 'react-responsive-modal'
import {BackAndConfirmButtons} from "../../components/Helper";
import SettingCard from "./Profile/components/SettingCard";
import SVG_Invite from "../assets/EventInfoSVG/invitatii-icon.svg";
import CustomInput from "../../components/custom/CustomInput";
import logo from "../../assets/logo.png";
import LanguageContext from "../../contexts/LanguageContext";
import {injectIntl} from 'react-intl';

class ViewEvents extends React.Component {
	static contextType = LanguageContext

	state = {
		events: [],
		dispEvents: [],

		showFinishedEvents: false,
		eventsTypes: ["Toate", "Nunta", "Botez", "Mot", "Turta", "Petrecere", "Cununie"],
		eventTypeChoice: "Toate",
		statusFilter: 'free',

		showShareModal: false,
		linkToShare: "",
		linkCategory: "",
		showSearch: false,
		search: '',
		showWalkthrough: false,
		showWalkthroughId: 0,

		showSendInviteModal: false,
		showSendSaveTheDateModal: false,
		showWhatToSendModal: false,
		shareEvent: null,
		selectedInviteId: 0,
		maxInvitesNumber: 1,

		showPhoneNumberModal: false,
		showPhoneNumberDiscountCodeModal: false,
		phone: '',
		showLanguageTempModal: this.context.language !== 'ro',
		loading: true
	}

	params = new URLSearchParams(window.location.search)

	componentDidMount() {
		this.init()
	}

	init = async () => {
		/*
		if (this.props.location.search !== "") {
			let jwt = this.props.location.search.split("?")[1]

			if (jwt === "null") {
				localStorage.clear();
			} else {
				localStorage.setItem("jwt", this.props.location.search.split("?")[1])
			}
		}*/

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		if (this.props.location.search !== "" && localStorage.getItem("id") === null) {
			 axios.get(process.env.REACT_APP_DOMAIN + 'users/me', auth)
				.then(response => {
					localStorage.setItem("id", response.data.id)
					localStorage.setItem("rememberMe", "remember")
				})
		}

		if(localStorage.getItem('jwt') && localStorage.getItem('jwt').length > 0) {
			 axios.get(process.env.REACT_APP_DOMAIN + 'users/checkPhoneNumber', auth)
				.then(response => {
					if (response && response.data && response.data.status === 0) {
						this.setState({showPhoneNumberModal: true})
					}
				})
		}

		await axios.get(process.env.REACT_APP_DOMAIN + 'weddings/list/myWeddings', auth)
			.then(response => {

				let responses_count = 0
				let newEvents = response.data.map((event, index) => {
					event.eventIndex = response.data.length - index - 1
					responses_count += event.responses_count ? event.responses_count : 0

					event.dataEvent = getWeddingFirstDate(event).date.toISOString()

					return event
				})

				newEvents = newEvents.sort(function(a,b){
					return new Date(a.dataEvent) - new Date(b.dataEvent);
				});

				if(responses_count > 0) {
					if (window.alityAppView) {
						window.ReactNativeWebView.postMessage(JSON.stringify({
							action: 'askForReview'
						}))
					}
				}

				this.setState({
					events: newEvents
				}, () => {

					let checkEvents = newEvents.filter(event => event.packages && event.packages.wedding_participants && event.packages.wedding_participants.package)
					if(checkEvents && checkEvents.length > 0) {
						this.changeStatusFilter('paid')
					} else {
						this.changeStatusFilter('free')
					}

					if(this.params && this.params.get('finishWalkthrough')) {

						if(newEvents.length === 1) {
							this.setState({
								showWalkthrough: true,
								showWalkthroughId: this.params.get('finishWalkthrough'),
								loading: false
							})
						} else {
							this.setState({
								loading: false
							})
						}

						this.finishWalkthrough(this.params.get('finishWalkthrough'))
					} else {
						this.setState({
							loading: false
						})
					}
				})
			})
			.catch(err => {

				if(err && err.response && err.response.status === 401) {
					localStorage.clear()
					toast.error("Informațiile de autentificare au expirat, vă rugăm să vă autentificați!")
					this.props.history.push('/login');
				} else {
					toast.error('A apărut o eroare! Vă rugăm reîncercați!')
				}
			})
	}

	finishWalkthrough = async (id_eveniment) => {
		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		await axios.post(process.env.REACT_APP_DOMAIN + 'weddings/update/tutorials/' + id_eveniment,
			{
				walkthrough: true
			},
			auth)
	}

	changeStatusFilter = (status, type = this.state.eventTypeChoice) => {

		let newDipsEvents = [...this.state.events]

		if(status === 'free') {
			newDipsEvents = newDipsEvents.filter(event => (!event.packages || !event.packages.wedding_participants || !event.packages.wedding_participants.package) && !!!event.isFinished === true)
		}
		if(status === 'paid') {
			newDipsEvents = newDipsEvents.filter(event => (event.packages && event.packages.wedding_participants && event.packages.wedding_participants.package) && !!!event.isFinished === true)
		}
		if(status === 'finished') {
			newDipsEvents = newDipsEvents.filter(event => !!event.isFinished === true)
		}

		if (type !== "Toate") {
			newDipsEvents = newDipsEvents.filter(event => event.eventType === type)
		}

		this.setState({
			statusFilter: status,
			eventTypeChoice: type,
			dispEvents: newDipsEvents
		})
	}


	changeFinishedStatus = (index) => {
		let currentStatus = this.state.dispEvents[index].isFinished

		//change server status
		let obj = {
			isFinished: !currentStatus
		}

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		axios.put(process.env.REACT_APP_DOMAIN + 'weddings/' + this.state.dispEvents[index].id, obj, auth)
			.then(_ => {
				if (!currentStatus) {
					toast.success("Eveniment trecut la Șterse")
				} else {
					toast.success("Eveniment trecut la Active")
				}

				this.changeStatusFilter(this.state.statusFilter, this.state.eventTypeChoice)

				this.init()

			})
			.catch(err => {
				toast.error("Eroare, va rog reincercati")
			})
	}

	deleteEvent = (index) => {

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		axios.delete(process.env.REACT_APP_DOMAIN + 'weddings/deleteEvent/' + this.state.dispEvents[index].id, auth)
			.then(_ => {

				toast.success("Evenimentul a fost șters!")

				this.changeStatusFilter(this.state.statusFilter,this.state.eventTypeChoice)

				this.init()

			})
			.catch(err => {
				toast.error("Eroare, va rog reincercati")
			})
	}

	moveToEvent = (id) => {
		this.props.history.push('/organizer/adminEventPage/' + id)
	}

	goBack = () => {
		this.props.history.go(-1)
	}

	openShareModal = (data, type) => {

		let inviteCreated = !!data.invitatieInfo
		let saveTheDateCreated = !!data.saveTheDateInfo

		this.setState({
			shareEvent: data,
			selectedInviteId: 0,
			showWhatToSendModal:true,
			inviteCreated: inviteCreated,
			saveTheDateCreated: saveTheDateCreated,
			maxInvitesNumber: getMaxInvitesNumber(data),
		})

		/*
		if(!inviteCreated && !saveTheDateCreated) {
			toast.error('Pentru a distribui evenimentul trebuie să creați invitația!')
		}

		if(inviteCreated && !saveTheDateCreated) {
			this.setState({
				shareEvent: data,
				showSendInviteModal:true
			})
		}
		if(!inviteCreated && saveTheDateCreated) {
			this.setState({
				shareEvent: data,
				showSendSaveTheDateModal:true
			})
		}
		if(inviteCreated && saveTheDateCreated) {
			this.setState({
				shareEvent: data,
				showWhatToSendModal:true
			})
		}
*/
		/*
		let link = ""
		let category = ""

		if (type === "event") {
			link = data.title

			let categoryFound = false
			//look in public events type
			for (let i = 0; i < this.state.publicEvents.length; i++) {
				if (data.tip_eveniment === filterToType[this.state.publicEvents[i]]) {
					category = "Pu"
					categoryFound = true
					break
				}
			}

			if (!categoryFound) {
				//look in special events type
				for (let i = 0; i < this.state.specialEvents.length; i++) {
					if (data.tip_eveniment === filterToType[this.state.specialEvents[i]]) {
						category = "Sp"
						categoryFound = true
						break
					}
				}
			}

			if (!categoryFound) {
				//look in corporate events type
				for (let i = 0; i < this.state.corporateEvents.length; i++) {
					if (data.tip_eveniment === filterToType[this.state.corporateEvents[i]]) {
						category = "Co"
						categoryFound = true
						break
					}
				}
			}
		}

		if (type === "wedding") {
			category = "Pr"

			link = data.eventType + " "

			if (data.eventType === "Nunta") {
				link += data.prenumeMireasa + " si " + data.prenumeMire
			}

			if (data.eventType === "Botez") {
				link = 'Botez ' + data.prenumeMire
			}

			if (data.eventType === "Petrecere") {
				link = 'Aniversare ' + data.prenumeMire
			}
		}

		if (window.flutter_inappwebview) {
			window.flutter_inappwebview.callHandler('shareEvent', `${process.env.REACT_APP_PAGE}events/${encodeURI(link)}/${category}`)
			return
		}

		if (window.alityAppView) {
			window.ReactNativeWebView.postMessage(JSON.stringify({
				action: 'shareUrl',
				url: `${process.env.REACT_APP_PAGE}events/${encodeURI(link)}/${category}`,
			}))
			return
		}

		this.setState({
			showShareModal: true,
			linkToShare: encodeURI(link),
			linkCategory: category
		})

		 */
	}

	closeShareModal = () => {
		this.setState({
			showShareModal: false
		})
	}

	updatePhoneNumber = async () => {

		//Validate phone number
		if(this.state.phone.length < 10 || (!(parseInt(this.state.phone) > 0))) {
			toast.error('Numărul de telefon nu este valid!')
			return
		}

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		let obj = {
			phone: this.state.phone
		}

		await axios.put(process.env.REACT_APP_DOMAIN + 'user/updatePhoneNumber', obj, auth)
			.then((response) => {
				toast.success("Vă mulțumim!")

				this.setState({showPhoneNumberModal: false, showPhoneNumberDiscountCodeModal: true})
			})
			.catch(_ => {
				toast.error("S-a produs o eroare va rog reincercati")
			})
	}

	skipPhoneNumber = async () => {

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		let obj = {
			skip_phone_number: true
		}

		await axios.put(process.env.REACT_APP_DOMAIN + 'user/updatePhoneNumber', obj, auth)
			.then((response) => {
				toast.success("Vă mulțumim!")
				this.setState({showPhoneNumberModal: false})
			})
			.catch(_ => {
				toast.error("S-a produs o eroare va rog reincercati")
			})
	}

	render() {
		if (this.state.loading === true)
			return (
				<div className="ViewEvents-container">
					<HeaderWhite />

					<h4 className="title">Evenimente create de mine</h4>


					<div className="ViewEvents-contents">
						<Loader/>
					</div>

					<NavbarColor history={this.props.history} active={"My_Created_Events"} />
				</div>
			)

		// eslint-disable-next-line
		let cards = this.state.dispEvents.map((event, index) => {
			if(this.state.search.length > 0) {
				if(getWeddingTitle(event).toLowerCase().includes(this.state.search.toLowerCase())){
					return <EventCard
						customClass={event.id_eveniment === this.state.showWalkthroughId ? 'walkthrough-active' :''}
						openShareModal={this.openShareModal}
						moveToEvent={this.moveToEvent} key={index}
						event={event} changeFinishedStatus={() => this.changeFinishedStatus(index)}
						deleteEvent={() => this.deleteEvent(index)} />
				}
			} else
			return <EventCard
				customClass={event.id_eveniment === this.state.showWalkthroughId ? 'walkthrough-active' :''}
				openShareModal={this.openShareModal}
				moveToEvent={this.moveToEvent} key={index} event={event}
				changeFinishedStatus={() => this.changeFinishedStatus(index)}
				deleteEvent={() => this.deleteEvent(index)} />
		})

		if (this.state.events.length === 0) {
			cards = <div>
				<div style={{margin: "20px 0px"}}>Nu aveți evenimente create</div>

				<ConfirmButton style={{width: "160px"}} function={() => {this.props.history.push('/organizer/addEvent')}} text={"Creeaza eveniment"} />
			</div>
		}

		return (
			<div className="ViewEvents-container">
				<HeaderWhite/>

				{this.state.showSearch
					? <h4 className="title">
						<Icon className="icon-left" icon="search" color="white" />

						<Input className="search-bar" value={this.state.searchInput} onChange={(val) => this.setState({search: val})} placeholder={"Introduceti numele evenimentului"} />

						<Icon className="icon-right" icon="close" style={{ color: "white", width: "20px", marginRight:'10px' }} onClick={() => this.setState({showSearch:false})} />
					</h4>
					: <h4 className="title">
						Evenimente create de mine


						<div className={"search-box"}  onClick={() => this.setState({showSearch:true})}>
							<Icon className="search-icon" icon="search" style={{ color: "white", width: "20px" }} />
							<span className={"search-text"}>Caută</span>
						</div>
					</h4>
				}

				<div className="filters">
					<div
						className={this.state.statusFilter === 'paid' ? "Events-Status-Choice" : "Events-Status-Option"}
						onClick={() => this.changeStatusFilter('paid')}>
						Plătite
					</div>

					<div
						className={this.state.statusFilter === 'free' ? "Events-Status-Choice" : "Events-Status-Option"}
						onClick={() => this.changeStatusFilter('free')}>
						Gratuite
					</div>

					<div
						className={this.state.statusFilter === 'finished' ? "Events-Status-Choice" : "Events-Status-Option"}
						onClick={() => this.changeStatusFilter('finished')}>
						Șterse
					</div>
				</div>

				<div className="filters2">
					{this.state.eventsTypes.map((type, index) => {
						return <div key={index}
									className={(this.state.eventTypeChoice === type || (this.state.eventTypeChoice === 'Petrecere' && type === 'Aniversare')) ? "Events-Status-Choice" : "Events-Status-Option"}
									onClick={() => this.changeStatusFilter(this.state.statusFilter, type)}>

							{this.props.intl.formatMessage({id: 'events.subtypes.' + type})}
						</div>
					})}
				</div>

				<div className="ViewEvents-contents">
					<div>
						{cards}
					</div>
				</div>

				<ShareModal link={this.state.linkToShare} showModal={this.state.showShareModal} closeModal={this.closeShareModal} linkCategory={this.state.linkCategory} />

				{
					this.state.shareEvent ?
						<>

							<SendInviteModal
								showModal={this.state.showSendInviteModal}
								setShowModal={(value) => this.setState({showSendInviteModal: value})}
								event={this.state.shareEvent}
								selectedInviteId={this.state.selectedInviteId}
							/>


							<SendSaveTheDateModal
								showModal={this.state.showSendSaveTheDateModal}
								setShowModal={(value) => this.setState({showSendSaveTheDateModal: value})}
								event={this.state.shareEvent}
							/>

							<Modal classNames={{
								overlay: 'modal-overlay',
								modal: 'helper-invite-modal',
								closeButton: 'modal-close-button',
							}} open={this.state.showWhatToSendModal} onClose={() => this.setState({showWhatToSendModal: false})} focusTrapped={false}>
								<div className="modal-content">
									<div style={{ marginTop: "10px", maginBottom:'30px' }}>

										{this.state.shareEvent.eventType === "Nunta" && <SettingCard svg={SVG_Invite} onClick={() => {
											if(!this.state.saveTheDateCreated) {
												this.props.history.push('/organizer/addSaveTheDate/' + this.state.shareEvent.id_eveniment)
											}
										}} name="Save the date" />}

										{this.state.saveTheDateCreated && <div>
											<SettingCard icon="" onClick={() => this.props.history.push('/organizer/editSaveTheDate/' + this.state.shareEvent.id_eveniment)} name="Editează" isSmall={true} />

											<SettingCard icon="" onClick={() => this.props.history.push('/organizer/previewSaveTheDate/' + this.state.shareEvent.id_eveniment)} name="Previzualizează" isSmall={true} />

											<SettingCard icon="" onClick={() => {
												this.setState({
													showSendSaveTheDateModal:true
												})
											}} name="Trimite" isSmall={true} />

											<SettingCard icon="" onClick={() => this.props.history.push('/organizer/raspunsuriSaveTheDate/' + this.state.shareEvent.id_eveniment)} name="Răspunsuri" isSmall={true} />

										</div>}

										<SettingCard svg={SVG_Invite} onClick={() => {
											if(!this.state.inviteCreated) {
												this.props.history.push('/organizer/editInvite/' + this.state.shareEvent.id_eveniment)
											}
										}} name="Invitație" />

										{
											this.state.shareEvent && this.state.shareEvent.additional_invites.length > 0 ?
												<div className={"invites-selection-cont"}>
													<div className={"invite-item " + (this.state.selectedInviteId === 0 ? 'active' : '')}
														 onClick={() => this.setState({selectedInviteId: 0})}>
														{/*eslint-disable-next-line*/}
														Invitația 1 🇷🇴
													</div>
													{
														this.state.shareEvent.additional_invites.map((inviteItem,i) => (
															<div key={i} className={"invite-item " + (this.state.selectedInviteId === inviteItem.id ? 'active' : '')}
																 onClick={() => this.setState({selectedInviteId: inviteItem.id})}>
																Invitația {i+2} {getLanguageFlag(inviteItem.language)}
															</div>
														))
													}

												</div>
												:null
										}




										{this.state.inviteCreated && <div>
											<SettingCard icon="" onClick={() => {
												if(this.state.selectedInviteId !== 0) {
													//Go to edit additional invite
													this.props.history.push('/organizer/editInvite/' + this.state.shareEvent.id_eveniment + '/' + this.state.selectedInviteId)
												} else {
													//Go to edit primary invite
													this.props.history.push('/organizer/editInvite/' + this.state.shareEvent.id_eveniment)
												}
											}} name="Editează" isSmall={true} />

											<SettingCard icon="" onClick={() => {
												if(this.state.selectedInviteId !== 0) {
													this.props.history.push('/organizer/invitationPreview/' + this.state.shareEvent.id_eveniment + '/' + this.state.selectedInviteId)
												} else {
													this.props.history.push('/organizer/invitationPreview/' + this.state.shareEvent.id_eveniment)
												}
											}} name="Previzualizează" isSmall={true} />

											<SettingCard icon="" onClick={() => {
												this.setState({
													showSendInviteModal:true
												})
											}} name="Trimite" isSmall={true} />

											<SettingCard icon="" onClick={() => this.props.history.push('/organizer/raspunsuriInvitati/' + this.state.shareEvent.id_eveniment)} name="Răspunsuri" isSmall={true} />

										</div>}

									</div>
								</div>
							</Modal>
						</>
						: null
				}

				{
					this.state.showWalkthrough ?
						<div className={"walkthrough-overlay"}>

							<div className={"content"}>
								<p>Apasă pe numele evenimentului pentru a-l deschide</p>

								<Button
									onClick={() => this.setState({showWalkthrough: false})}
									color="green"
									style={{backgroundColor: "#05AFF2", borderRadius: "20px", width:'80px', marginTop:"20px"}}>Ok</Button>

							</div>

						</div>
						:null
				}

				<Modal
					classNames={{
						overlay: 'modal-overlay',
						modal: 'modal',
						closeButton: 'modal-close-button',
					}}
					open={this.state.showPhoneNumberModal}
					focusTrapped={false}
					onClose={() => this.setState({showPhoneNumberModal: false})}
					closeOnOverlayClick={false}
				>
					<div style={{marginTop: "20px", textAlign: 'center'}}>

						<img src={logo} style={{height: "50px", marginBottom: "30px"}} alt="logo"/>
						<br/>
						<h6>Vă mulțumim că faceți parte din comunitatea Ality și că ne-ați acordat încredere creând un
							eveniment în platforma noastră.</h6>
						<p style={{marginTop: '10px'}}>Pentru a facilita comunicarea vă rugăm să ne lasați numărul
							dumneavoastră de telefon.</p>
						<p style={{marginTop: '10px'}}>Veți primi un <span style={{backgroundColor:'green', color:'#fff', padding:'0 5px', fontWeight:'800'}}>cod de reducere de 5%</span> ce poate fi utilizat la achiziționarea oricărui pachet din aplicație.</p>

						<CustomInput value={this.state.phone} onChange={(val) => {
							this.setState({phone: val.replace(/\D/g, '')})
						}}
									 inputType={'number'}
									 placeholder={'Telefon'}/>

						<BackAndConfirmButtons
							textConfirm={'Salvează'}
							functionConfirm={() => this.updatePhoneNumber()}
							textBack={'Nu, mulțumesc'}
							functionBack={() => this.skipPhoneNumber()}

						/>


					</div>
				</Modal>

				<Modal
					classNames={{
						overlay: 'modal-overlay',
						modal: 'modal',
						closeButton: 'modal-close-button',
					}}
					open={this.state.showPhoneNumberDiscountCodeModal}
					focusTrapped={false}
					onClose={() => this.setState({showPhoneNumberDiscountCodeModal: false})}
					closeOnOverlayClick={false}
				>
					<div style={{marginTop: "20px", textAlign: 'center'}}>

						<img src={logo} style={{height: "50px", marginBottom: "30px"}} alt="logo"/>
						<br/>
						<p style={{fontWeight:'600'}}>Vă mulțumim!</p>
						<p style={{marginTop:'5px'}}>Codul dvs. promoțional este</p>
						<h2 style={{marginTop:'10px', marginBottom:'10px'}}>APR5043</h2>
						<p style={{marginTop: '15px'}}>Vă rugăm să salvați și să folosiți acest cod la achiziționarea
							unui pachet!</p>
						<p style={{marginTop: '10px'}}>Valoarea discount-ului este de 5% și se aplică tuturor pachetelor disponibile în aplicație.</p>

						<ConfirmButton
							style={{marginTop:'15px', width:'100%'}}
							function={() => {
							this.setState({
								showPhoneNumberDiscountCodeModal: false
							})
						}} text={"Mergi mai departe"}/>

					</div>
				</Modal>

				<Modal
					classNames={{
						overlay: 'modal-overlay',
						modal: 'modal-profile',
						closeButton: 'modal-close-button',
					}}
					styles={{
						overlay: {zIndex: '1000000000000000'}
					}}
					open={this.state.showLanguageTempModal}
					onClose={() => this.setState({showLanguageTempModal: false})}
					focusTrapped={false}
				>
					<div>
						<br/><br/>
						<h6>We apologize, this section is not yet available in English.</h6>
						<br/>
						<ConfirmButton
							style={{width:'100%'}}
							text={'Ok'}
							function={() => {
								this.setState({
									showLanguageTempModal: false
								})
							}} />
					</div>
				</Modal>

				<NavbarColor history={this.props.history} active={"My_Created_Events"} />
			</div>
		)
	}
}

export default injectIntl(ViewEvents);
