import React from 'react'
import './GeneralTab.scss'
import { Button, Icon } from 'rsuite'
// import ImgModal from './GeneralComponents/ImgModal'
import CustomModal from './GeneralComponents/CustomModal'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { SiWaze, SiGooglemaps } from 'react-icons/si';

import AdModal from '../../components/AdModal'
import { getUrlFromImage } from '../../components/Helper'
import { eventType_to_eventName, locationTypes } from '../../components/Constants';
import moment from "moment-timezone";
import CustomModalUpdate from "./GeneralComponents/CustomModalUpdate";
import { FormattedMessage } from 'react-intl';
let eventsTypes = eventType_to_eventName

class GeneralTab extends React.Component {
	state = {
		event_data: this.props.event_data,
		eventType: eventsTypes[this.props.event_data.tip_eveniment] !== undefined ? eventsTypes[this.props.event_data.tip_eveniment] : "Absolvire",
		showModal: false,
		loading: true,

		showAdModal: this.props.adData !== null,
		showModalUpdate: false,
		showFullDescription: false,
	}

	componentDidMount = async () => {
		let isAndroid = navigator.userAgent.toLowerCase().indexOf("android") > -1

		if (isAndroid) {
			let viewHeight = window.innerHeight
			let viewport = document.querySelector("meta[name=viewport]");
			viewport.setAttribute("content", "height=" + viewHeight + "px, width=device-width, initial-scale=1.0")
		}

		let event_data = this.props.event_data

		//check if modal is needed
		var shouldShowModal = true;
		var shouldShowModalUpdate = false;
		let participants = event_data.participants

		if(participants && participants.length > 0) {
			for (var i = 0; i < participants.length; i++) {
				if (participants[i].email === this.props.user_data.email && participants[i].user !== null) {
					shouldShowModal = false

					// Daca nu a raspuns la intrebari
					if(event_data.questions && event_data.activate_questions && event_data.published_questions && event_data.questions.length > 0
						&& this.props.participantData &&
						(!this.props.participantData.questions_answers || this.props.participantData.questions_answers.length === 0)) {
						shouldShowModalUpdate = true
					}


					break
				}
			}
		}

		if (!event_data.tickets_needed) {
			if (shouldShowModal) {

				shouldShowModal = true
			} else {
				//is participant
				shouldShowModal = false
			}


		}

		if (this.props.participantData !== null) {
			this.setState({
				showAdModal: false
			})
		}

		this.setState({
			showModal: shouldShowModal,
			showModalUpdate: shouldShowModalUpdate
		}, () => {
			this.setState({
				loading: false
			})
		})
	}

	toggleModalFinal = () => {
		let current = this.state.showModal;
		//toast.success(	<FormattedMessage id={"event_page.general_tab."} />)

		this.setState({
			showModal: !current
		})
	}

	toggleImg = (goToTickets = false) => {
		let current = this.state.showModal;

		this.setState({
			showModal: !current
		})

		this.props.reloadEventData(goToTickets)
	}

	toggleModalUpdate = () => {
		let current = this.state.showModalUpdate;

		this.setState({
			showModalUpdate: !current
		})

		this.props.reloadEventData()
	}

	closeAdModal = () => {
		this.setState({
			showAdModal: false
		})
	}

	openLink = (link) => {
		if (window.flutter_inappwebview) {
			window.flutter_inappwebview.callHandler('openLink', link)
		} else if (window.alityAppView) {
			window.ReactNativeWebView.postMessage(JSON.stringify({
				action: 'openLink',
				url: link,
			}))
		} else {
			window.open(link)
		}
	}

	maps = (x, y) => {
		this.openLink("http://maps.google.com/?q=" + x + "," + y);
	}

	waze = (x, y) => {
		this.openLink("https://www.waze.com/ul?ll=" + x + "%2C" + y + "&navigate=yes&zoom=17");
	}

	render() {
		if (this.state.loading === true) {
			return <div className="GeneralTab-container"><p><FormattedMessage id={"general.loading"} /></p></div>
		}

		let maxLettersToShow = 70

		return (
			<div className="GeneralTab-container">
				{this.props.adData !== null && <AdModal showModal={this.state.showAdModal} closeModal={this.closeAdModal} adData={this.props.adData} />}

				{!this.state.showAdModal &&
					<CustomModal
						showModal={this.state.showModal}
						toggleModal={this.toggleImg}
						event_data={this.state.event_data}
						user_data={this.props.user_data}
					/>}


				{!this.state.showAdModal && !this.state.showModal
					&& this.props.participantData && (!this.props.participantData.inviteStatus || this.props.participantData.inviteStatus !== 3) &&
					<CustomModalUpdate
						showModal={this.state.showModalUpdate}
						toggleModal={this.toggleModalUpdate}
						event_data={this.state.event_data}
						user_data={this.props.user_data}
						participantData={this.props.participantData}
					/>}


				{
					this.state.event_data.imagine_eveniment && this.state.event_data.imagine_eveniment.length > 0 ?
						<div className="Image" >
							<img src={"https://" + process.env.REACT_APP_IMAGE_DOMAIN + getUrlFromImage(this.state.event_data.imagine_eveniment[0])} loading={"lazy"} alt={""}/>
						</div>
						: null
				}

				<div className="Data"> {moment(this.state.event_data.data_eveniment).format('DD.MM.YYYY HH:mm')}{this.state.event_data.data_sfarsit_eveniment ? ' - ' +moment(this.state.event_data.data_sfarsit_eveniment).format('DD.MM.YYYY HH:mm') : ''} </div>

				<div className="Title">{this.state.event_data.title}</div>

				<div className={this.state.showFullDescription ? "Full-Description" : "Description"}>
					{this.state.event_data.descriere.length > maxLettersToShow && !this.state.showFullDescription
						? <div> {this.state.event_data.descriere.substring(0, maxLettersToShow)}...</div>
						: this.state.event_data.descriere
					}
				</div>

				<div style={{ alignSelf: "center", color: "black" }}>
					{
						this.state.event_data.descriere.length > maxLettersToShow
							? <p onClick={() => { this.state.showFullDescription ? this.setState({ showFullDescription: false }) : this.setState({ showFullDescription: true }) }} style={{ fontSize: "11px" }}>
								{this.state.showFullDescription ? <FormattedMessage id={"event_page.general_tab.less_details"} /> : <FormattedMessage id={"event_page.general_tab.more_details"} />}
								{this.state.showFullDescription ? <BsChevronUp /> : <BsChevronDown />}
							</p>
							: <p></p>
					}
				</div>

				{this.props.locations.map((location, index) => {

					let time = moment(location.time)
					let day = moment(location.time)

					if(!location.time && location.date) {
						time = moment(location.date)
						day = moment(location.date)
					} else {
						if (location.day !== undefined) {
							day = moment(location.day, 'DD-MM-YYYY')
						}
					}

					return <div className="Location" key={index}>
						<div className="Type">

							{
								location.name.toLowerCase() === 'congress' ?
									<><FormattedMessage
										id={"event_page.general_tab.location.congress"}/></>
									:
									<><FormattedMessage
										id={"event_page.general_tab.location"}/> {locationTypes[location.name.toLowerCase()]}</>
							}
						</div>

						<div className="Address"> {location.location} </div>

						<div className="Address"> {location.label ? location.label : location.address} </div>

						<div className="Data-Line">
							<div className="Data"> {moment(time).format('HH:mm')} </div>

							<div className="Data"> {moment(day).format('DD-MM-YYYY')}</div>
						</div>

						{location.pinx && location.piny ?
							<div className="Data-Line">
								<Button onClick={() => this.waze(location.pinx, location.piny)} style={{ display: "flex", alignItems: "center", justifyContent: "center", color: "white", backgroundColor: "#05AFF2", width: "150px", marginRight: "20px" }}>
									<SiWaze style={{ marginRight: "5px" }} /> Waze
								</Button>

								<Button onClick={() => this.maps(location.pinx, location.piny)} style={{ display: "flex", alignItems: "center", justifyContent: "center", color: "white", backgroundColor: "#19B899", width: "150px" }}>
									<SiGooglemaps style={{ marginRight: "5px" }} /> Google Maps
								</Button>
							</div>:null}
					</div>
				})}

				{this.props.event_data.tickets_needed && <div className="Buy-Ticket" onClick={this.props.goToTickets}>
					<Icon icon="ticket" style={{ color: "white", marginRight: "10px" }} />
					{
						this.props.event_data.id_eveniment === 'hOC8lz2i5c' ?
							<FormattedMessage id={"event_page.general_tab.buy_ticket.ehc"}/>
							:
							<FormattedMessage id={"event_page.general_tab.buy_ticket"}/>
					}
				</div>}

				{(this.props.event_data.tip_eveniment === "conferinta" ||
					this.props.event_data.tip_eveniment === "team_building" ||
					this.props.event_data.tip_eveniment === "corporate_party")
					&& <div className="Buy-Ticket" onClick={this.props.goToInvite}>
					<Icon icon="ticket" style={{ color: "white", marginRight: "10px" }} />
						<FormattedMessage id={"event_page.general_tab.invite_access"} />
				</div>}
			</div>
		)
	}
}

export default GeneralTab;
