import React from 'react'
import './NavbarColor.scss'
import SVG_Events_Active from '../assets/NavBarSVGs/n-publicate-active.svg'
import SVG_Events from '../assets/NavBarSVGs/n-publicate.svg'
import SVG_My_Events_Active from '../assets/NavBarSVGs/n-create-active.svg'
import SVG_My_Events from '../assets/NavBarSVGs/n-create.svg'
import SVG_My_Events_Part_Active from '../assets/NavBarSVGs/n-particip-active.svg'
import SVG_My_Events_Part from '../assets/NavBarSVGs/n-particip.svg'
import SVG_Settings_Active from '../assets/NavBarSVGs/settings-active-icon.svg'
import SVG_Settings from '../assets/NavBarSVGs/settings-icon.svg'
import SVG_Create_Event_Active from "../organizer/assets/NavBarSVGs/create-event-active-icon.svg";
import SVG_Create_Event from "../organizer/assets/NavBarSVGs/create-event-icon.svg";
import {FormattedMessage} from "react-intl";

class NavbarColor extends React.Component {
	state = {
		hasAccount: false
	}


	getMobileOperatingSystem = () => {
		var userAgent = navigator.userAgent || navigator.vendor || window.opera;

		// Windows Phone must come first because its UA also contains "Android"
		if (/windows phone/i.test(userAgent)) {
			return "Windows Phone";
		}

		if (/android/i.test(userAgent)) {
			return "Android";
		}

		// iOS detection from: http://stackoverflow.com/a/9039885/177710
		if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
			return "iOS";
		}

		return "unknown";
	}

	os = this.getMobileOperatingSystem()

	componentDidMount = () => {
		if (localStorage.getItem('jwt') !== null) {
			this.setState({
				hasAccount: true
			})
		}
	}

	moveToCreateEvent = () => {
		this.props.history.push('/organizer/addEvent')
	}

	moveToNews = () => {
		this.props.history.push('/news')
	}

	moveToOrganizer = () => {
		let link = "" + process.env.REACT_APP_LINK_TO_ORGANIZER + "pageSelect"

		if (window.flutter_inappwebview) {
			window.flutter_inappwebview.callHandler('changeSharedPrefs', "Organizer")
		}

		link += "?"
		link += localStorage.getItem("jwt")

		window.open(link, "_self")
	}

	moveToChosingEvent = () => {
		this.props.history.push('/events');
	}

	moveToMyEvents = () => {
		this.props.history.push('/myEvents');
	}

	moveToMyCreatedEvents = () => {
		this.props.history.push('/organizer/viewEvents');
	}

	moveToProfile = () => {
		if (this.state.hasAccount) {
			this.props.history.push('/profile');

			return
		}

		this.props.history.push('/Login');
	}

	isActiveOrganzier = () => {
		let url = window.location.pathname

		if(url.includes('/organizer') && url !== '/organizer/addEvent') {
			return true
		}

		return false
	}

	render() {
		let svgStyle = { height: "23px" }

		return (
			<div className="NavbarColor-container">
				<div className="NavbarColor-contents"
					 style={this.os === 'Android' ? {fontSize: '8px'} : {}}>

					<div onClick={this.moveToChosingEvent}
						 className={this.props.active === "Events" ? "active-element" : "menu-element"}>
						<div className={"icon-row"}>
							<img src={this.props.active === "Events" ? SVG_Events_Active : SVG_Events}
								 alt="Participanti" style={svgStyle}/>
						</div>
						<div className={"text-row"}>
							<FormattedMessage id={"menu.all_events"}/>
						</div>
					</div>

					<div onClick={this.moveToMyCreatedEvents}
						 className={this.isActiveOrganzier() ? "active-element" : "menu-element"}>
						<div className={"icon-row"}>
							<img src={this.isActiveOrganzier() ? SVG_My_Events_Active : SVG_My_Events}
								 alt="Participanti" style={svgStyle}/>
						</div>
						<div className={"text-row"}>
							<FormattedMessage id={"menu.my_events"}/>
						</div>
					</div>

					<div onClick={this.moveToCreateEvent}
						 className={this.props.active === "Create_event" ? "active-element" : "menu-element"}>
						<div className={"icon-row"}>
							<img src={this.props.active === "Create_event" ? SVG_Create_Event_Active : SVG_Create_Event}
								 alt="Participanti" style={svgStyle}/>
						</div>
						<div className={"text-row"}>
							<FormattedMessage id={"menu.create_event"}/>
						</div>
					</div>

					<div onClick={this.moveToMyEvents}
						 className={this.props.active === "My_Events" ? "active-element" : "menu-element"}>
						<div className={"icon-row"}>
							<img
								src={this.props.active === "My_Events" ? SVG_My_Events_Part_Active : SVG_My_Events_Part}
								alt="Participanti" style={svgStyle}/>
						</div>
						<div className={"text-row"}>
							<FormattedMessage id={"menu.attending_events"}/>
						</div>
					</div>

					{/*
					<div onClick={this.moveToNews} className={this.props.active === "News" ? "active-element" : "menu-element"} >
						<img src={this.props.active === "News" ? SVG_News_Active : SVG_News} alt="Participanti" style={svgStyle} />
						<p> Știri </p>
					</div> */}

					<div onClick={this.moveToProfile}
						 className={this.props.active === "Profile" ? "active-element" : "menu-element"}>
						<div className={"icon-row"}>
							<img src={this.props.active === "Profile" ? SVG_Settings_Active : SVG_Settings}
								 alt="Participanti" style={svgStyle}/>
						</div>
						<div className={"text-row"}>
							<FormattedMessage id={"menu.my_account.1"}/><br/><FormattedMessage
							id={"menu.my_account.2"}/>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default NavbarColor;
