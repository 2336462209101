import React, {useEffect, useState} from "react";
import {useHistory, useLocation, useParams, useSearchParams} from "react-router-dom";
import HeaderWhite from "../../../components/HeaderWhite";
import {useIntl} from "react-intl";
import './style.scss'
import SVG_Petrecere from "../../../organizer/assets/EventsTypes/petrecere-icon.svg";
import {Button} from "rsuite";
import axios from "axios";
import Loader from "../../../components/Loader";
import {sha256} from "../../../functions";
import queryString from 'query-string';

export default function ThankYouRegisterPage() {

    const intl = useIntl();
    let history = useHistory()

    const queryParams = queryString.parse(useLocation().search)


    const [loading, setLoading] = useState(true)

    useEffect(() => {

        getUser()

    }, []);


    const getUser = async () => {


        let jwt = localStorage.getItem('jwt')
        let auth = {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        }

        if (!jwt) {
            history.push('/events')
        }

        await axios.get(process.env.REACT_APP_DOMAIN + 'users/me', auth)
            .then(async response => {

                let user = response.data

                let email = await sha256(user.email)

                setTimeout(() => {
                    try {
                        window.fbq('track', 'PageView');
                        window.fbq('track', 'CompleteRegistration');

                        window.ttq.identify({
                            "email": email
                        });

                        window.ttq.track('CompleteRegistration', {
                            "contents": [{
                                "content_id": "<content_identifier>", // string. ID of the product. Example: "1077218".
                                "content_name": "<content_name>", // string. The name of the page or product. Example: "shirt".
                            }],
                        });
                    } catch (e) {

                    }

                    setLoading(false)
                }, 300)

            })

    }

    return (
        <div className={"GeneralScreen-container ThankYouRegisterPage"}>

            <HeaderWhite/>

            <div className="GeneralScreen-contents">

                {loading ? <Loader/> :
                    <>
                        <div className={"icon"}>
                            <img src={SVG_Petrecere} alt={""}/>
                        </div>
                        <h2 className={"title"}>{intl.formatMessage({id: 'thank_you_page_register.title'})}</h2>

                        <div className={"subtitle"}>
                            {intl.formatMessage({id: 'thank_you_page_register.description'})}
                        </div>

                        <div className={"button-cont"}>
                            <Button color="green" style={{
                                backgroundColor: "#05AFF2",
                                borderRadius: "20px",
                                width: "100%",
                                maxWidth: "300px",
                                marginTop: '30px'
                            }}
                                    onClick={() => {
                                        if (queryParams && queryParams.redirectBackTo && queryParams.redirectBackTo.length > 0) {
                                            history.push('/' + queryParams.redirectBackTo)
                                        } else {
                                            history.push('/events')
                                        }
                                    }}
                            >{intl.formatMessage({id: 'thank_you_page_register.button'})}</Button>
                        </div>
                    </>}

            </div>
        </div>
    )
}