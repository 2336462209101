import React from 'react'
import { SelectPicker } from 'rsuite'
import { toast } from 'react-toastify'
import axios from 'axios'
import _ from "lodash";

import './ResponseTab.scss'
import CustomInput from '../../../components/custom/CustomInput';
import { ConfirmButton, CustomQuestions } from '../../../components/Helper';
import {injectIntl} from 'react-intl';
let answersType = [{ label: "Particip", value: "yes" }, { label: "Nu particip", value: "no" }, { label: "Nu stiu", value: "don't know" }]

class ResponseTab extends React.Component {
	state = {
		answer: this.props.response_data.response.answer,
		nume: this.props.response_data.response.nume,
		prenume: this.props.response_data.response.prenume,
		insotit: this.props.response_data.response.insotit,
		nume_insotitor: this.props.response_data.response.nume_insotitor,
		cuCopii: this.props.response_data.response.cuCopii,
		nr_copii: this.props.response_data.response.nr_copii,

		kidsNumberOptions: ["1", "2", "3", "4", "5"],

		isUsingCustomQuestions: false,
		customQuestions: [],
		answers: [],

		showSaveButton: false
	}

	componentDidMount = async () => {
		if (localStorage.getItem("id") === null) {
			toast.error("Trebuie sa va logati pentru a folosi site-ul")
			this.props.history.push('/login');
			return
		}

		let questions = _.cloneDeep(this.props.event_data.questions)
		let answers = []

		for (let i = 0; i < questions.length; i++) {
			answers.push(this.props.response_data.response["answer" + i])

			let newOptions = []

			for (let j = 0; j < questions[i].options.length; j++) {
				newOptions.push({ label: questions[i].options[j], value: questions[i].options[j] })
			}

			questions[i].options = newOptions
		}

		this.setState({
			isUsingCustomQuestions: this.props.event_data.useCustomQuestions,
			customQuestions: questions,
			answers: answers
		})
	}

	setter = (key, value) => {
		this.setState({
			[key]: value,
			showSaveButton: true
		})
	}

	changeAnswers = (newAnswers) => {
		this.setState({
			answers: newAnswers,
			showSaveButton: true
		})
	}

	checkData = () => {
		//check data
		if (this.state.answer === "") {
			toast.error("Va rog completati raspunsul la invitatie")
			return;
		}

		if (this.state.prenume === "") {
			toast.error("Va rog completati prenumele")
			return;
		}

		if (this.state.insotit && this.state.nume_insotitor === "") {
			toast.error("Va rog completati numele partenerului")
			return
		}

		if (this.state.cuCopii && this.state.nr_copii === null) {
			toast.error("Va rog mentionati cu cati copii veniti")
			return
		}

		//send new data
		this.sendData()
	}

	sendData = async () => {
		let obj = {
			answer: this.state.answer,
			nume: this.state.nume,
			prenume: this.state.prenume,
			insotit: this.state.insotit,
			nume_insotitor: this.state.nume_insotitor,
			cuCopii: this.state.cuCopii,
			nr_copii: this.state.nr_copii,
		}

		for (let i = 0; i < this.state.customQuestions.length; i++) {
			obj["answer" + i] = this.state.answers[i]
		}

		let data = {
			response: obj
		}

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		axios.post(process.env.REACT_APP_DOMAIN + 'responses/updateMyResponse/' + this.props.response_data.id, data, auth)
			.then(response => {
				let response_data = {
					id: response.data.id,
					response: obj
				}

				this.props.updateResponse(response_data)

				toast.success("Modificari salvate")
			})
	}

	render() {
		return <div className="ResponseTab-container">
			<p>Raspuns</p>
			<SelectPicker
				searchable={false}
				style={{ zIndex: "2000", marginBottom: 20, borderRadius: "5px" }}
				data={answersType}
				appearance="default"
				onChange={(value) => this.setState({ answer: value, showSaveButton: true })}
				value={this.state.answer}
				placeholder="Raspuns invitatie"
				cleanable={false}
			/>

			{/* <CustomInput style={{ margin: "10px 0px" }} value={this.state.nume} onChange={(value) => this.setter("nume", value)} placeholder={'Nume'} /> */}

			<CustomInput style={{ margin: "10px 0px" }} value={this.state.prenume} onChange={(value) => this.setter("prenume", value)} placeholder={'Prenume, Nume'} />

			{this.state.answer === "yes" && <div>
				<div className="data-row">
					<div className={!this.state.insotit ? "active-option" : "inactive-option"} style={{ width: "100px" }} onClick={_ => this.setter("insotit", false)}>
						Vin singur/ă
					</div>

					<div className={this.state.insotit ? "active-option" : "inactive-option"} style={{ width: "100px" }} onClick={_ => this.setter("insotit", true)}>
						Vin insoțit/ă
					</div>
				</div>

				{this.state.insotit && (
					<CustomInput value={this.state.nume_insotitor} onChange={value => this.setter("nume_insotitor", value)} placeholder={"Nume, Prenume Insotitor"} />
				)}
			</div>}

			{this.state.answer === "yes" && <div>
				<div style={{ fontSize: "18px", textAlign: "left" }}>Veniti insotiti de copii?</div>
				<div className="data-row">
					<div className={!this.state.cuCopii ? "active-option" : "inactive-option"} onClick={_ => this.setter("cuCopii", false)}>
						Nu
					</div>

					<div className={this.state.cuCopii ? "active-option" : "inactive-option"} onClick={_ => this.setter("cuCopii", true)}>
						Da
					</div>
				</div>
				{this.state.cuCopii && (
					<div>
						<div>Cu cati copii veniti?</div>

						<div className="data-row">
							{this.state.kidsNumberOptions.map((nr, index) =>
								<div key={index} className={this.state.nr_copii === nr ? "active-option" : "inactive-option"} onClick={_ => this.setter("nr_copii", nr)}>
									{nr}
								</div>)}
						</div>
					</div>
				)}
			</div>}

			{this.state.answer === "yes" && this.state.isUsingCustomQuestions && <CustomQuestions questions={this.state.customQuestions} answers={this.state.answers} changeAnswers={this.changeAnswers} intl={this.props.intl}/>}

			{this.state.showSaveButton && <ConfirmButton text={"Confirma schimbarile"} style={{ width: "200px" }} function={this.checkData} />}
		</div>
	}
}

export default injectIntl(ResponseTab);
