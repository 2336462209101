import moment from "moment-timezone";
import {corporateWeddingEvents} from "./const";

const inviteTextsTranslation = {
    'ro': {
        'la ora': 'la ora {{date}}',
        'Nu Particip': 'Nu, nu pot să particip!',
        'Particip': 'Da, confirm prezența!',
        'Nu știu': 'Încă nu m-am hotărât!',
        'Apăsați pentru a porni': 'Apăsați pentru a porni',
        'Locatie eveniment': 'Locație eveniment'
    },
    'en': {
        //Nunta
        'Cu inimile pline de emoție și bucurie, noi': 'With hearts full of emotion and joy, we',
        'Copil': 'Children',
        'Mireasă': 'Bride',
        'Mire': 'Groom',
        'Aniversarea mea': 'Your name',
        'Vă invităm să fiți alături de noi la celebrarea căsătoriei noastre.': 'We invite you to join us in celebrating our marriage.',
        'Vă invităm să fiți alături de noi la celebrarea căsătoriei noastre și a botezului micuței' : 'We invite you to join us in celebrating our marriage and the chirstening of',
        'Vă invităm să fiți alături de noi la celebrarea cununiei noastre.': 'We invite you to join us in celebrating our wedding.',
        "Alături ne vor fi părinții": 'Our parents will be with us',
        "Soț & Soție": 'Husband & Wife',
        'Și nașii': 'And the godparents',
        'Nașul & Nașa': 'Godfather and Godmother',
        'Vă așteptăm cu drag!': 'Thank you!',
        //Botez
        'Sunt cel mai frumos dar pe care Dumnezeu l-a făcut părinților mei și mă numesc': 'I am the most beautiful gift that God gave to my parents and my name is',
        'Împreună cu părinții mei,': 'together with my parents',
        'Având alături nașii,': 'With the godfathers by my side,',
        'Cornel și Livia': 'Sophia & Oliver',
        'Avem plăcerea de a vă invita la botezul meu': 'We are pleased to invite you to my christening',
        'Avem plăcerea de a vă invita la tăierea moțului meu': 'We are pleased to invite you to my christening',
        'Avem plăcerea de a vă invita la ruperea turtei mele': 'We are pleased to invite you to my christening',
        //Party
        'Vă invităm să ne distrăm împreună': 'We invite you to have fun together',
        //Corporate
        'Vă invităm la evenimentul nostru': 'We invite you to our event',
        'Locatie eveniment': 'Event location',
        'Denumire eveniment business': 'Event title',
        'Adaugă aici descrierea evenimentului': 'Add your event description here',

        //Locations and addresses
        'Cununia religioasă va avea loc la': 'The religious ceremony will take place at',
        'Botezul va avea loc la': 'The christening will take place at',
        'la ora': 'at {{date}}',
        'Cununia civilă se va desfășura la': 'The civil wedding will take place at',
        'Vom petrece împreună la': 'We will party together at',

        //Countdown
        'Zi': 'Day',
        'Zile': 'Days',
        'Oră': 'Hour',
        'Ore': 'Hours',
        'Minut': 'Minute',
        'Minute': 'Minutes',
        'Secunde': 'Seconds',

        //Separator
        'și': 'and',

        //Form
        'Doriți să participați?': 'Do you want to participate?',
        'Nu Particip': 'No',
        'Particip': 'Yes',
        'Nu știu': 'I don\'t know',
        'Vă rugăm introduceți următoarele informații:': 'Please provide the following information:',
        'Prenume, Nume': 'Full name',
        'Ne pare rău să auzim asta, daca vă răzgândiți vă așteptam': 'Sorry to hear that, if you change your mind we were waiting for you',
        'Nu este obligatoriu să răspundeți acum, când sunteți sigur': 'You don\'t have to answer now',
        'Vin singur/ă': 'I come alone',
        'Vin insoțit/ă': 'I come accompanied',
        "Prenume, Nume Insotitor": 'Accompanying full name',
        'Veniți însoțiți de copii?': 'Are you coming with children?',
        'Da': 'Yes',
        'Nu': 'No',
        'Cu câți copii veniți?': 'How many children are you coming with?',
        'Trimite răspuns': 'Send',
        'Vă mulțumim!': 'Thank you!',
        //Form alerts
        'Vă rugăm completați numele și prenumele': 'Please fill in your first and last name',
        'Vă rugăm, completați numele partenerului': 'Please fill in the name of the companion',
        'Vă rugăm mentionați cu câți copii veniți': 'Please mention how many children you come with',
        'Ați trimis deja un răspuns pentru acest eveniment!': 'You have already submitted a response for this event!',
        'Răspunsul a fost trimis!' : 'The answer has been sent!',
        'Întrebări opționale': 'Optional questions',
        'Răspuns liber': 'Free answer',
        'Adresa de email': 'Email address',
        'Vă rugăm completați adresa de email': 'Please complete the email address',
        'Adresa de email nu este corectă!': 'The email address is not correct!',
        'Apăsați pentru a porni': 'Press here to start',
        'Omite': 'Skip',
        'Redă': 'Replay',
        'Oprește melodia': 'Stop the song',
        'Pornește melodia': 'Play the song',
        'Apasă pentru a deschide invitația': 'Click to open the invitation',
        'Răspunde': 'Respond',
        'Vezi locația': 'See location',
        'Vezi invitația': 'See the invitation',
        'Alegeți numărul de insoțitori': 'Choose the number of your companion'
    },
    'fr':{
        //Nunta
        'Cu inimile pline de emoție și bucurie, noi': 'Le cœur plein d\'émotion et de joie, nous',
        'Copil': 'enfant',
        'Mireasă': 'Mariée',
        'Mire': 'marié',
        'Aniversarea mea': 'Your name',
        'Vă invităm să fiți alături de noi la celebrarea căsătoriei noastre.': 'Nous vous invitons à nous rejoindre pour célébrer notre mariage.',
        'Vă invităm să fiți alături de noi la celebrarea căsătoriei noastre și a botezului micuței' : 'Nous vous invitons à vous joindre à nous pour célébrer notre mariage et le baptême de notre petit',
        'Vă invităm să fiți alături de noi la celebrarea cununiei noastre.': 'Nous vous invitons à nous rejoindre pour célébrer notre mariage.',
        "Alături ne vor fi părinții": 'Nos parents seront avec nous',
        "Soț & Soție": 'Mari & Épouse',
        'Și nașii': 'Et les parrains et marraines',
        'Nașul & Nașa': 'Nez & Marraine',
        'Vă așteptăm cu drag!': 'Vous êtes les bienvenus!',

        //Botez
        'Sunt cel mai frumos dar pe care Dumnezeu l-a făcut părinților mei și mă numesc': 'Je suis le plus beau cadeau que Dieu a fait à mes parents et je m\'appelle moi',
        'Împreună cu părinții mei,': 'avec mes parents',
        'Având alături nașii,': 'Avoir les parrains et marraines à vos côtés',
        'Cornel și Livia': 'Papa et Maman',
        'Avem plăcerea de a vă invita la botezul meu': 'Nous avons le plaisir de vous inviter à mon baptême',
        'Avem plăcerea de a vă invita la tăierea moțului meu': 'Nous avons le plaisir de vous inviter au découpage de mon motz',
        'Avem plăcerea de a vă invita la ruperea turtei mele': 'Nous avons le plaisir de vous inviter au cassement de mon gâteau',

        //Party
        'Vă invităm să ne distrăm împreună': 'Nous vous invitons à vous amuser ensemble',
        //Corporate
        'Vă invităm la evenimentul nostru': 'Nous vous invitons à notre événement',
        'Locatie eveniment': 'Lieu de l\'événement',
        'Denumire eveniment business': 'Titre de l\'événement',
        'Adaugă aici descrierea evenimentului': 'Ajoutez ici la description de votre événement',

        //Emplacements et adresses
        'Cununia religioasă va avea loc la': 'Le mariage religieux aura lieu à',
        'Botezul va avea loc la': 'Le baptême aura lieu à',
        'la ora': 'en classe {{date}}',
        'Cununia civilă se va desfășura la': 'Le mariage civil aura lieu à',
        'Vom petrece împreună la': 'Nous passerons ensemble à',

        //Compte à rebours
        'Zi': 'Jour',
        'Zile': 'jours',
        'Oră': 'heure',
        'Ore': 'heures',
        'Minut': 'Minute',
        'Minute': 'Minutes',
        'Secunde': 'secondes',

        //Separator
        'și': 'et',

        //Formulaire
        'Doriți să participați?': 'Veux-tu participer?',
        'Nu Particip': 'Pas',
        'Particip': 'Participer',
        'Nu știu': 'Je ne sais pas',
        'Vă rugăm introduceți următoarele informații:': 'Veuillez saisir les informations suivantes :',
        'Prenume, Nume': 'Nom et Prénom',
        'Ne pare rău să auzim asta, daca vă răzgândiți vă așteptam': 'Désolé d\'entendre ça, si tu changes d\'avis, nous t\'attendions',
        'Nu este obligatoriu să răspundeți acum, când sunteți sigur': 'Tu n\'es pas obligé de répondre maintenant, quand tu es sûr',
        'Vin singur/ă': 'Je viens seul',
        'Vin insoțit/ă': 'Vin accompagné',
        "Prenume, Nume Insotitor": 'Prénom nom de famille compagnon',
        'Veniți însoțiți de copii?': 'Vous venez avec des enfants ?',
        'Da': 'Oui',
        'Nu': 'Pas',
        'Cu câți copii veniți?': 'Avec combien d’enfants venez-vous ?',
        'Trimite răspuns': 'Envoyer la réponse',
        'Vă mulțumim!': 'Merci!',
        //Alertes de formulaire
        'Vă rugăm completați numele și prenumele': 'Merci de renseigner votre nom et prénom',
        'Vă rugăm, completați numele partenerului': 'Merci de renseigner le nom du partenaire',
        'Vă rugăm mentionați cu câți copii veniți': 'Veuillez indiquer avec combien d\'enfants vous venez',
        'Ați trimis deja un răspuns pentru acest eveniment!': 'Vous avez déjà soumis une réponse pour cet événement!',
        'Răspunsul a fost trimis!' : 'La réponse a été envoyée !',
        'Întrebări opționale': 'Questions facultatives',
        'Răspuns liber': 'Réponse gratuite',
        'Adresa de email': 'Adresse e-mail',
        'Vă rugăm completați adresa de email': 'Veuillez compléter l\'adresse e-mail',
        'Adresa de email nu este corectă!': 'L\'adresse e-mail n\'est pas correcte !',
        'Apăsați pentru a porni': 'Appuyez ici pour commencer',
        'Omite': 'Sauter',
        'Redă': 'Rejouer',
        'Oprește melodia': 'Arrête la chanson',
        'Pornește melodia': 'Joue la chanson',
        'Apasă pentru a deschide invitația': "Cliquez pour ouvrir l'invitation",
        'Răspunde':'Répondre',
        'Vezi locația':  "Voir l'emplacement",
        'Vezi invitația': "Voir l'invitation",
        'Alegeți numărul de insoțitori': 'Choisissez le numéro d\'accompagnateur'
    },
    'es': {
        //Nunta
        'Cu inimile pline de emoție și bucurie, noi': 'Con el corazón lleno de emoción y alegría,',
        'Copil': 'niño',
        'Mireasă': 'Novia',
        'Mire': 'Acicalar',
        'Aniversarea mea': 'Mi cumpleaños',
        'Vă invităm să fiți alături de noi la celebrarea căsătoriei noastre.': 'Te invitamos a unirte a nosotros para celebrar nuestro matrimonio.',
        'Vă invităm să fiți alături de noi la celebrarea căsătoriei noastre și a botezului micuței' : 'Te invitamos a unirte a nosotros para celebrar nuestro matrimonio y el bautismo de nuestro pequeño.',
        'Vă invităm să fiți alături de noi la celebrarea cununiei noastre.': 'Te invitamos a unirte a nosotros para celebrar nuestra boda.',
        "Alături ne vor fi părinții": 'Nuestros padres estarán con nosotros.',
        "Soț & Soție": 'Marido & Esposa',
        'Și nașii': 'Y los padrinos',
        'Nașul & Nașa': 'Padrino y Madrina',
        'Vă așteptăm cu drag!': 'Te estamos esperando ¡cariñosamente!',

        //Botez
        'Sunt cel mai frumos dar pe care Dumnezeu l-a făcut părinților mei și mă numesc': 'ISoy el regalo más hermoso que Dios le dio a mis padres y mi nombre soy yo.',
        'Împreună cu părinții mei,': 'Junto con mis padres',
        'Având alături nașii,': 'Con los padrinos a su lado,',
        'Nașul și  nașa': 'Nariz & Madrina',
        'Avem plăcerea de a vă invita la botezul meu': 'Tenemos el agrado de invitarte a mi bautismo.',
        'Avem plăcerea de a vă invita la tăierea moțului meu': 'Tenemos el placer de invitarte al corte de mi motz.',
        'Avem plăcerea de a vă invita la ruperea turtei mele': 'Tenemos el placer de invitarte a la rotura de mi pastel.',

        //Party
        'Vă invităm să ne distrăm împreună': 'Te invitamos a divertirnos juntos.',
        //Corporate
        'Vă invităm la evenimentul nostru': 'Te invitamos a nuestro evento',
        'Locatie eveniment': 'Ubicación del evento',
        'Denumire eveniment business': 'Título del evento',
        'Adaugă aici descrierea evenimentului': 'Agrega la descripción de tu evento aquí',

        //Locations and addresses
        'Cununia religioasă va avea loc la': 'La boda religiosa se llevará a cabo en',
        'Botezul va avea loc la': 'El bautismo tendrá lugar en',
        'la ora': 'en clase {{date}}',
        'Cununia civilă se va desfășura la': 'La boda civil se celebrará en',
        'Vom petrece împreună la': 'Pasaremos juntos en',

        //Countdown
        'Zi': 'Día',
        'Zile': 'Días',
        'Oră': 'Hora',
        'Ore': 'Horas',
        'Minut': 'Minuto',
        'Minute': 'Minutos',
        'Secunde': 'Segundos',

        //Separator
        'și': 'y',

        //Form
        'Doriți să participați?': 'Quieres participar?',
        'Nu Particip': 'No participo',
        'Particip': 'Yo participo',
        'Nu știu': 'No lo sé',
        'Vă rugăm introduceți următoarele informații:': 'Por favor, introduzca la siguiente información:',
        'Prenume, Nume': 'Nombre completo',
        'Ne pare rău să auzim asta, daca vă răzgândiți vă așteptam': 'Lamento escuchar eso, si cambias de opinión te estábamos esperando.',
        'Nu este obligatoriu să răspundeți acum, când sunteți sigur': 'No tienes que responder ahora, cuando estás seguro.',
        'Vin singur/ă': 'Vengo solo',
        'Vin insoțit/ă': 'Vino acompañado',
        "Prenume, Nume Insotitor": 'El nombre completo del acompañante',
        'Veniți însoțiți de copii?': '¿Vienes con niños?',
        'Da': 'Sí',
        'Nu': 'No',
        'Cu câți copii veniți?': '¿Con cuántos niños vienes?',
        'Trimite răspuns': 'Enviar respuesta',
        'Vă mulțumim!': '¡Gracias!',
        //Form alerts
        'Vă rugăm completați numele și prenumele': 'Por favor complete su nombre y apellido',
        'Vă rugăm, completați numele partenerului': 'Por favor complete el nombre del socio',
        'Vă rugăm mentionați cu câți copii veniți': 'Indique con cuántos niños viene.',
        'Ați trimis deja un răspuns pentru acest eveniment!': '¡Ya has enviado una respuesta para este evento!',
        'Răspunsul a fost trimis!' : '¡La respuesta ha sido enviada!',
        'Întrebări opționale': 'Preguntas opcionales',
        'Răspuns liber': 'Respuesta gratuita',
        'Adresa de email': 'Dirección de correo electrónico',
        'Vă rugăm completați adresa de email': 'Por favor, complete la dirección de correo electrónico',
        'Adresa de email nu este corectă!': '¡La dirección de correo electrónico no es correcta!',
        'Apăsați pentru a porni': 'Presione aquí para comenzar',
        'Omite': 'Saltar',
        'Redă': 'Repetición',
        'Oprește melodia': 'Detener la cancion',
        'Pornește melodia': 'Toca la cancion',
        'Apasă pentru a deschide invitația': 'Haga clic para abrir la invitación.',
        'Răspunde': 'Responder',
        'Vezi locația': 'Ver ubicación',
        'Vezi invitația': 'Ver la invitación',
        'Alegeți numărul de insoțitori': 'Elige el número de acompañante'
    },
    'it': {
        //Nunta
        'Cu inimile pline de emoție și bucurie, noi': 'Con il cuore pieno di emozione e gioia, noi',
        'Copil': 'Bambino',
        'Mireasă': 'Sposa',
        'Mire': 'Sposo',
        'Aniversarea mea': 'Il mio compleanno',
        'Vă invităm să fiți alături de noi la celebrarea căsătoriei noastre.': 'Vi invitiamo ad unirvi a noi per celebrare il nostro matrimonio.',
        'Vă invităm să fiți alături de noi la celebrarea căsătoriei noastre și a botezului micuței' : 'Vi invitiamo ad unirvi a noi per celebrare il nostro matrimonio e il battesimo del nostro piccolo',
        'Vă invităm să fiți alături de noi la celebrarea cununiei noastre.': 'Vi invitiamo ad unirvi a noi per celebrare il nostro matrimonio.',
        "Alături ne vor fi părinții": 'i nostri genitori saranno al nostro fianco',
        "Soț & Soție": 'Marito & Moglie',
        'Și nașii': 'E i padrini',
        'Nașul & Nașa': 'Padrino e Madrina',
        'Vă așteptăm cu drag!': 'Prego!',

        //Botez
        'Sunt cel mai frumos dar pe care Dumnezeu l-a făcut părinților mei și mă numesc': 'Sono il dono più bello che Dio ha fatto ai miei genitori e il mio nome sono io',
        'Împreună cu părinții mei,': 'Insieme ai miei genitori',
        'Având alături nașii,': 'Con i padrini al suo fianco,',
        'Cornel și Livia': 'Sophia & Oliver',
        'Avem plăcerea de a vă invita la botezul meu': 'Siamo lieti di invitarvi al mio battesimo',
        'Avem plăcerea de a vă invita la tăierea moțului meu': 'Siamo lieti di invitarvi al taglio del mio mot',
        'Avem plăcerea de a vă invita la ruperea turtei mele': 'Abbiamo il piacere di invitarvi alla rottura della mia torta',

        //Party
        'Vă invităm să ne distrăm împreună': 'Vi invitiamo a divertirci insieme',
        //Corporate
        'Vă invităm la evenimentul nostru': 'Ti invitiamo al nostro evento',
        'Locatie eveniment': 'Luogo dell\'evento',
        'Denumire eveniment business': 'Titolo dell\'evento',
        'Adaugă aici descrierea evenimentului': 'Aggiungi qui la descrizione del tuo evento',

        //Locations and addresses
        'Cununia religioasă va avea loc la': 'Il matrimonio religioso avrà luogo presso',
        'Botezul va avea loc la': 'Il battesimo avrà luogo al',
        'la ora': 'alle ore {{date}}',
        'Cununia civilă se va desfășura la': 'Il matrimonio civile avrà luogo a',
        'Vom petrece împreună la': 'Trascorreremo insieme a',

        //Countdown
        'Zi': 'Giorno',
        'Zile': 'Giorni',
        'Oră': 'Ora',
        'Ore': 'Ore',
        'Minut': 'Minuto',
        'Minute': 'Minuti',
        'Secunde': 'Secondi',

        //Separator
        'și': 'e',

        //Form
        'Doriți să participați?': 'Vuoi partecipare?',
        'Nu Particip': 'Non partecipo',
        'Particip': 'Partecipare',
        'Nu știu': 'Non lo so',
        'Vă rugăm introduceți următoarele informații:': 'Inserisci le seguenti informazioni:',
        'Prenume, Nume': 'L\'intero nome',
        'Ne pare rău să auzim asta, daca vă răzgândiți vă așteptam': 'Mi dispiace, se cambi idea ti stavamo aspettando',
        'Nu este obligatoriu să răspundeți acum, când sunteți sigur': 'Non devi rispondere adesso, che sei sicuro',
        'Vin singur/ă': 'Vengo da solo',
        'Vin insoțit/ă': 'Vino accompagnato',
        "Prenume, Nume Insotitor": 'Il nome del compagno',
        'Veniți insoțiți de copii?': 'Vieni con i bambini?',
        'Da': 'SÌ',
        'Nu': 'Non',
        'Cu câți copii veniți?': 'Con quanti bambini verrai?',
        'Trimite răspuns': 'Invia risposta',
        'Vă mulțumim!': 'Grazie!',
        //Form alerts
        'Vă rugăm completați numele și prenumele': 'Per favore inserisci il tuo nome e cognome',
        'Vă rugăm, completați numele partenerului': 'Si prega di inserire il nome del partner',
        'Vă rugăm mentionați cu câți copii veniți': 'Per favore indica con quanti bambini verrai',
        'Ați trimis deja un răspuns pentru acest eveniment!': 'Hai già inviato una risposta per questo evento!',
        'Răspunsul a fost trimis!' : 'La risposta è stata inviata!',
        'Întrebări opționale': 'Domande facoltative',
        'Răspuns liber': 'Risposta gratuita',
        'Adresa de email': 'Indirizzo email',
        'Vă rugăm completați adresa de email': 'Si prega di compilare l\'indirizzo email',
        'Adresa de email nu este corectă!': 'L\'indirizzo email non è corretto!',
        'Apăsați pentru a porni': 'Premi qui per iniziare',
        'Omite': 'Saltare',
        'Redă': 'Rigiocare',
        'Oprește melodia': 'Ferma la canzone',
        'Pornește melodia': 'Riproduci la canzone',
        'Apasă pentru a deschide invitația': "Fare clic per aprire l'invito",
        'Răspunde': 'Rispondere',
        'Vezi locația': 'Vedi la posizione',
        'Vezi invitația': "Vedi l'invito",
        'Alegeți numărul de insoțitori': 'Scegli il numero dell\'accompagnatore'
    },
    'de': {
        //Nunta
        'Cu inimile pline de emoție și bucurie, noi': 'Mit Herzen voller Emotionen und Freude, wir',
        'Copil': 'Kind',
        'Mireasă': 'Braut',
        'Mire': 'Bräutigam',
        'Aniversarea mea': 'Mein Geburtstag',
        'Vă invităm să fiți alături de noi la celebrarea căsătoriei noastre.': 'Wir laden Sie ein, mit uns unsere Hochzeit zu feiern.',
        'Vă invităm să fiți alături de noi la celebrarea căsătoriei noastre și a botezului micuței' : 'WWir laden Sie ein, mit uns unsere Hochzeit und die Taufe unseres Kleinen zu feiern',
        'Vă invităm să fiți alături de noi la celebrarea cununiei noastre.': 'WWir laden Sie ein, mit uns unsere Hochzeit zu feiern.',
        "Alături ne vor fi părinții": 'Unsere Eltern werden bei uns sein',
        "Soț & Soție": 'Ehemann & Gattin',
        'Și nașii': 'Und die Paten',
        'Nașul & Nașa': 'Pate und Patin',
        'Vă așteptăm cu drag!': 'Gern geschehen!',

        //Botez
        'Sunt cel mai frumos dar pe care Dumnezeu l-a făcut părinților mei și mă numesc': 'Ich bin das schönste Geschenk, das Gott meinen Eltern gemacht hat, und mein Name ist ich',
        'Împreună cu părinții mei,': 'zusammen mit meinen Eltern',
        'Având alături nașii,': 'Mit den Paten an seiner Seite,',
        'Cornel și Livia': 'Sophia & Oliver',
        'Avem plăcerea de a vă invita la botezul meu': 'Wir freuen uns, Sie zu meiner Taufe einladen zu dürfen',
        'Avem plăcerea de a vă invita la tăierea moțului meu': 'Wir freuen uns, Sie zum Schneiden meines Motz einzuladen',
        'Avem plăcerea de a vă invita la ruperea turtei mele': 'Wir freuen uns, Sie zum Aufbrechen meiner Torte einzuladen',

        //Party
        'Vă invităm să ne distrăm împreună': 'Wir laden Sie ein, gemeinsam Spaß zu haben',
        //Corporate
        'Vă invităm la evenimentul nostru': 'Wir laden Sie zu unserer Veranstaltung ein',
        'Locatie eveniment': 'Veranstaltungsort',
        'Denumire eveniment business': 'Veranstaltungstitel',
        'Adaugă aici descrierea evenimentului': 'Fügen Sie hier Ihre Veranstaltungsbeschreibung hinzu',

        //Locations and addresses
        'Cununia religioasă va avea loc la': 'Die kirchliche Trauung findet um statt',
        'Botezul va avea loc la': 'Die Taufe findet um statt',
        'la ora': 'im Unterricht {{date}}',
        'Cununia civilă se va desfășura la': 'Die standesamtliche Trauung findet um statt',
        'Vom petrece împreună la': 'Wir werden zusammen verbringen',

        //Countdown
        'Zi': 'Tag',
        'Zile': 'Tage',
        'Oră': 'Stunde',
        'Ore': 'Std.',
        'Minut': 'Minute',
        'Minute': 'Protokoll',
        'Secunde': 'Sekunden',

        //Separator
        'și': 'Und',

        //Form
        'Doriți să participați?': 'Möchten Sie teilnehmen?',
        'Nu Particip': 'Ich mache nicht mit',
        'Particip': 'Ja, ich nehme teil',
        'Nu știu': 'Ich weiß es nicht',
        'Vă rugăm introduceți următoarele informații:': 'Bitte geben Sie folgende Informationen ein:',
        'Prenume, Nume': 'vollständiger Name',
        'Ne pare rău să auzim asta, daca vă răzgândiți vă așteptam': 'Es tut mir leid, das zu hören. Falls Sie Ihre Meinung ändern, haben wir auf Sie gewartet',
        'Nu este obligatoriu să răspundeți acum, când sunteți sigur': 'Sie müssen jetzt nicht antworten, wenn Sie sicher sind',
        'Vin singur/ă': 'Ich komme alleine',
        'Vin insoțit/ă': 'Begleitender Wein',
        "Prenume, Nume Insotitor": 'Name des Begleiters',
        'Veniți însoțiți de copii?': 'Kommen Sie mit Kindern?',
        'Da': 'Ja',
        'Nu': 'Nicht',
        'Cu câți copii veniți?': 'Mit wie vielen Kindern kommst du?',
        'Trimite răspuns': 'Antwort senden',
        'Vă mulțumim!': 'Danke schön!',
        //Form alerts
        'Vă rugăm completați numele și prenumele': 'Bitte geben Sie Ihren Vor- und Nachnamen ein',
        'Vă rugăm, completați numele partenerului': 'Bitte tragen Sie den Namen der Begleitperson ein',
        'Vă rugăm mentionați cu câți copii veniți': 'Bitte geben Sie an, mit wie vielen Kindern Sie kommen',
        'Ați trimis deja un răspuns pentru acest eveniment!': 'Sie haben bereits eine Antwort für diese Veranstaltung eingereicht!',
        'Răspunsul a fost trimis!' : 'Die Antwort wurde gesendet!',
        'Întrebări opționale': 'Optionale Fragen',
        'Răspuns liber': 'Kostenlose Antwort',
        'Adresa de email': 'E-Mail-Adresse',
        'Vă rugăm completați adresa de email': 'Bitte geben Sie die E-Mail-Adresse ein',
        'Adresa de email nu este corectă!': 'Die E-Mail-Adresse ist nicht korrekt!',
        'Apăsați pentru a porni': 'Drücken Sie hier, um zu starten',
        'Omite': 'Überspringen',
        'Redă': 'Wiederholen',
        'Oprește melodia': 'Stoppen Sie das Lied',
        'Pornește melodia': 'Spielen Sie das Lied',
        'Apasă pentru a deschide invitația': 'Klicken Sie hier, um die Einladung zu öffnen',
        'Răspunde': 'Antworten',
        'Vezi locația': 'Siehe Standort',
        'Vezi invitația': 'Siehe Einladung',
        'Alegeți numărul de insoțitori': 'Wählen Sie die Begleitnummer'
    },
    'hu': {
        //Nunta
        'Cu inimile pline de emoție și bucurie, noi': 'Érzelmekkel és örömmel teli szívvel mi',
        'Copil': 'Gyermek',
        'Mireasă': 'Menyasszony',
        'Mire': 'Vőlegény',
        'Aniversarea mea': 'A születésnapom',
        'Vă invităm să fiți alături de noi la celebrarea căsătoriei noastre.': 'WMeghívjuk Önt, hogy csatlakozzon hozzánk házasságunk megünneplésére.',
        'Vă invităm să fiți alături de noi la celebrarea căsătoriei noastre și a botezului micuței' : 'Meghívjuk Önt, hogy csatlakozzon hozzánk házasságkötésünk és kisgyermekünk megkeresztelkedésének megünneplésére',
        'Vă invităm să fiți alături de noi la celebrarea cununiei noastre.': 'WMeghívjuk Önt, hogy csatlakozzon hozzánk esküvőnk megünneplésére.',
        "Alături ne vor fi părinții": 'A szüleink velünk lesznek',
        "Soț & Soție": 'Férj & Feleség',
        'Și nașii': 'És a keresztszülők',
        'Nașul & Nașa': 'Orr & Keresztanya',
        'Vă așteptăm cu drag!': 'Szívesen!',

        //Botez
        'Sunt cel mai frumos dar pe care Dumnezeu l-a făcut părinților mei și mă numesc': 'Én vagyok a legszebb ajándék, amit Isten adott a szüleimnek, és a nevem én vagyok',
        'Împreună cu părinții mei,': 'A szüleimmel együtt',
        'Având alături nașii,': 'A keresztapákkal az oldalán,',
        'Cornel și Livia': 'Sophia & Oliver',
        'Avem plăcerea de a vă invita la botezul meu': 'Örömmel meghívjuk Önt a keresztelőmre',
        'Avem plăcerea de a vă invita la tăierea moțului meu': 'Örömmel meghívjuk Önt a motzám darabolására',
        'Avem plăcerea de a vă invita la ruperea turtei mele': 'Örömmel hívjuk meg tortám törésére',

        //Party
        'Vă invităm să ne distrăm împreună': 'Meghívjuk Önt, hogy érezze magát együtt',
        //Corporate
        'Vă invităm la evenimentul nostru': 'Meghívjuk rendezvényünkre',
        'Locatie eveniment': 'Rendezvény helyszíne',
        'Denumire eveniment business': 'Esemény címe',
        'Adaugă aici descrierea evenimentului': 'Add ide az esemény leírását',

        //Locations and addresses
        'Cununia religioasă va avea loc la': 'Az egyházi szertartás',
        'Botezul va avea loc la': 'A keresztelőre órakor kerül sor',
        'la ora': '{{date}} órai kezdettel',
        'Cununia civilă se va desfășura la': 'A polgári esküvő',
        'Vom petrece împreună la': 'A mulatság',

        //Countdown
        'Zi': 'nap',
        'Zile': 'nap',
        'Oră': 'óra',
        'Ore': 'óra',
        'Minut': 'perc',
        'Minute': 'perc',
        'Secunde': 'másodperc',

        //Separator
        'și': 'és',

        //Form
        'Doriți să participați?': 'Szeretnél részt venni?',
        'Nu Particip': 'Nem veszek részt',
        'Particip': 'Részt veszek',
        'Nu știu': 'Nem tudom',
        'Vă rugăm introduceți următoarele informații:': 'Kérjük, adja meg a következő adatokat:',
        'Prenume, Nume': 'Teljes név',
        'Ne pare rău să auzim asta, daca vă răzgândiți vă așteptam': 'Sajnálattal hallom, ha meggondolnád magad, vártunk rád',
        'Nu este obligatoriu să răspundeți acum, când sunteți sigur': 'Nem kell most válaszolnod, ha biztos vagy benne',
        'Vin singur/ă': 'Egyedül jövök',
        'Vin insoțit/ă': 'Kísérővel',
        "Prenume, Nume Insotitor": 'Kísérő teljes név',
        'Veniți însoțiți de copii?': 'Gyerekekkel jössz?',
        'Da': 'Igen',
        'Nu': 'Nem',
        'Cu câți copii veniți?': 'Hány gyerekkel jössz?',
        'Trimite răspuns': 'Válasz küldése',
        'Vă mulțumim!': 'Köszönöm!',
        //Form alerts
        'Vă rugăm completați numele și prenumele': 'Kérjük, adja meg vezeték- és keresztnevét',
        'Vă rugăm, completați numele partenerului': 'Kérjük, adja meg a partner nevét',
        'Vă rugăm mentionați cu câți copii veniți': 'Kérjük, adja meg, hány gyermekkel érkezel',
        'Ați trimis deja un răspuns pentru acest eveniment!': 'Már küldtél választ erre az eseményre!',
        'Răspunsul a fost trimis!' : 'A választ elküldtük!',
        'Întrebări opționale': 'Választható kérdések',
        'Răspuns liber': 'Ingyenes válasz',
        'Adresa de email': 'Email cím',
        'Vă rugăm completați adresa de email': 'Kérjük, töltse ki az email címet',
        'Adresa de email nu este corectă!': 'Az email cím nem megfelelő!',
        'Apăsați pentru a porni': 'Nyomja meg itt a kezdéshez',
        'Omite': 'Kihagyás',
        'Redă': 'Visszajátszás',
        'Oprește melodia': 'Hagyd abba a dalt',
        'Pornește melodia': 'Játssza le a dalt',
        'Apasă pentru a deschide invitația': 'Kattintson a meghívó megnyitásához',
        'Răspunde': 'Reagál',
        'Vezi locația': 'Lásd a helyet',
        'Vezi invitația': 'Lásd a meghívót',
        'Alegeți numărul de insoțitori': 'Válassza ki a kísérőszámot'
    }
}

export function getWeddingFirstDate(wedding) {

    let dateNow = moment()

    if(!wedding.locatii || wedding.locatii.length === 0) {
        return {
            preview: moment(wedding.dataEvent).format('DD-MM-YYYY'),
            date: moment(wedding.dataEvent).subtract(1, 'hours').toDate()
        }
    }

    let locations = [...wedding.locatii]

    locations.forEach((location,i) => {
        if(location && location.day) {
            locations[i].dateTime = location.day.split('T')[0] + 'T' + location.time.split('T')[1]
        } else {
            locations[i].dateTime = wedding.dataEvent
        }
    })

    // Sort locations by date asc
    locations.sort((a, b) => new Date(a.day) - new Date(b.day));

    if(locations[0]) {

        let nextDateOrEndDate = null

        for (let i = 0; i < locations.length; i++) {
            if (dateNow <= moment(locations[i].dateTime)) {
                nextDateOrEndDate = locations[i].dateTime
                break
            }
        }

        if (nextDateOrEndDate === null) {
            nextDateOrEndDate = locations[locations.length - 1].dateTime
        }

        return {
            preview: moment(nextDateOrEndDate).format('DD-MM-YYYY'),
            date: moment(nextDateOrEndDate).subtract(1, 'hours').toDate()
        }
    } else {
        let firstLocation = wedding.dataEvent
        return {
            preview: moment(firstLocation).format('DD-MM-YYYY'),
            date: moment(firstLocation).subtract(1, 'hours').toDate()
        }
    }
}

export function timeSince(date) {

    var seconds = Math.floor((new Date() - date) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
        return (Math.floor(interval) === 1 ?  "acum un an" : "acum " +Math.floor(interval)+ " ani");
    }

    interval = seconds / 2592000;
    if (interval > 1) {
        // eslint-disable-next-line
        return (Math.floor(interval) === 1 ?  "acum o lună" : "acum " +(Math.floor(interval)+'')+ " luni");
    }
    interval = seconds / 86400;
    if (interval > 1) {
        return (Math.floor(interval) === 1 ?  "acum o zi" : "acum " +Math.floor(interval)+ " zile");
    }
    interval = seconds / 3600;
    if (interval > 1) {
        return (Math.floor(interval) === 1 ?  "acum o ora" : "acum " +Math.floor(interval)+ " ore");
    }
    interval = seconds / 60;
    if (interval > 1) {
        return (Math.floor(interval) === 1 ?  "acum un minut" : "acum " +Math.floor(interval)+ " minute");
    }

    return (Math.floor(seconds) === 1 ?  "acum o secundă" : "acum " +Math.floor(seconds)+ " secunde");
}

export function getAudioLink(invite) {
    if (invite && invite.audioType && invite.audioType !== "none") {

        if (invite.audioType === 'song' && invite.song && invite.song.url) {

            return invite.song.url

        }else{
            if (invite.audio && invite.audio.url) {
               return ('https://' + process.env.REACT_APP_IMAGE_DOMAIN + invite.audio.url)

            }
        }
    }
    return ''
}

export function getWeddingTitle(event) {

    if(event.others && event.others.corporate) {
        return event.prenumeMire
    }

    if(event.eventType === "Nunta") {
        return "Nuntă " + event.prenumeMire + " și " + event.prenumeMireasa
    } else if(event.eventType === "Cununie") {
        return "Cununie civilă " + event.prenumeMire + " și " + event.prenumeMireasa
    } else if(event.eventType === 'Botez') {
        return 'Botez ' + event.prenumeMire
    } else if(event.eventType === 'Mot') {
        return 'Moț ' + event.prenumeMire
    } else if(event.eventType === 'Turta') {
        return 'Turtă ' + event.prenumeMire
    } else if(event.eventType === 'Petrecere') {
        return 'Aniversare ' + event.prenumeMire
    } else if(event.eventType === 'conferinta') {
        return '' + event.prenumeMire
    } else if(event.eventType === 'team_building') {
        return '' + event.prenumeMire
    } else if(event.eventType === 'corporate_party') {
        return '' + event.prenumeMire
    } else if(event.eventType === 'congress') {
        return '' + event.prenumeMire
    }

    return ''

}

export function getEventNextDate(event) {

    let dateNow = moment()

    //event.locations_and_dates
    //  date
    //  location

    //data_eveniment


    let dates = []

    if(event.locations_and_dates) {
        Object.values(event.locations_and_dates).forEach((locationAndDate,i) => {
            dates.push(moment(locationAndDate.date).toDate())
        })
    }

    // Order them ascending
    dates = dates.sort(function(a,b){
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return a - b;
    });

    let nextDateOrEndDate = null

    for(let i=0; i<dates.length; i++) {
        if(dateNow <= moment(dates[i])) {
            nextDateOrEndDate = dates[i]
            break
        }
    }

    if(nextDateOrEndDate === null && dates[dates.length-1]) {
        nextDateOrEndDate = dates[dates.length-1]
    }

    if(nextDateOrEndDate === null)
        nextDateOrEndDate = new Date()

    return nextDateOrEndDate
}

export function getWeddingParticipantsPackageNumber(current_event) {

    if(!current_event.packages) {
        return 0
    }

    if((current_event.packages && (!current_event.packages['wedding_participants'] || (current_event.packages['wedding_participants']
                && current_event.packages['wedding_participants'].package === 0))
        )) {
        return 0
    }

    if(current_event.packages && current_event.packages['wedding_participants']) {
        return current_event.packages['wedding_participants'].package
    }

}

export function countSeatedFamilies(positions) {

    let count = 0

    if(positions && positions.length > 0) {
        for (let i = 0; i < positions.length; i++) {
            if (positions[i].peopleAtTable && positions[i].peopleAtTable.length > 0) {
                count = count + positions[i].peopleAtTable.length
            }
        }
    }

    return count
}

export const getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }

    return "unknown";
}

export const generateVCard = (user) => {

    let vCardContend = 'BEGIN:VCARD\n' +
        'VERSION:1.0\n' +
        'REV:' + moment().toISOString()+'\n' +
        'N;CHARSET=utf-8:'+user.last_name.trim()+';'+user.first_name.trim()+';;;\n' +
        'FN;CHARSET=utf-8:'+ (user.first_name.trim() + ' ' + user.last_name.trim())+'\n'

    if(user.company && user.company.length > 0) {
        vCardContend += 'ORG;CHARSET=utf-8:' + user.company.trim()+'\n'
    }

    if(user.function && user.function.length > 0) {
        vCardContend += 'TITLE;CHARSET=utf-8:' + user.function.trim()+'\n'
    }

    if(user.second_email && user.second_email.length > 0) {
        vCardContend += 'EMAIL;WORK:' + user.second_email.trim()+'\n'
    }

    if(user.email && user.email.length > 0) {
        vCardContend += 'EMAIL;HOME:' + user.email.trim()+'\n'
    }

    if(user.phone && user.phone.length > 0) {
        vCardContend += 'TEL;WORK:' + user.phone.trim()+'\n'
    }

    if(user.website && user.website.length > 0) {
        vCardContend += 'URL:' + user.website.trim()+'\n'
    }

    vCardContend += 'END:VCARD\n'

    return vCardContend
}

export function getReadableWebsite(url) {

    let temp = url.replace('http://', '')
        .replace('https://', '')

    return temp

}

export function fixWebsiteUrl(url) {

    let temp = url
    if(temp.includes('http://') || temp.includes('https://')) {

    } else {
        temp = 'https://' + temp
    }
    return temp
}

export function getDefaultWeddingImage(wedding) {

    if(wedding.eventType === 'Nunta' || wedding.eventType === 'Cununie') {
        return '/images/events/icon_event_nunta.png'
    } else if(wedding.eventType === 'Botez' || wedding.eventType === 'Mot' || wedding.eventType === 'Turta') {
        return '/images/events/icon_event_botez.png'
    } else if(wedding.eventType === 'Petrecere') {
        return '/images/events/icon_event_petrecere.png'
    } else if(wedding.eventType === 'conferinta') {
        return '/images/events/icon_event_conferinta.jpg'
    } else if(wedding.eventType === 'team_building') {
        return '/images/events/icon_event_team_building.jpg'
    } else if(wedding.eventType === 'corporate_party') {
        return '/images/events/icon_event_corporate_party.png'
    } else if(wedding.eventType === 'congress') {
        return '/images/events/icon_event_corporate_general.png'
    }

    return ''

}

export function getMaxYearsPrivateEvent(eventType) {

    if(eventType === 'Nunta') {
        return 2
    } else if(eventType === 'Botez' || eventType === 'Mot' || eventType === 'Turta') {
        return 1
    } else if(eventType === 'Petrecere') {
        return 1
    } else {
        return 1
    }

}

export function getDefaultWeddingSendInviteMessage(event) {
    if (event.eventType === 'Nunta') {
        return event.prenumeMireasa + ' & ' + event.prenumeMire + ', vă invită la nunta lor!'
    }
    if (event.eventType === 'Cununie') {
        return event.prenumeMireasa + ' & ' + event.prenumeMire + ', vă invită la cununia lor!'
    }
    if (event.eventType === 'Botez') {
       return 'Vă invit la botezul meu, ' + event.prenumeMire
    }
    if (event.eventType === 'Mot') {
        return 'Vă invit la tăierea moțului meu, ' + event.prenumeMire
    }
    if (event.eventType === 'Turta') {
        return 'Vă invit la ruperea turtei, ' + event.prenumeMire
    }
    if (event.eventType === 'Petrecere') {
        return 'Sunteți invitat la următoarea petrecere: ' + event.prenumeMire
    }
    return ''
}

export function getDefaultWeddingSendSaveTheDateMessage(event) {
    if (event.eventType === 'Nunta') {
        return event.prenumeMireasa + ' & ' + event.prenumeMire + ', vă roagă să salvați această dată!'
    }
    return ''
}

export function roundNumber(number) {
    return Math.round((number + Number.EPSILON) * 100) / 100
}

export function isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export function copyToClipboard(text) {
    var input = document.createElement('input');
    input.setAttribute('value', text);
    document.body.appendChild(input);
    input.select();
    var result = document.execCommand('copy');
    document.body.removeChild(input);
    return result;
}

export function getMaxInvitesNumber(event) {

    let maxInvitesNumber = 1

    if(event.packages && event.packages.wedding_participants) {
        if (event.packages.wedding_participants.package === 3) {
            maxInvitesNumber = 2
        } else if (event.packages.wedding_participants.package === 4) {
            maxInvitesNumber = 3
        }
    }

    //Check for additional packages
    if(event.packages && event.packages.wedding_additional_invites_extra) {
        for(let i=0; i<event.packages.wedding_additional_invites_extra.length; i++) {
            maxInvitesNumber = maxInvitesNumber + event.packages.wedding_additional_invites_extra[i].details.limit
        }

    }

    return maxInvitesNumber
}

export function getAvailableInviteLanguages() {
    return [
        'ro',
        'en',
        'fr',
        'es',
        'it',
        'de',
        'hu'

    ]
}

export function getLanguageFlag(language) {
    if(language === 'ro') {
        return '🇷🇴';
    }

    if(language === 'en') {
        return '🇺🇸';
    }

    if(language === 'fr') {
        return '🇫🇷';
    }

    if(language === 'es') {
        return '🇪🇸';
    }

    if(language === 'it') {
        return '🇮🇹';
    }

    if(language === 'de') {
        return '🇩🇪';
    }

    if(language === 'hu') {
        return '🇭🇺';
    }

    return '🇷🇴';
}

export function getLanguageName(language) {

    if(language === 'ro') {
        return 'Română';
    }

    if(language === 'en') {
        return 'Engleză';
    }

    if(language === 'fr') {
        return 'Franceză';
    }

    if(language === 'es') {
        return 'Spaniolă';
    }

    if(language === 'it') {
        return 'Italiană';
    }

    if(language === 'de') {
        return 'Germană';
    }

    if(language === 'hu') {
        return 'Maghiară';
    }

    return 'Română';
}

export function translateInviteTexts(slug, language, params) {

    let string = ''

    if(!language || language === '' || language === 'ro') {

        if(inviteTextsTranslation['ro'][slug]) {
            string = inviteTextsTranslation['ro'][slug]
        } else {
            string = slug
        }

    } else {
        string = inviteTextsTranslation[language][slug]
    }

    if(params) {
        for (const [key, value] of Object.entries(params)) {
            string = string.replaceAll('{{'+key+'}}', value)
        }
    }

    return string

}

export function checkAndGetLocationLabel(location, weddingData) {

    let label = ''

    if(location.label) {
        label = location.label
    } else {
        label = location.address
        try {
            label = location.address && location.address.length>0 ? location.address.toString().replaceAll(', Romania', '') : ''
        } catch (e) {

        }
    }

    if(weddingData.invite &&
        weddingData.invite.language &&
        (weddingData.invite.language !== 'ro' && weddingData.invite.language !== '')) {

        if(location['label_' + weddingData.invite.language]) {
            label = location['label_' + weddingData.invite.language]
        }
    }

    return label
}

export function checkAndGetLocationName(location, weddingData) {

    let name = location.name

    if(weddingData.invite &&
        weddingData.invite.language &&
        (weddingData.invite.language !== 'ro' && weddingData.invite.language !== '')) {

        if(location['name_' + weddingData.invite.language]) {
            name = location['name_' + weddingData.invite.language]
        }
    }

    return name
}

export function getMainPackageAvailability(wedding) {

    if(!wedding || !wedding.availabilityDate) {
        return false
    }

    if(wedding.availabilityDate) {
        return moment(wedding.availabilityDate)
    }
}

export function returnZeroIfNegative(value) {
    return value < 0 ? 0 : value
}

export function convertDateTimeToDefaultTimezone(date) {
    return moment(date).set('hours', date.getHours()).set('minutes', date.getMinutes()).toDate()
}

export function convertDateTimeToBrowserTimezone(date) {

    //let newDate = new Date(new Date().toLocaleString("en-US", {timeZone: 'Europe/Bucharest'})
    //newDate = new Date('01-01-2023 ' + newDate.getHours() + ':' + newDate.getMinutes())
    //newDate.setMinutes(date.getMinutes())
    //newDate.setHours(date.getHours())

    //console.log(moment(newDate).toDate())

    return date
}

export function sleep(ms) {
    return new Promise((resolve) => {
        setTimeout(resolve, ms);
    });
}

export function convertRange( value, r1, r2 ) {
    return ( value - r1[ 0 ] ) * ( r2[ 1 ] - r2[ 0 ] ) / ( r1[ 1 ] - r1[ 0 ] ) + r2[ 0 ];
}
export function getRandomArbitrary(min, max) {
    return Math.floor(Math.random() * (max - min) + min)
}

export function getOrdinalNum(n) {
    return n + (n > 0 ? ['th', 'st', 'nd', 'rd'][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10] : '');
}

export function isCorporateWeddingEvent(wedding, isEventType = false) {
    if(!wedding) {
        return false
    }
    if(isEventType) {
        return corporateWeddingEvents.includes(wedding);
    } else {
        return corporateWeddingEvents.includes(wedding.eventType);
    }
}

export async function sha256(message) {
    // encode as UTF-8
    const msgBuffer = new TextEncoder().encode(message);

    // hash the message
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

    // convert ArrayBuffer to Array
    const hashArray = Array.from(new Uint8Array(hashBuffer));

    // convert bytes to hex string
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
}