import React from 'react'
import './IntroAnimationConfigComp.scss'
import {
    getIntroAnimationConfigValues,
    getTemplateConfigValues
} from "../../../InviteTemplatesFunctions";
import {Message, Toggle} from "rsuite";
import {useIntl} from "react-intl";
import ConfigurationFields from "./components/ConfigurationFields/ConfigurationFields";
import AddAudio from "../../../../organizer/mainPages/EditInvite/components/AddAudio";
import {toast} from "react-toastify";
import Animation_Icon from "../../../../organizer/assets/icons/invite-animation.svg"
import SVG_Walkthrough_Arrow from "../../../../assets/icons/walkthrough_arrow.svg";

export default function IntroAnimationConfigComp(
    {
        templateDetails,
        setTemplateDetails,
        isUsingAudio,
        initialWeddingData,
        audioType,
        serverAudioUrl,
        deleteServerAudio,
        allowAudioRequest,
        customAudioLink, customAudioSecond,
        songSelected,
        setAudioType,
        setAudioBlobData,
        setCustomAudioLink,
        setCustomAudioSecond,
        setDeleteServerAudio,
        setSongSelected,
        setIsUsingAudio,
        templateConfigValues
    }
) {

    const intl = useIntl();
    const availableAnimations = getTemplateConfigValues(templateDetails.id).introAnimations
    const toggleStyle = {
        margin: "10px 0px",
        textAlign: 'center',
        maxWidth: "500px",
        alignSelf: 'center'
    }


    return (<div className={"intro-animation-config-comp"}>


            {availableAnimations.length === 1 ?
                <div style={{marginBottom: '1rem'}}>


                    {!templateDetails.introAnimationId ?
                        <div className={"section-info-cont"}>

                            <div className={"header-cont"}>
                                <div className={"title-col"}>
                                    <div className={"title-1"}>
                                        Transmite un gram de
                                    </div>
                                    <div className={"title-2"}>
                                        EMOȚIE
                                    </div>
                                </div>
                                <div className={"icon-col"}>
                                    <img src={Animation_Icon} alt=""/>
                                </div>
                            </div>
                            <div className={"description"}>
                                Oferă o experiență captivantă cu invitațiile animate!
                            </div>
                            <div className={"description"}>
                                Animația respectă stilul modelulul de invitație ales și îți permite să personalizezi
                                culorile și animațiile secundare (păsări, confetti, frunze de toamnă, baloane etc.).
                            </div>
                            <div className={"description"}>
                                Invitația animată oferă o experiență cu adevărat minunată atunci când este însoțită de o
                                melodie.
                                Poți alege din lista noastră de melodii sau ne poți trimite un link de YouTube.
                            </div>

                            <div className={"footer-cont"}>
                                <div className={"cta-col"}>
                                    <div className={"text-col"}>
                                        Activează opțiunea
                                    </div>
                                    <div className={"img-col"}>
                                        <img src={SVG_Walkthrough_Arrow} alt=""/>
                                    </div>
                                </div>
                                <div className={"right-col"}></div>
                            </div>


                        </div>
                        : <>
                            {!(initialWeddingData && initialWeddingData.packages &&
                                    initialWeddingData.packages['wedding_participants'] && initialWeddingData.packages['wedding_participants'].details &&
                                    initialWeddingData.packages['wedding_participants'].details.addons &&
                                    initialWeddingData.packages['wedding_participants'].details.addons.find(item => item.type === "invitation_animation")) &&
                                <Message type="warning" style={{marginBottom: '12px'}}
                                         description={"Puteți testa această funcționalitate în modul de previzualizare dar în cadrul invitației va apărea doar după achiziționarea unui pachet cu animație!"}/>
                            }</>}


                    {intl.formatMessage({id: 'intro_animation.config.enable_question'})}
                    <br/>
                    <Toggle
                        style={toggleStyle}
                        checked={!!(templateDetails.introAnimationId && templateDetails.introAnimationId === availableAnimations[0])}
                        size="lg"
                        checkedChildren={intl.formatMessage({id: 'general.yes'})}
                        unCheckedChildren={intl.formatMessage({id: 'general.no'})}
                        onChange={(value) => {
                            let newTemplateDetails = {...templateDetails}
                            if (value) {
                                newTemplateDetails.introAnimationId = availableAnimations[0]

                                //Define default configuration values
                                let introAnimationConfiguration = {}
                                let configurationValues = getIntroAnimationConfigValues(availableAnimations[0]).configuration
                                configurationValues.forEach(item => {
                                    if (templateConfigValues && templateConfigValues.introAnimationsConfig &&
                                        templateConfigValues.introAnimationsConfig[newTemplateDetails.introAnimationId] && templateConfigValues.introAnimationsConfig[newTemplateDetails.introAnimationId][item.title])
                                        introAnimationConfiguration[item.title] = templateConfigValues.introAnimationsConfig[newTemplateDetails.introAnimationId][item.title]
                                    else
                                        introAnimationConfiguration[item.title] = item.value
                                })
                                newTemplateDetails.introAnimationConfiguration = introAnimationConfiguration
                            } else {
                                newTemplateDetails.introAnimationId = 0
                            }
                            setTemplateDetails(newTemplateDetails)
                        }}/>
                </div> : null}

            {templateDetails.introAnimationId ?
                <div>
                    {/* introAnimation template configuration */}
                    <ConfigurationFields
                        introAnimationId={templateDetails.introAnimationId}
                        configuration={templateDetails.introAnimationConfiguration}
                        setConfiguration={(value) => {
                            let newTemplateDetails = {...templateDetails}
                            newTemplateDetails.introAnimationConfiguration = value
                            setTemplateDetails(newTemplateDetails)
                        }}
                    />

                    <div className="alert alert-warning">
                        <Message type="info"
                                 description={"Pentru o experiență mai captivantă îți recomandăm să selectezi o melodie pentru invitația ta din secțiunea 'Opțiuni'"}/>
                    </div>
                </div>
                : null}

        </div>
    )
}
