import React, {useEffect} from "react";
import axios from "axios";

export default function EditInviteWalkthrough(
    {
        eventId,
        step,
        setStep,
        children,
        changeTab,
        callback = () => {
        },
        slug = 'walkthrough'
    }) {

    const isIOS = (/iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) && !window.MSStream

    const finishWalkthrough = async () => {
        changeTab('invitation')

        let jwt = localStorage.getItem('jwt')

        let auth = {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        }

        let payload = {}
        payload[slug] = true

        localStorage.setItem('edit_invite_walkthrough', '1')

        if(jwt) {
            axios.post(process.env.REACT_APP_DOMAIN + 'weddings/update/tutorials/' + eventId, payload, auth)
        } else {
            axios.post(process.env.REACT_APP_DOMAIN + 'weddings/update/tutorials/' + eventId, payload)
        }

        callback()
    }

    return (
        <>
            {/* preloading assets */}

            {step === 1 ?
            <div style={{position: 'absolute', top:0, left: '-200vw', width:'0px', height:'0px', overflow:'hidden'}}>
                <img src={"/images/inviteTemplates/17/preview.jpg"} alt={""}/>
                <img src={"/images/inviteTemplates/18/preview.jpg"} alt={""}/>
                <img src={"/images/inviteTemplates/91/preview.jpg"} alt={""}/>
                <img src={"/images/inviteTemplates/56/preview.jpg"} alt={""}/>
                <img src={"/images/inviteTemplates/87/preview.jpg"} alt={""}/>
                <img src={"/images/editInviteTutorial/introscreen1.png"} alt={""}/>
                <img src={"/images/editInviteTutorial/introscreen2.png"} alt={""}/>
                <img src={"/images/editInviteTutorial/introscreen3.png"} alt={""}/>
                <img src={"/images/editInviteTutorial/introscreen5.png"} alt={""}/>
                <img src={"/images/editInviteTutorial/introscreen4.png"} alt={""}/>

                {!(window.alityAppView && isIOS) ?
                <video autoPlay preload="auto" muted webkit-playsinline playsinline
                       className="video-cont" id="video">
                    <source id="vsource" src="https://ality.ro/wp-content/uploads/nunta_fit1.mp4"
                            type="video/mp4"/>
                </video>:null}
            </div>:null}

            {(step === 3 || step === 5 || step === 7) ? <div className={"walkthrough-overlay-back-invite"}>
                    <div className={"tip-text"}>
                        Apasă în locul indicat de sâgeată
                    </div>

                </div>
                :
                <div className={"walkthrough-overlay"}>
                    <div className={"content"}>
                        {children}
                        {step === 1 ? <>
                            <p style={{marginTop: '4rem'}}>Îți mulțumim că te-ai alăturat comunității ALITY!</p>
                            <p style={{marginTop: '1rem'}}>Hai să ne familiarizăm cu invitația prin 4 pași simpli!</p>

                            <div className={"next-button"} onClick={() => setStep(2)}>Să începem
                            </div>
                        </>
                        : null}

                    {step === 2 ? <>
                            <h2>Invitație</h2>

                            <p style={{marginTop: '2rem'}}>
                                În această secțiune îți poți alege un model de invitație și îl poți personaliza apăsând pe
                                fiecare secțiune în parte.
                            </p>
                            <div className={"image-cont"}>
                                <img src={"/images/inviteTemplates/17/preview.jpg"} alt={""}/>
                                <img src={"/images/inviteTemplates/18/preview.jpg"} alt={""}/>
                                <img src={"/images/inviteTemplates/91/preview.jpg"} alt={""}/>
                                <img src={"/images/inviteTemplates/56/preview.jpg"} alt={""}/>
                                <img src={"/images/inviteTemplates/87/preview.jpg"} alt={""}/>
                            </div>
                            <p style={{marginTop: '4rem', fontSize: '1.2rem'}}>Folosind bara de jos poți naviga între
                                secțiunile principale
                                ale invitației.<br/>
                            </p>
                            <div className={"next-button"} onClick={() => {
                                //changeTab('introScreen')
                                setStep(3)
                            }}>Mai departe
                            </div>
                        </>
                        : null}


                    {step === 4 ? <>
                            <h2>Introducere</h2>
                            <p style={{marginTop: '2rem'}}>
                                Ecranul introductiv îi întâmpină pe invitați cu o prezentare de ansamblu captivantă a
                                evenimentului tău.
                            </p>
                            <div className={"image-cont"}>
                                <img src={"/images/editInviteTutorial/introscreen1.png"}  alt={""}/>
                                <img src={"/images/editInviteTutorial/introscreen2.png"} alt={""}/>
                                <img src={"/images/editInviteTutorial/introscreen3.png"} alt={""}/>
                                <img src={"/images/editInviteTutorial/introscreen5.png"} alt={""}/>
                                <img src={"/images/editInviteTutorial/introscreen4.png"} alt={""}/>
                            </div>
                            <p style={{marginTop: '2rem'}}>
                            Se îmbină perfect cu invitația și este complet personalizabil. Încearcă-l!
                            </p>
                            <div className={"next-button"} onClick={() => {
                                setStep(5)
                            }}>Mai departe
                            </div>
                        </>
                        : null}

                    {step === 6 ? <>
                            <h2>Animație</h2>
                            <p style={{marginTop: '0.5rem', marginBottom:'1rem', fontSize: '1.5rem'}}>
                                Oferă o experiență captivantă cu invitațiile animate!
                            </p>

                            {!(window.alityAppView && isIOS) ?
                                <video loop autoPlay preload="auto" muted webkit-playsinline
                                       playsInline
                                       className="video-cont" id="video">
                                    <source id="vsource" src="https://ality.ro/wp-content/uploads/nunta_fit1.mp4"
                                            type="video/mp4"/>
                                </video>
                                :
                                <video loop controls preload="auto" muted webkit-playsinline
                                       playsInline
                                       className="video-cont" id="video">
                                    <source id="vsource" src="https://ality.ro/wp-content/uploads/nunta_fit1.mp4"
                                            type="video/mp4"/>
                                </video>
                            }

                            <p style={{marginTop: '1rem', fontSize: '1rem'}}>
                                Decorează-ți invitația cu efecte animate speciale (păsări, confetti, frunze de toamnă,
                                baloane
                                etc.).
                            </p>
                            <div className={"next-button"} onClick={() => {
                                setStep(7)
                            }}>Mai departe
                            </div>
                        </>
                        : null}
                    {step === 8 ? <>
                            <h2>Opțiuni</h2>

                            <p style={{marginTop: '1rem'}}>
                                În această secțiune îți poți personaliza întrebările din invitație, poți adăuga o
                                melodie de fundal și multe alte opțiuni de personalizare.
                            </p>

                            <div className={"next-button"} onClick={() => finishWalkthrough()}>Gata !</div>
                        </>
                        : null}
                </div>
                <div className={"skip-button"} onClick={() => finishWalkthrough()}>
                    Treci peste
                </div>
            </div>
            }
        </>)
}